import React from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { deleteUpcomingPayments, getTransactionInfoByWalletId } from 'store/actions/transactions';
import { Modal, Button } from 'components';
import styles from './DeleteInvoiceModal.module.scss';

export interface IDeleteInvoiceModalProps {
  isOpen: boolean;
  toggle(): void;
  options: any;
}

export const DeleteInvoiceModalComponent: React.FC<IDeleteInvoiceModalProps> = ({ isOpen, toggle, options }) => {
  const dispatch = useAppDispatch();

  const { walletId } = useAppSelector((state) => state.wallet.walletInfo);
  const { transactionGroupList, transactionPaginationConfig, transactionDateRangeConfig } = useAppSelector(
    (state) => state.transactions
  );

  const { id } = options;
  const { pageSize } = transactionPaginationConfig;
  const { dateFrom, dateTo } = transactionDateRangeConfig;

  const getTransactionByWalletIdAction = (): void => {
    if (walletId) {
      dispatch(
        getTransactionInfoByWalletId({
          walletId,
          page: 1,
          pageSize,
          dateTo,
          dateFrom,
        })
      );
    }
  };

  const handleDeleteAction = async () => {
    await dispatch(deleteUpcomingPayments({ paymentId: id }));
    getTransactionByWalletIdAction();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Delete invoice</h2>

      <div className={styles.description}>
        <p>Are you sure you’re ready to delete this invoice?</p>
        <p>This action cannot be undone</p>
      </div>

      <div className={styles.controlSection}>
        <Button fullWidth size="small" onClick={handleDeleteAction}>
          Yes, delete this invoice
        </Button>

        <Button fullWidth theme="secondary" size="small" onClick={toggle}>
          No, I changed my mind
        </Button>
      </div>
    </Modal>
  );
};

export const DeleteInvoiceModal = React.memo(DeleteInvoiceModalComponent);
