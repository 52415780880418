import * as yup from 'yup';
import { passwordSchema } from './passwordSchema';

export const changePasswordSchema = yup.object().shape({
  oldPassword: passwordSchema,
  password: passwordSchema
    .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match')
    .notOneOf([yup.ref('oldPassword'), null], 'Passwords must be new'),
  confirmPassword: passwordSchema
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .notOneOf([yup.ref('oldPassword'), null], 'Passwords must be new'),
});
