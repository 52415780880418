import React from 'react';
import cx from 'clsx';

import { TBaseComponentProps } from '../../types';
import { TInputSize } from '../types';
import styles from './InputWrapper.module.scss';

type TCursor = 'pointer';

interface IInputWrapperProps extends TBaseComponentProps<React.ComponentPropsWithoutRef<'div'>> {
  className?: string;
  disabled?: boolean;
  focused?: boolean;
  invalid?: boolean;
  rightEnhancer?: React.ReactNode;
  children: React.ReactNode;
  cursor?: TCursor;
  size?: TInputSize;
}

export const InputWrapper = React.forwardRef<HTMLDivElement, IInputWrapperProps>(function InputWrapper(props, ref) {
  const { focused, disabled, invalid, rightEnhancer, children, className, cursor, size, ...rest } = props;

  return (
    <div
      {...rest}
      ref={ref}
      className={cx(
        styles.inputWrapper,
        styles[size!],
        focused && styles.focused,
        invalid && styles.invalid,
        disabled && styles.disabled,
        className
      )}
      style={{ cursor }}
      tabIndex={disabled ? -1 : props.tabIndex}
    >
      <div className={styles.childrenWrapper}>{children}</div>

      {rightEnhancer && <span className={styles.rightEnhancer}>{rightEnhancer}</span>}
    </div>
  );
});

InputWrapper.defaultProps = {
  disabled: false,
  focused: false,
  invalid: false,
  rightEnhancer: undefined,
  cursor: undefined,
  size: 'medium',
  className: undefined,
};
