import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Modal, Button, FormField, Input } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { handleBankDetailsSchema } from 'utils/schemas/handleBankDetailsSchema';
import { updateBankDetailsRequest, updateBankDetails, getWalletInfoByListingId } from 'store/actions/wallet';
import { useAppDispatch, useAppSelector, useUserInfo } from 'hooks';
import { IEditBankDetailsModalProps } from './types';
import styles from './EditBankDetailsModal.module.scss';

export const EditBankDetailsModalComponent: React.FC<IEditBankDetailsModalProps> = ({ isOpen, toggle }) => {
  const { role } = useUserInfo();
  const { listingId } = useAppSelector((store) => store.selectedListing);
  const landlordAccount = useAppSelector((store) => store.wallet.landlordAccount);
  const { walletId } = useAppSelector((store) => store.wallet.walletInfo);
  const dispatch = useAppDispatch();

  const { landlordAccountName, landlordAccountNumber, landlordAccountSortCode } = landlordAccount;

  type FormValues = {
    [type: string]: string | null;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      accountName: landlordAccountName,
      sortCode: landlordAccountSortCode,
      accountNumber: landlordAccountNumber,
    },
    resolver: yupResolver(handleBankDetailsSchema),
  });

  const handleEditBankDetails = async ({ accountName, sortCode, accountNumber }: any) => {
    if (walletId && listingId) {
      const payload = {
        landlordAccountName: accountName,
        landlordAccountSortCode: sortCode,
        landlordAccountNumber: accountNumber,
      };

      if (role === 'Landlord')
        dispatch(
          updateBankDetailsRequest({
            walletId,
            payload,
          })
        );

      if (role === 'Admin' || role === 'Controller') {
        await dispatch(
          updateBankDetails({
            walletId,
            payload,
          })
        );
        dispatch(getWalletInfoByListingId({ listingId }));
      }

      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Edit bank details</h2>

      <p className={styles.description}>
        Your request for change will be verified by a Home Made team member and you will be notified by email once
        approved
      </p>

      <div className={styles.inputSection}>
        <form autoComplete="off" onSubmit={handleSubmit(handleEditBankDetails)}>
          <FormField labelId="accountName" labelText="Account Name" fieldError={errors.accountName as TFieldError}>
            <Input size="small" id="accountName" {...register('accountName')} />
          </FormField>

          <FormField labelId="sortCode" labelText="Sort code" fieldError={errors.sortCode as TFieldError}>
            <Input size="small" id="sortCode" type="number" {...register('sortCode')} />
          </FormField>

          <FormField
            labelId="accountNumber"
            labelText="Account number"
            fieldError={errors.accountNumber as TFieldError}
          >
            <Input size="small" id="accountNumber" {...register('accountNumber')} />
          </FormField>

          <div className={styles.controlSection}>
            <Button fullWidth size="small" type="submit">
              Submit change request
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const EditBankDetailsModal = React.memo(EditBankDetailsModalComponent);
