import React from 'react';

import alertOutlineImg from 'images/alert-outline.svg';
import styles from './InviteBadge.module.scss';

export interface IInviteBadgeProps {
  onInviteClick(): void;
}

export const InviteBadge: React.FC<IInviteBadgeProps> = ({ onInviteClick }) => (
  <div className={styles.badge}>
    <img src={alertOutlineImg} alt="Alert Icon" className={styles.img} />
    <span className={styles.mainText}>This user has not activated their account yet.</span>
    <button type="button" className={styles.sendInviteButton} onClick={onInviteClick}>
      Send a new onboarding invite
    </button>
  </div>
);
