import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Input, Button } from 'components';
import { useAppDispatch } from 'hooks';
import { updateFinancesTotalPayments } from 'store/actions/finances';
import { IPaymentItemControlProps } from './types';
import styles from './PaymentItemControl.module.scss';

export const PaymentItemControlComponent: React.FC<IPaymentItemControlProps> = (props) => {
  const { type, handleEdit, id, balance } = props;
  const dispatch = useAppDispatch();

  type FormValues = {
    [type: string]: string | number;
  };

  const schema = yup.object().shape({
    [type]: yup
      .number()
      .typeError('Should be a number')
      .min(0, 'Should be greater than 0')
      .max(balance, `Should be lower than balance - ${balance}`)
      .test('decimal', 'Should be lower than two decimal', (value) => /^\d*.?\d{0,2}$/.test(`${value}`))
      .required('This field is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: { [type]: props[type] },
    resolver: yupResolver(schema),
  });

  const getOppositeValueOdds = (a: number, b: number, positions = 2) => {
    const factor = 10 ** positions;
    // @ts-ignore: Unreachable code error
    return ((a * factor).toFixed(positions) - (b * factor).toFixed(positions)) / factor;
  };

  const handleSave = async (data: any) => {
    const oppositePaymentType = type === 'homemadePaymentsTotal' ? 'landlordPaymentsTotal' : 'homemadePaymentsTotal';
    let oppositPaymentValue = props[oppositePaymentType];

    if (data[type] + props[oppositePaymentType] > balance) {
      oppositPaymentValue = getOppositeValueOdds(balance, data[type]);
    }

    const updatedPayment = { [type]: data[type], [oppositePaymentType]: oppositPaymentValue };
    await dispatch(updateFinancesTotalPayments({ id, params: updatedPayment }));
    handleEdit('');
  };

  return (
    <form className={styles.financesDescriptionActions} onSubmit={handleSubmit(handleSave)}>
      <Input size="extraSmall" {...register(type)} />
      <Button className={styles.financesDescriptionActionSave} type="submit">
        Save
      </Button>
      <button type="button" className={styles.editButton} onClick={() => handleEdit('')}>
        Cansel
      </button>
      {errors[type] && <span className={styles.paymentErrorMessage}>{errors[type].message}</span>}
    </form>
  );
};

export const PaymentItemControl = React.memo(PaymentItemControlComponent);
