import React from 'react';
import { CheckIcon } from '../icons/CheckIcon';
import styles from './Timeline.module.scss';

export interface ITimelineEvent {
  title: string;
  dateKey: string;
  badge: React.ReactNode;
  description: React.ReactNode;
  isExpanded?: boolean;
  onToggle(key: string): void;
}

export interface ITimelineBadge {
  completed: string | null;
}

export const Timeline: React.FC = ({ children }) => <ul className={styles.timeline}>{children}</ul>;

export const TimelineBadge: React.FC<ITimelineBadge> = ({ completed }) =>
  completed ? (
    <div className={styles.badgeWrapperCompleted}>
      <div className={styles.badgeIconWrapper}>
        <CheckIcon color="currentColor" width={20} height={16} />
      </div>
      <span className={styles.badgeText}>Completed {completed}</span>
    </div>
  ) : (
    <div className={styles.badgeWrapper} />
  );

export const TimelineEvent: React.FC<ITimelineEvent> = ({
  dateKey,
  title,
  badge,
  isExpanded,
  onToggle,
  description,
}) => {
  const handleToggleOpen = React.useCallback(() => onToggle(dateKey), [dateKey, onToggle]);

  const handleKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        handleToggleOpen();
      }
    },
    [handleToggleOpen]
  );

  return (
    <li className={styles.event}>
      <div className={styles.head} role="button" tabIndex={0} onClick={handleToggleOpen} onKeyPress={handleKeyPress}>
        <div className={styles.titleWrapper}>
          {badge}
          <span className={styles.title}>{title}</span>
        </div>

        <button className={styles.toggleButton}>{isExpanded ? 'See less' : 'See more'}</button>
      </div>

      {isExpanded && <div className={styles.description}>{description}</div>}
    </li>
  );
};

TimelineEvent.defaultProps = {
  isExpanded: false,
};
