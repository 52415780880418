import React, { ChangeEventHandler, useState, useEffect } from 'react';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { format, isValid, isAfter, isBefore, parse } from 'date-fns';

import { Button, Input, Modal } from 'components';
import { Datepicker } from 'components/elements/Datepicker';
import { useAppDispatch } from 'hooks';
import { transactionsSlice } from 'store/reducers/TransactionsSlice';
import styles from './DateRangePickerModal.module.scss';

export interface ITenancyDatesModalProps {
  isOpen: boolean;
  toggle(): void;
}

const currentDate = new Date();
const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
const endDate = new Date();
const DATE_FORMAT = 'dd/MM/yyyy';
const DATE_ACTION_FORMAT = 'yyyy-MM-dd';

export const DateRangePickerModalComponent: React.FC<ITenancyDatesModalProps> = ({ isOpen, toggle }) => {
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>({ from: startDate, to: endDate });
  const [fromValue, setFromValue] = useState<string>(format(startDate, DATE_FORMAT));
  const [toValue, setToValue] = useState<string>(format(endDate, DATE_FORMAT));
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(transactionsSlice.actions.setTransactionDateRangeConfig({ dateFrom: '', dateTo: '' }));
    };
  }, []);

  // eslint-disable-next-line
  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, DATE_FORMAT, new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  // eslint-disable-next-line
  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, DATE_FORMAT, new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, DATE_FORMAT));
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, DATE_FORMAT));
    } else {
      setToValue('');
    }
  };

  const handleDatePicker = () => {
    if (!selectedRange || !selectedRange.from || !selectedRange.to) return;

    const dateRangePayload = {
      dateFrom: format(selectedRange.from, DATE_ACTION_FORMAT),
      dateTo: format(selectedRange.to, DATE_ACTION_FORMAT),
    };

    dispatch(transactionsSlice.actions.setTransactionDateRangeConfig(dateRangePayload));
    toggle();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Custom date range</h2>

      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.labels}>
            <span className={styles.startDateLabel}>From</span>
            <span className={styles.endDateLabel}>To</span>
          </div>

          <div className={styles.controls}>
            <Input value={fromValue} size="small" onChange={handleFromChange} />
            <Input value={toValue} size="small" onChange={handleToChange} />
            <Button fullWidth size="small" onClick={handleDatePicker}>
              Go
            </Button>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <Datepicker
            mode="range"
            selected={selectedRange}
            onSelect={handleRangeSelect}
            className={styles.datePicker}
          />
        </div>
      </div>
    </Modal>
  );
};

export const DateRangePickerModal = React.memo(DateRangePickerModalComponent);
