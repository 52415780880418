import { UserRole } from 'types';

export interface IRouteItem {
  path: string;
  allowedForRoles?: UserRole[];
}

export const mainRoutesMap: Record<string, IRouteItem> = {
  properties: {
    path: 'properties',
  },
  tenancies: {
    path: 'tenancies',
  },
  dashboard: {
    path: 'dashboard',
  },
  tenants: {
    path: 'tenants',
  },
  income: {
    path: 'income',
  },
  documents: {
    path: 'documents',
  },
  users: {
    path: 'users',
    allowedForRoles: ['Admin', 'Controller', 'Agent'],
  },
  finances: {
    path: 'finances',
    allowedForRoles: ['Admin', 'Controller'],
  },
  status: {
    path: 'status',
    allowedForRoles: ['Admin'],
  },
};
