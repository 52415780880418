import { getDecimalStringNumber } from './handlers';

interface IGetPriceProps {
  price: number;
  currency: string;
}

const currencyMap: Record<string, string> = {
  GBP: `£`,
  EUR: `€`,
};

export const getPrice = ({ price, currency }: IGetPriceProps): string => {
  return price
    ? `${currencyMap[currency]}${getDecimalStringNumber(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : `${currencyMap[currency]} 0`;
};

export const getWalletPrice = (amount: number): string => {
  return amount
    ? `${currencyMap.GBP}${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} pcm`
    : `${currencyMap.GBP}0 pcm`;
};

export const getTransactionPrice = (amount: number): string => {
  return amount ? `${currencyMap.GBP}${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '-';
};
