import React from 'react';
import cx from 'clsx';

import { Tooltip } from 'components';
import { ICardProps } from './types';
import styles from './Card.module.scss';

const CardComponent: React.FC<ICardProps> = ({ children, className, titleText, tooltipText }) => (
  <div className={cx(styles.card, className)}>
    <div className={styles.cardTitleContainer}>
      {titleText ? <span className={styles.title}>{titleText}</span> : null}
      {tooltipText ? <Tooltip text={tooltipText} type="info" placement="right-start" /> : null}
    </div>

    {children}
  </div>
);

export const Card = React.memo(CardComponent);
