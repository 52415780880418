import { AppDispatch } from 'store';
import { walletSlice } from 'store/reducers/WalletSlice';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { handleWalletModel, handleUpdatedBankDetailsModel } from 'store/models/wallet';
import { addNotificationItem } from 'store/actions/notifications';
import {
  getWalletInfoByListingIdCall,
  postAdjustNextMonthsRentCall,
  initTenancyAccountCall,
  patchBankDetailsCall,
  patchBankDetailsRequestCall,
  approveEditBankDetailsRequestCall,
  rejectEditBankDetailsRequestCall,
  getUpdatedBankDetailsCall,
} from 'store/api/wallet';
import {
  IPatchBankDetailsPayload,
  IHandleEditBankDetailsRequestPayload,
  IGetWalletInfoByListingIdPayload,
  IAdjustNextMonthsRentPayload,
  IInitAccountPayload,
} from 'types';

export const getWalletInfoByListingId = (params: IGetWalletInfoByListingIdPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(walletSlice.actions.resetWalletState());
    dispatch(loaderSlice.actions.increaseLoader());

    const walletData = await getWalletInfoByListingIdCall(params);
    const handledWalletData = handleWalletModel(walletData);
    const { walletInfo } = handledWalletData;

    dispatch(walletSlice.actions.setWalletState(handledWalletData));

    if (walletInfo.newBankDetailsRequested && walletInfo.walletId) {
      const updatedBankDetailsData = await getUpdatedBankDetailsCall({ walletId: walletInfo.walletId });
      const handledUpdatedBankDetails = handleUpdatedBankDetailsModel(updatedBankDetailsData);

      dispatch(walletSlice.actions.setUpdatedBankDetailsState(handledUpdatedBankDetails));
    }
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateAdjustNextMonthsRent = (params: IAdjustNextMonthsRentPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    await postAdjustNextMonthsRentCall(params);
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const initTenancyAccount = (params: IInitAccountPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    const walletData = await initTenancyAccountCall(params);

    const handledWalletData = handleWalletModel(walletData);

    dispatch(walletSlice.actions.setWalletState(handledWalletData));
  } catch (error) {
    if (error !== '') {
      dispatch(addNotificationItem({ type: 'error', label: (error as Error)?.message }));
    } else {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    }
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateBankDetailsRequest = (params: IPatchBankDetailsPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    await patchBankDetailsRequestCall(params);
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateBankDetails = (params: IPatchBankDetailsPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    await patchBankDetailsCall(params);
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const handleEditBankDetailsRequest =
  (params: IHandleEditBankDetailsRequestPayload) => async (dispatch: AppDispatch) => {
    const { walletId, flag } = params;

    try {
      dispatch(loaderSlice.actions.increaseLoader());
      if (flag) {
        await approveEditBankDetailsRequestCall({ walletId });
      } else {
        await rejectEditBankDetailsRequestCall({ walletId });
      }
    } catch (e) {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    } finally {
      dispatch(loaderSlice.actions.decreaseLoader());
    }
  };
