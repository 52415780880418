import React from 'react';

import { Button, StatusTag } from 'components';

import { ITableRowItemField, IOnCellClickParams } from '../types';

import styles from './index.module.scss';

interface ITableRowCellProps extends ITableRowItemField {
  onClick?(params: IOnCellClickParams): void;
}

const TableRowCellComponent: React.FC<ITableRowCellProps> = ({
  value,
  fieldName,
  fieldType,
  label,
  onClick,
  ...rest
}): JSX.Element => {
  const handleClick = () => {
    if (onClick && value) {
      onClick({ value, fieldName });
    }
  };

  const statusCell = (): JSX.Element => (
    <td key={fieldType} className={styles.td}>
      <StatusTag label={value} {...rest} />
    </td>
  );

  const downloadDocumentCell = (): JSX.Element => (
    <td key={fieldType} className={styles.td}>
      <div className={styles.downloadDocumentCell}>
        <Button className={styles.downloadDocumentButton} onClick={handleClick} />
      </div>
    </td>
  );

  const actionCell = (): JSX.Element => (
    <td key={fieldType} className={styles.td}>
      <div className={styles.actionCell}>
        <Button fullWidth theme="secondary" onClick={handleClick} size="small">
          {label}
        </Button>
      </div>
    </td>
  );

  const cell = (): JSX.Element => (
    <td key={fieldType} className={styles.td}>
      <div className={styles.cell}>
        <span title={value}>{value}</span>
      </div>
    </td>
  );

  const renderCell = (): JSX.Element => {
    switch (fieldType) {
      case 'status':
        return statusCell();
      case 'action':
        return actionCell();
      case 'downloadDocument':
        return downloadDocumentCell();
      default:
        return cell();
    }
  };

  return renderCell();
};

export const TableRowCell = React.memo(TableRowCellComponent);
