import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import accountIcon from 'images/account-icon.svg';
import arrowDownIcon from 'images/arrow-down-icon.svg';
import { useUserInfo } from 'hooks/useUserInfo';
import { roleMap } from 'constants/userList';
import { AccountPopover } from '../AccountPopover';
import styles from './Header.module.scss';

interface IContents {
  [key: string]: {
    title: string;
    subTitle?: string;
  };
}

const contents: IContents = {
  properties: {
    title: 'My properties',
    subTitle: 'A dashboard of your properties with Home Made.',
  },
  tenancies: {
    title: 'Tenancies history',
    subTitle: 'Information about all your tenancies within one property',
  },
  dashboard: {
    title: 'Advertising dashboard',
  },
  tenants: {
    title: 'Progressing tenants',
  },
  income: {
    title: 'Property wallet',
  },
  documents: {
    title: 'Documentation',
  },
  users: {
    title: 'User Management',
    subTitle: 'You add, edit or deactivate users',
  },
  finances: {
    title: 'Manage finance',
    subTitle: 'Review incoming rent and approve outgoing payments',
  },
  status: {
    title: 'System dashboard',
    subTitle: 'Home-Made background jobs status',
  },
};

const HeaderComponent = () => {
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState('');
  const { email, fullName, role } = useUserInfo();
  const userName = fullName || email;

  const getMainPageName = (path: string): string => {
    return path.replace('/main/', '');
  };

  useEffect((): void => {
    setCurrentPage(getMainPageName(pathname));
  }, [pathname]);

  const renderHeaderTitle = () => {
    return (
      <>
        <h1 className={styles.headerTitle}>{contents[currentPage].title}</h1>
        {contents[currentPage].subTitle && (
          <span className={styles.headerSubTitle}>{contents[currentPage].subTitle}</span>
        )}
      </>
    );
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerTitleContainer}>{currentPage && renderHeaderTitle()}</div>
      <div className={styles.headerAccountContainer}>
        <div className={styles.headerUserTextContainer}>
          <span className={styles.headerUsername}>{userName}</span>
          <span className={styles.headerRole}>{role && ` | ${roleMap[role]}`}</span>
        </div>
        <AccountPopover>
          <img src={accountIcon} alt="Account Icon" className={styles.headerAccountIcon} />
          <img src={arrowDownIcon} alt="Arrow Down" className={styles.headerArrowDownIcon} />
        </AccountPopover>
      </div>
    </header>
  );
};

export const Header = React.memo(HeaderComponent);
