import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PayloadAction } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, ContactUs, FormField, Input, MFACode, NavigationLink } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { signInSchema } from 'utils/schemas';
import { login, loginMFA } from 'store/actions/auth';
import { getUserInfo } from 'store/api/user';
import { addNotificationItem } from 'store/actions/notifications';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCalculateNumberDays } from 'utils/helpers';

import styles from './SignInPage.module.scss';

type FormValues = {
  email: string;
  password: string;
};

export const SignInPage: React.FC = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isMFACodeVisible, setMFACodeVisible] = useState(false);
  const { loaderState } = useAppSelector((state) => state.loader);

  const defaultValues = {
    email: searchParams.get('userEmail') || undefined,
    password: searchParams.get('password') || undefined,
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(signInSchema),
  });

  const handleExpiredPassword = async (payload: any) => {
    // @ts-ignore: Unreachable code error
    const { passwordModifiedAt, id } = await getUserInfo(payload.accessToken.jwtToken);

    if (!passwordModifiedAt) return;
    if (getCalculateNumberDays(new Date(), new Date(passwordModifiedAt)) >= 120) {
      navigate('/auth/expired-password', { state: { oldPassword: getValues('password'), userId: id } });
    } else {
      navigate('/main/properties');
    }
  };

  const signInHandler = ({ email, password }: FormValues): void => {
    dispatch(loaderSlice.actions.increaseLoader());
    dispatch(login({ email, password }))
      .then(({ type, payload }: PayloadAction<unknown>) => {
        if (type === 'auth/login/rejected' && payload && (payload as Error)?.message === 'newPasswordRequired') {
          navigate('/auth/new-password');
        }

        if (type === 'auth/login/rejected' && payload && (payload as Error)?.message === 'mfaRequired') {
          setMFACodeVisible(true);
          dispatch(addNotificationItem({ key: 'mfaCodeSent' }));
        }

        if (type === 'auth/login/fulfilled') {
          handleExpiredPassword(payload);
        }

        if (
          type === 'auth/login/rejected' &&
          (payload as Error)?.message !== 'mfaRequired' &&
          (payload as Error)?.message !== 'newPasswordRequired'
        ) {
          dispatch(addNotificationItem({ type: 'error', label: (payload as Error)?.message }));
        }
      })
      .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
  };

  const resendMFAVerificationCode = () => {
    signInHandler({ email: getValues('email'), password: getValues('password') });
  };

  const sendMFAcode = (verificationCode: string) => {
    dispatch(loaderSlice.actions.increaseLoader());
    dispatch(loginMFA({ confirmationCode: verificationCode }))
      .then(({ type, payload }: PayloadAction<unknown>) => {
        if (type === 'auth/loginMFA/rejected') {
          dispatch(addNotificationItem({ key: 'mfaCodeValidationFailed' }));
        }

        if (type === 'auth/loginMFA/fulfilled') {
          handleExpiredPassword(payload);
        }
      })
      .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
  };

  return (
    <div className="sign-in">
      <h2 className={styles.taglineHeader}>Sign in to your account</h2>
      {isMFACodeVisible ? (
        <div className={styles.verificationCodeContainer}>
          <p className={styles.verificationDescription}>Please confirm your account with the code sent to your phone</p>
          <MFACode sendMFAcode={sendMFAcode} resendMFACode={resendMFAVerificationCode} />
        </div>
      ) : (
        <div className={styles.signInContainer}>
          <form autoComplete="off" onSubmit={handleSubmit(signInHandler)}>
            <FormField labelId="email" labelText="Email address" fieldError={errors.email as TFieldError}>
              <Input placeholder="Type your email address" id="email" {...register('email')} />
            </FormField>
            <FormField labelId="password" labelText="Password" fieldError={errors.password as TFieldError}>
              <Input placeholder="Type your password" id="password" type="password" {...register('password')} />
            </FormField>
            <div className={styles.forgotPasswordSection}>
              <NavigationLink>
                <Link to="/auth/forgot-password">Forgot password?</Link>
              </NavigationLink>
            </div>
            <div className={styles.controlSection}>
              <Button type="submit" fullWidth disabled={loaderState > 0}>
                Sign in
              </Button>
            </div>
          </form>
        </div>
      )}
      <ContactUs promoLabel="Need help signing in?" />
    </div>
  );
};
