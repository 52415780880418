import React from 'react';
import cx from 'clsx';

import { Tooltip } from 'components';
import { handleCardNumber, getWalletPrice } from 'utils/helpers';
import { useAppDispatch, useAppSelector, useModal, useUserInfo } from 'hooks';
import { initTenancyAccount } from 'store/actions/wallet';
import { BankAccountDetailsModalComponent } from '../BankAccountDetailsModal';
import { EditBankDetailsModal } from '../EditBankDetailsModal';
import { ApproveEditBankDetailsModal } from '../ApproveEditBankDetailsModal';
import { IInfoPaneItemProps } from './types';
import styles from './InfoPaneItem.module.scss';

const InfoPaneItemComponent: React.FC<IInfoPaneItemProps> = (props): JSX.Element => {
  const { data, type, labelText } = props;
  const { walletId, newBankDetailsRequested } = useAppSelector((store) => store.wallet.walletInfo);
  const { email } = useAppSelector((store) => store.selectedListing.landlordInfo);
  const { role } = useUserInfo();
  const [isAccountModalOpen, toggleAccountModalOpen] = useModal();
  const [isEditBankDetailsModalOpen, toggleEditBankDetailsModalOpen] = useModal();
  const [isApproveEditBankDetailsOpen, toggleApproveEditBankDetailsModalOpen] = useModal();

  const dispatch = useAppDispatch();

  const handleInitTenancyAccount = () => {
    if (walletId && email) dispatch(initTenancyAccount({ walletId, userEmail: email }));
  };

  const renderInlineContent = () => {
    return <span className={styles.paneContentTitle}>{data}</span>;
  };

  const renderNextMounthAdjustment = () => {
    return (
      <>
        <span className={styles.paneTenancyRateNextTitle}>Next rent payment</span>
        <span className={styles.paneTenancyRateNextContent}>
          {getWalletPrice(data.monthlyTenancyRate - data.nextMonthlyTenancyRate)}
        </span>
      </>
    );
  };

  const renderMonthlyTenancyRateContent = () => {
    return (
      <div className={styles.paneTenancyRateContainer}>
        <span className={styles.paneContentTitle}>{data.monthlyTenancyRateLabel}</span>
        {data.nextMonthlyTenancyRate > 0 && data.lastMonthlyRateAdjustmentReason && renderNextMounthAdjustment()}
      </div>
    );
  };

  const renderLandlordAccount = (): JSX.Element => {
    const { landlordAccountName, landlordAccountNumber } = data;

    return (
      <>
        <span className={styles.paneContentTitle}>{landlordAccountName}</span>
        <div className={styles.paneLandlordContent}>
          <span className={styles.paneLandlordAccountNumber}>{handleCardNumber(landlordAccountNumber)}</span>
          <button className={cx(styles.editLineBtn, styles.paneLandlordAccountView)} onClick={toggleAccountModalOpen}>
            View
          </button>
        </div>
        {role !== 'Landlord' && role !== 'Agent' && newBankDetailsRequested && (
          <div>
            <button
              onClick={toggleApproveEditBankDetailsModalOpen}
              className={cx(styles.editLineBtn, styles.approveEditBtn)}
            >
              Approve new bank account
            </button>
          </div>
        )}
        <BankAccountDetailsModalComponent isOpen={isAccountModalOpen} toggle={toggleAccountModalOpen} options={data} />
        {isApproveEditBankDetailsOpen && (
          <ApproveEditBankDetailsModal
            isOpen={isApproveEditBankDetailsOpen}
            toggle={toggleApproveEditBankDetailsModalOpen}
          />
        )}
      </>
    );
  };

  const renderTenancyAccountAction = (): JSX.Element => {
    return (
      <button className={styles.tenancyAccountAction} onClick={handleInitTenancyAccount}>
        <div className={styles.tenancyAccountActionIcon}>+</div>
        <span className={styles.tenancyAccountActionRow}>Create new Home Made</span>
        <span className={styles.tenancyAccountActionRow}>Account Number</span>
      </button>
    );
  };

  const renderTenancyAccountPending = (): JSX.Element => {
    return (
      <div className={styles.tenancyAccountPending}>
        <div className={styles.tenancyAccountSortCode}>
          <span className={styles.tenancyAccountTitle}>Sort code</span>
          <span className={styles.tenancyAccountState}>Pending</span>
        </div>
        <div className={styles.tenancyAccountAccountNumber}>
          <span className={styles.tenancyAccountTitle}>Account number</span>
          <span className={styles.tenancyAccountState}>Pending</span>
        </div>
      </div>
    );
  };

  const renderTenancyAccountContent = ({ tenancyAccountNumber, tenancyAccountSortCode }: any): JSX.Element => {
    return (
      <div className={styles.tenancyAccountPending}>
        <div className={styles.tenancyAccountSortCode}>
          <span className={styles.tenancyAccountTitle}>Sort code</span>
          <span className={styles.tenancyAccountCompleteState}>{tenancyAccountSortCode}</span>
        </div>
        <div className={styles.tenancyAccountAccountNumber}>
          <span className={styles.tenancyAccountTitle}>Account number</span>
          <span className={styles.tenancyAccountCompleteState}>{tenancyAccountNumber}</span>
        </div>
      </div>
    );
  };

  const renderTenancyAccount = (): JSX.Element | null => {
    const { tenancyLedgerId, tenancyAccountNumber, tenancyAccountSortCode } = data;

    if (!tenancyLedgerId) {
      return renderTenancyAccountAction();
    }

    if (tenancyLedgerId && !tenancyAccountSortCode) {
      return renderTenancyAccountPending();
    }

    return renderTenancyAccountContent({ tenancyAccountNumber, tenancyAccountSortCode });
  };

  const renderContent = (): JSX.Element | null => {
    switch (type) {
      case 'landlordAccount':
        return renderLandlordAccount();
      case 'tenancyAccount':
        return renderTenancyAccount();
      case 'monthlyTenancy':
        return renderMonthlyTenancyRateContent();
      default:
        return renderInlineContent();
    }
  };

  return (
    <div className={styles.infoPaneItem}>
      <div className={styles.paneTitleContainer}>
        <span className={styles.paneTitleWrapper}>
          <span className={styles.paneTitle}>{labelText}</span>
          {type === 'landlordAccount' && (
            <Tooltip text="These are the details of the account we will pay your rent into" placement="right-start" />
          )}
        </span>
        {role !== 'Agent' && type === 'landlordAccount' && (
          <button
            className={cx(styles.editLineBtn, styles.paneLandlordAccountView)}
            onClick={toggleEditBankDetailsModalOpen}
          >
            Edit
          </button>
        )}
        {isEditBankDetailsModalOpen && (
          <EditBankDetailsModal isOpen={isEditBankDetailsModalOpen} toggle={toggleEditBankDetailsModalOpen} />
        )}
      </div>
      <div className={styles.paneDelimiter} />
      <div className={styles.paneContentContainer}>{renderContent()}</div>
    </div>
  );
};

export const InfoPaneItem = React.memo(InfoPaneItemComponent);
