import { TListingStatusResponse } from 'types';

export interface IPropertyByListingIdResponse {
  id: string;
  listingId: string;
  price: number;
  enquiriesNumber: number;
  viewingsNumber: number;
  offersNumber: number;
  savingsNumber: number;
  petsAllowed: boolean;
  furnished: boolean;
  parkingAvailable: boolean;
  bedroomsNumber: number;
  bathroomsNumber: number;
  availableDate: string;
  bookings: IBookingsViews[];
}

interface IBookingsViews {
  bookingDate: Date;
}

export interface IViewsByListingIdResponse {
  viewDate: string;
  price: number;
  totalSummaryViews: number;
  desktopSummaryViews: number;
  mobileSummaryViews: number;
  totalDetailViews: number;
  desktopDetailViews: number;
  mobileDetailViews: number;
  displayAddress: string;
}

export interface ITenancyTerminationInfoResponse {
  id: string;
  newTenancyTerminationDateRequested: boolean;
  existing: {
    tenancyEndDate: Date;
  };
  updated: {
    tenancyEndDate: Date;
  };
}

export interface IListingByPropertySalesforceIdResponse {
  id: string;
  salesforceId: string;
  listingId: string;
  address: string;
  status: TListingStatusResponse;
  price: number;
  priceReductionDate: Date;
  currency: string;
  tenancyStartDate: Date;
  tenancyEndDate: Date;
  tenancySalesforceId: string;
  enquiriesNumber: number;
  viewingsNumber: number;
  offersNumber: number;
  savingsNumber: number;
  petsAllowed: true;
  furnished: true;
  parkingAvailable: true;
  bedroomsNumber: number;
  bathroomsNumber: number;
  availableDate: Date;
  poiReceivedDate: Date;
  referencingDate: Date;
  contractDate: Date;
  rentDepositDate: Date;
  invoicingTimestamp: Date;
  propertySalesforceId: string;
  ownerId: string;
  createdAt: Date;
  updatedAt: Date;
  documents: IListingsDocument[];
}

export interface IListingsDocument {
  id: string;
  title: string;
  fileExtension: string;
  type: TDocumentType;
}

export interface IPropertyResponse {
  id: string;
  salesforceId: string;
  lastListingListingId: string;
  lastListingSalesforceListingId: string;
  name: string;
  ownerId: string;
  createdAt: Date;
  updatedAt: Date;
  listings: IListingByPropertySalesforceIdResponse[];
}

export interface IListingResponse {
  id: string;
  salesforceId: string;
  listingId: string;
  address: string;
  status: TListingStatusResponse;
  price: number;
  currency: string;
  tenancyStartDate: string;
  tenancyEndDate: string;
  enquiriesNumber: number;
  viewingsNumber: number;
  offersNumber: number;
  savingsNumber: number;
  petsAllowed: boolean;
  furnished: boolean;
  parkingAvailable: boolean;
  bedroomsNumber: number;
  bathroomsNumber: number;
  availableDate: string;
  poiReceivedDate: string;
  priceReductionDate: string | null;
  referencingDate: string;
  contractDate: string;
  rentDepositDate: string;
  invoicingTimestamp: string;
  walletSortCode: string;
  walletAccountNumber: string;
  ownerId: string;
  createdAt: string;
  updatedAt: string;
  rentManagement: boolean;
  propertySalesforceId: string;
  contactInfo: IContactInfo;
}

export interface IContactInfo {
  landlord: {
    email: string;
    fullName: string;
    phoneNumber: string;
  };
}

export type TDocumentType = 'GSC' | 'EICR' | 'EPC' | 'Tenancy Agreement';

export const documentTypeMap: TDocumentType[] = ['GSC', 'EICR', 'EPC', 'Tenancy Agreement'];
