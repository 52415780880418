import * as yup from 'yup';

export const handleInvoiceDetailsSchema = yup.object().shape({
  authorName: yup.string().typeError('This field is required').required('This field is required'),
  amount: yup
    .number()
    .typeError('Should be a number')
    .min(0.01, 'Should be greater than 0')
    .test('decimal', 'Should be lower than two decimal', (value) => /^\d*.?\d{0,2}$/.test(`${value}`))
    .required('This field is required'),
  serviceDescription: yup.string().typeError('This field is required').required('This field is required'),
  date: yup.string().typeError('This field is required').required('This field is required'),
  invoiceNumber: yup.string().typeError('This field is required').required('This field is required'),
});
