import React from 'react';
import cx from 'clsx';

import { IButtonProps } from './types';
import styles from './Button.module.scss';

const ButtonComponent = React.forwardRef(function Button(props: IButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const { theme, type, size, children, className, beforeIcon, fullWidth, ...rest } = props;

  const buttonClasses = cx(styles.button, className, styles[size as string], {
    [styles.fullWidth]: fullWidth,
    [styles.primary]: theme === 'primary',
    [styles.secondary]: theme === 'secondary',
    [styles.beforeIcon]: !!beforeIcon,
  });

  return (
    <button type={type} className={buttonClasses} {...rest} ref={ref}>
      {beforeIcon}
      {children && <span className={styles.text}>{children}</span>}
    </button>
  );
});

ButtonComponent.defaultProps = {
  type: 'button',
  theme: 'primary',
  size: 'medium',
  beforeIcon: undefined,
};

export const Button = React.memo(ButtonComponent);
