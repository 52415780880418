import { createAsyncThunk } from '@reduxjs/toolkit';

import { signOut } from 'utils/cognito/signOut';
import { cognitoAuth } from 'utils/cognito';
import { LoginParams, LoginMFAParams } from 'types';

export const login = createAsyncThunk('auth/login', async ({ email, password }: LoginParams, { rejectWithValue }) => {
  try {
    const data = await cognitoAuth.signIn(email, password);

    return {
      idToken: {
        jwtToken: data.getIdToken().getJwtToken(),
        payload: data.getIdToken().decodePayload(),
      },
      accessToken: {
        jwtToken: data.getAccessToken().getJwtToken(),
        payload: data.getAccessToken().decodePayload(),
      },
      refreshToken: {
        jwtToken: data.getRefreshToken().getToken(),
      },
      isValid: data.isValid(),
    };
  } catch (e) {
    if (e instanceof Error && e.message === 'newPasswordRequired') {
      throw rejectWithValue(new Error('newPasswordRequired'));
    } else if (e instanceof Error && e.message === 'mfaRequired') {
      throw rejectWithValue(new Error('mfaRequired'));
    } else {
      throw rejectWithValue(e);
    }
  }
});

export const loginMFA = createAsyncThunk(
  'auth/loginMFA',
  async ({ confirmationCode }: LoginMFAParams, { rejectWithValue }) => {
    try {
      const data = await cognitoAuth.sendMFAcode(confirmationCode);

      return {
        idToken: {
          jwtToken: data.getIdToken().getJwtToken(),
          payload: data.getIdToken().decodePayload(),
        },
        accessToken: {
          jwtToken: data.getAccessToken().getJwtToken(),
          payload: data.getAccessToken().decodePayload(),
        },
        refreshToken: {
          jwtToken: data.getRefreshToken().getToken(),
        },
        isValid: data.isValid(),
      };
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  signOut();
});
