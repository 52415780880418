import React from 'react';

import { MarketingStatsItem } from '../MarketingStatsItem';
import { IMarketingStatsProps } from './types';
import { marketingStatsMap } from '../constants';
import styles from './MarketingStats.module.scss';

const MarketingStatsComponent: React.FC<IMarketingStatsProps> = (data) => {
  return (
    <div className={styles.dashboardMarketingStats}>
      {marketingStatsMap.map(({ key, title }) => (
        <MarketingStatsItem value={data[key as keyof IMarketingStatsProps]} labelText={title} key={key} />
      ))}
    </div>
  );
};
export const MarketingStats = React.memo(MarketingStatsComponent);
