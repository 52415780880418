import React from 'react';
import cx from 'clsx';

import { TableHeadCell, TableRow, TableRowCell } from './internal';

import { ITableRowItemField, ITableRowItemFields, THeadItem, IOnCellClickParams } from './types';
import styles from './Table.module.scss';

interface ITableProps {
  THeadItems: THeadItem[];
  TBodyItems: ITableRowItemFields[];
  TGridTemplate: string[];
  onRowClick?(id: string): void;
  onCellClick?(params: IOnCellClickParams): void;
  renderBodyRow?: (items: ITableRowItemFields) => React.ReactNode;
  hasHoverableRows?: boolean;
  className?: string;
}

const getColumnWidth = (map: ITableProps['TGridTemplate']) => ({
  gridTemplateColumns: `${map.join(' ')}`,
});

const TableComponent: React.FC<ITableProps> = ({
  THeadItems,
  TBodyItems,
  TGridTemplate,
  onRowClick,
  onCellClick,
  renderBodyRow,
  hasHoverableRows,
  className,
}) => {
  const renderTBodyRow =
    renderBodyRow ||
    ((items: ITableRowItemFields) => (
      <TableRow onClick={onRowClick} id={items.id} key={items.id} isHoverable={hasHoverableRows}>
        {THeadItems.map((headItem: THeadItem, index: number) => {
          const cellItem = items.values.find(
            (bodyItem: ITableRowItemField) => headItem.fieldName === bodyItem.fieldName
          );
          return (
            cellItem && (
              <TableRowCell
                key={index}
                onClick={(params: IOnCellClickParams) =>
                  onCellClick && onCellClick(Object.assign(params, { rowId: items.id }))
                }
                {...cellItem}
              />
            )
          );
        })}
      </TableRow>
    ));

  const renderTHead = (): JSX.Element => (
    <TableRow>
      {THeadItems.map((el) => (
        <TableHeadCell {...el} key={el.fieldName} />
      ))}
    </TableRow>
  );

  const renderTBody = (): JSX.Element => (
    <>{TBodyItems.map((TBodyRowItems: ITableRowItemFields) => renderTBodyRow(TBodyRowItems))}</>
  );

  return (
    <table className={cx(styles.table, className)} style={getColumnWidth(TGridTemplate)}>
      <thead className={styles.thead}>{renderTHead()}</thead>
      <tbody className={styles.tbody}>{renderTBody()}</tbody>
    </table>
  );
};

TableComponent.defaultProps = {
  renderBodyRow: undefined,
  onRowClick: undefined,
  hasHoverableRows: false,
  className: '',
};

export const Table = React.memo(TableComponent);
