import React from 'react';

interface IUseControlledProps<T> {
  controlled: T | undefined;
  default: T;
}

export const useControlledRef = <T>({ controlled, default: defaultValue }: IUseControlledProps<T>) => {
  const { current: isControlled } = React.useRef(controlled !== undefined);
  const valueRef = React.useRef(defaultValue);

  if (isControlled) {
    valueRef.current = controlled as T;
  }

  const setValue = React.useCallback((newValue: T) => {
    valueRef.current = newValue;
  }, []);

  return [valueRef, setValue] as const;
};
