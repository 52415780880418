import { router } from 'router';
import { AppDispatch } from 'store';
import { addNotificationItem } from 'store/actions/notifications';
import { selectedListingSlice } from 'store/reducers/SelectedListingSlice';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import {
  IUpdateListingByListingIdPayload,
  IListingUpdateRentManagmentPayload,
  IRequestUpdateTerminationDatePayload,
  IListingTerminationInfoPayload,
  IHandleListingTerminationDatePayload,
  ISetSelectedListingPayload,
  IGetListingDetailsPayload,
  IGetListingDataBySalesforceListingIdPayload,
  IGetPropertyDetailsBySalesforcePropertyIdPayload,
  ISuggestionsPayload,
  ISetSelectedPropertyPayload,
} from 'types';
import {
  getListingDetailsCall,
  getListingDetailsBySalesforceListingIdCall,
  getListingViewsBySalesforceListingIdCall,
  updateListingByListingIdCall,
  updateListingRentManagmentCall,
  requestUpdateListingTerminationDateCall,
  getListingTerminationInfoCall,
  approveListingTerminationDateCall,
  rejectListingTerminationDateCall,
  getPropertyDetailsBySalesforcePropertyIdCall,
  getListingSuggestionsCall,
  getPropertySuggestionsCall,
} from 'store/api/listing';
import {
  handleSelectedListingInfo,
  handleLandlordInfo,
  handleChartListingData,
  getTotalAdViewsNumber,
  getViewingsNumber,
  handleListingTerminationInfo,
  handleListingsHistoryTableItem,
  handleListingsHistoryItem,
  handleListingSuggestionsItem,
  handlePropertySuggestionsItem,
} from 'store/models/selectedListing';

export const getListingDetails = (params: IGetListingDetailsPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const data = await getListingDetailsCall(params);

    const selectedPropertyInfo = handleSelectedListingInfo(data);
    const landlordInfo = handleLandlordInfo(data);
    const suggestionsDefaultOption = handleListingSuggestionsItem(data);

    dispatch(selectedListingSlice.actions.setListingInfo(selectedPropertyInfo));
    dispatch(selectedListingSlice.actions.setLandlordInfo(landlordInfo));
    dispatch(selectedListingSlice.actions.setListingSuggestionsDefaultOption(suggestionsDefaultOption));
  } catch (e: any) {
    if (e.code === 'NOT_FOUND') {
      router.navigate('/main/properties');
    } else {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    }
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const getListingSuggestions = (params: ISuggestionsPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    const data = await getListingSuggestionsCall(params);
    const suggestionsList = data.map(handleListingSuggestionsItem);
    dispatch(selectedListingSlice.actions.setListingSuggestionsList(suggestionsList));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const getPropertySuggestions = (params: ISuggestionsPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    const data = await getPropertySuggestionsCall(params);
    const suggestionsList = data.map(handlePropertySuggestionsItem);
    dispatch(selectedListingSlice.actions.setPropertySuggestionsList(suggestionsList));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const setSelectedListing = (payload: ISetSelectedListingPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    dispatch(selectedListingSlice.actions.setSelectedListing(payload));
    if (payload.listingId) {
      dispatch(getListingDetails({ listingId: payload.listingId }));
      dispatch(getListingSuggestions({}));
    }
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const setSelectedProperty = (params: ISetSelectedPropertyPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    dispatch(setSelectedListing(params));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const getPropertyDetailsBySalesforcePropertyId =
  (params: IGetPropertyDetailsBySalesforcePropertyIdPayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.increaseLoader());

      const property = await getPropertyDetailsBySalesforcePropertyIdCall(params);

      const handledListingsHistoryItems = property.listings.map(handleListingsHistoryItem);
      const handledListingsHistoryTableItems = property.listings.map(handleListingsHistoryTableItem);
      const suggestionsDefaultOption = handlePropertySuggestionsItem(property);

      dispatch(selectedListingSlice.actions.setListingsHistoryItems(handledListingsHistoryItems));
      dispatch(selectedListingSlice.actions.setListingsHistoryTableItems(handledListingsHistoryTableItems));
      dispatch(selectedListingSlice.actions.setPropertySuggestionsDefaultOption(suggestionsDefaultOption));

      if (!params.skipDocumentSync) {
        dispatch(getPropertySuggestions({}));
      }
    } catch (e) {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    } finally {
      dispatch(loaderSlice.actions.decreaseLoader());
    }
  };

export const getListingViewsDetailsBySalesforceListingId =
  (params: IGetListingDataBySalesforceListingIdPayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.increaseLoader());

      const listingData = await getListingDetailsBySalesforceListingIdCall(params);
      const viewsData = await getListingViewsBySalesforceListingIdCall(params);

      const chartListingData = handleChartListingData({
        viewsData,
        listingData,
      });
      const { plannedViewings, pastViewings } = getViewingsNumber(listingData);
      const adViewingsNumber = getTotalAdViewsNumber(viewsData);

      dispatch(
        selectedListingSlice.actions.setListingViewingsNumber({
          adViewingsNumber,
          plannedViewings,
          pastViewings,
        })
      );
      dispatch(selectedListingSlice.actions.setListingsViewsInfo(chartListingData));
    } catch (e) {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    } finally {
      dispatch(loaderSlice.actions.decreaseLoader());
    }
  };

export const updateListingByListingId = (params: IUpdateListingByListingIdPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const data = await updateListingByListingIdCall(params);
    const updatedSelectedListingInfo = handleSelectedListingInfo(data);

    dispatch(selectedListingSlice.actions.setListingInfo(updatedSelectedListingInfo));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateListingRentManagement =
  (params: IListingUpdateRentManagmentPayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.increaseLoader());

      const data = await updateListingRentManagmentCall(params);
      const updatedSelectedPropertyInfo = handleSelectedListingInfo(data);

      dispatch(selectedListingSlice.actions.setListingInfo(updatedSelectedPropertyInfo));
    } catch (e) {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    } finally {
      dispatch(loaderSlice.actions.decreaseLoader());
    }
  };

export const requestUpdateListingTerminationDate =
  (params: IRequestUpdateTerminationDatePayload) => async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.increaseLoader());

      await requestUpdateListingTerminationDateCall(params);
    } catch (e) {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    } finally {
      dispatch(loaderSlice.actions.decreaseLoader());
    }
  };

export const getListingTerminationInfo = (params: IListingTerminationInfoPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const data = await getListingTerminationInfoCall(params);
    const listingTerminationInfo = handleListingTerminationInfo(data);
    dispatch(selectedListingSlice.actions.setListingTerminationInfo(listingTerminationInfo));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const handleListingTerminationDate =
  ({ listingId, flag }: IHandleListingTerminationDatePayload) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(loaderSlice.actions.increaseLoader());
      if (flag) {
        await approveListingTerminationDateCall({ listingId });
      } else {
        await rejectListingTerminationDateCall({ listingId });
      }
      dispatch(getListingTerminationInfo({ listingId }));
    } catch (e) {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    } finally {
      dispatch(loaderSlice.actions.decreaseLoader());
    }
  };
