import { format, parseISO } from 'date-fns';
import { getCalculateNumberDays } from './handlers';

const getUTCDate = (dateString: Date) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getFullDate = (date: string | Date) => {
  return date ? format(new Date(date), 'd MMMM yyyy') : '';
};

export const getShortDate = (date: string) => {
  return date ? format(new Date(date), 'dd.MM.yyyy') : '';
};

export const getShortDateWithTime = (date: string) => {
  return date ? format(parseISO(date), 'dd.MM.yyyy HH:mm') : '';
};

export const getDayMonthDate = (date: string) => {
  return date ? format(new Date(date), 'dd.MM') : '';
};

export const getPPDate = (date: string) => {
  return date ? format(new Date(date), 'PP') : '';
};

export const getTenancyCompletedDate = (date: string) => {
  return date ? format(new Date(date), 'dd.MM.yy') : '';
};

export const getRentDueDate = (date: string): string => {
  const currentDate = new Date();
  const calculatedDateFromISO = new Date(date);

  return calculatedDateFromISO < currentDate ? `${getCalculateNumberDays(currentDate, calculatedDateFromISO)}` : '';
};
