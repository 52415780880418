import React, { useState } from 'react';
import { useInput } from 'react-day-picker';
import format from 'date-fns/format';
import cx from 'clsx';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Button, Input, Modal } from 'components';
import { Datepicker } from 'components/elements/Datepicker';
import { requestUpdateListingTerminationDate } from 'store/actions/selectedListing';
import lockImg from 'images/lock.svg';
import styles from './TenancyDatesModal.module.scss';

const DATE_FORMAT = 'dd/MM/yyyy';

export interface ITenancyDatesModalProps {
  isOpen: boolean;
  toggle(): void;
  options: any;
}

export const TenancyDatesModal: React.FC<ITenancyDatesModalProps> = ({ isOpen, toggle, options }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { listingId } = useAppSelector((state) => state.selectedListing);
  const dispatch = useAppDispatch();
  const { inputProps, dayPickerProps } = useInput({
    defaultSelected: new Date(options.endDate),
    required: true,
    fromDate: new Date(options.startDate),
    format: DATE_FORMAT,
  });

  const handleNotify = () => {
    if (listingId) {
      dispatch(
        requestUpdateListingTerminationDate({
          listingId,
          date: format(new Date(dayPickerProps.selected as Date), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        })
      );
    }

    toggle();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Edit tenancy date</h2>

      <p className={styles.description}>
        Your request for change will be verified by a Home Made team member and you will be notified by email once
        approved
      </p>

      <div className={styles.container}>
        <span className={styles.startDateLabel}>Tenancy started</span>

        <div className={styles.startDateInput}>
          <Input
            readOnly
            rightEnhancer={<img src={lockImg} alt="Locked" />}
            value={format(new Date(options.startDate), DATE_FORMAT)}
            size="small"
          />
        </div>

        <span className={styles.endDateLabel}>Tenancy end date</span>

        <div className={styles.endDateInput}>
          <Input
            readOnly={!showDatePicker}
            wrapperClassName={cx({
              [styles.endDateInputSelected]: showDatePicker,
            })}
            rightEnhancer={
              <button className={styles.editButton} onClick={() => setShowDatePicker(true)}>
                Edit
              </button>
            }
            size="small"
            {...inputProps}
          />
        </div>

        {showDatePicker && (
          <Datepicker
            mode="single"
            className={styles.datePicker}
            {...dayPickerProps}
            selected={dayPickerProps.selected as Date | undefined}
          />
        )}

        <div className={styles.notifyButton}>
          <Button fullWidth size="small" onClick={handleNotify}>
            Submit change request
          </Button>
        </div>
      </div>
    </Modal>
  );
};
