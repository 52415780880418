import React from 'react';

import { Modal, Button, FormField, Input } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { handleEditBankDetailsRequest, getWalletInfoByListingId } from 'store/actions/wallet';
import { IApproveEditBankDetailsModalProps } from './types';
import styles from './ApproveEditBankDetailsModal.module.scss';

export const ApproveEditBankDetailsModalComponent: React.FC<IApproveEditBankDetailsModalProps> = ({
  isOpen,
  toggle,
}) => {
  const dispatch = useAppDispatch();
  const { listingId } = useAppSelector((store) => store.selectedListing);
  const { landlordAccount, updatedLandlordAccount, walletInfo } = useAppSelector((store) => store.wallet);
  const { landlordAccountName, landlordAccountSortCode, landlordAccountNumber } = landlordAccount;
  const { updatedLandlordAccountName, updatedLandlordAccountNumber, updatedLandlordAccountSortCode } =
    updatedLandlordAccount;
  const { walletId } = walletInfo;

  const handleEditBankDetailsRequestCallback = async (flag: boolean) => {
    if (walletId && listingId) {
      await dispatch(handleEditBankDetailsRequest({ walletId, flag }));
      dispatch(getWalletInfoByListingId({ listingId }));
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalSidepane}>
          <h2 className={styles.sideTitle}>Existing bank details</h2>
          <p className={styles.sideItem}>
            <span className={styles.sideItemTitle}>Account Name</span>
            <span className={styles.sideItemContent}>{landlordAccountName}</span>
          </p>
          <p className={styles.sideItem}>
            <span className={styles.sideItemTitle}>Sort code</span>
            <span className={styles.sideItemContent}>{landlordAccountSortCode}</span>
          </p>
          <p className={styles.sideItem}>
            <span className={styles.sideItemTitle}>Account number</span>
            <span className={styles.sideItemContent}>{landlordAccountNumber}</span>
          </p>
        </div>

        <div>
          <h2 className={styles.header}>Approval needed</h2>
          <p className={styles.description}>Please approve updated bank details</p>
          <div className={styles.inputSection}>
            <FormField labelId="accountName" labelText="Account Name">
              <Input readOnly size="small" id="accountName" value={updatedLandlordAccountName} />
            </FormField>
            <FormField labelId="sortCode" labelText="Sort code">
              <Input readOnly size="small" id="sortCode" value={updatedLandlordAccountSortCode} />
            </FormField>
            <FormField labelId="accountNumber" labelText="Account number">
              <Input readOnly size="small" id="accountNumber" value={updatedLandlordAccountNumber} />
            </FormField>
            <div className={styles.controlSection}>
              <Button
                className={styles.controlButton}
                size="small"
                onClick={() => handleEditBankDetailsRequestCallback(true)}
              >
                Approve
              </Button>
              <Button
                className={styles.controlButton}
                size="small"
                onClick={() => handleEditBankDetailsRequestCallback(false)}
                theme="secondary"
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const ApproveEditBankDetailsModal = React.memo(ApproveEditBankDetailsModalComponent);
