import React from 'react';
import ReactModal from 'react-modal';
import cx from 'clsx';

import closeImg from 'images/close-icon.svg';
import styles from './Modal.module.scss';

ReactModal.setAppElement('#modalContainer');

export interface IModalProps extends ReactModal.Props {
  hasCloseButton?: boolean;
}

export const Modal: React.FC<IModalProps> = ({ children, hasCloseButton, className, ...modalProps }) => (
  <ReactModal
    className={cx(styles.modal, className)}
    overlayClassName={styles.overlay}
    {...modalProps}
    bodyOpenClassName={styles.bodyOpenClassName}
  >
    {hasCloseButton && (
      <button className={styles.closeButton} onClick={modalProps.onRequestClose}>
        <img src={closeImg} alt="Close modal" />
      </button>
    )}
    {children}
  </ReactModal>
);

Modal.defaultProps = {
  hasCloseButton: true,
};
