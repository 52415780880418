import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { getListingViewsDetailsBySalesforceListingId } from 'store/actions/selectedListing';
import { Card, ListingSelect } from 'components';
import { AdDetails, MarketingStats, SavingsCard, ViewsPerformanceChart } from './internal';
import { adDetailsTitleMap, marketingStatsTitleMap } from './internal/constants';
import styles from './DashboardPage.module.scss';

export const DashboardPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { salesforceListingId, listingInfo, viewingsInfo, viewsInfo } = useAppSelector(
    (state) => state.selectedListing
  );
  const { yesterdayViews, chartListingData } = viewsInfo;

  useEffect(() => {
    if (salesforceListingId) {
      dispatch(getListingViewsDetailsBySalesforceListingId({ salesforceListingId }));
    }
  }, [salesforceListingId]);

  return (
    <div className={styles.dashboardPage}>
      <ListingSelect className={styles.dashboardPageSelectWrapper} />
      <div className={styles.dashboardPageWrapper}>
        <div className={styles.leftSide}>
          <Card titleText={marketingStatsTitleMap.title} tooltipText={marketingStatsTitleMap.tooltipText}>
            <div className={styles.cardContentWrapper}>
              <MarketingStats {...listingInfo} {...viewingsInfo} />
            </div>
          </Card>
          <Card titleText="Daily listing views performance">
            <div className={styles.cardContentWrapper}>
              <ViewsPerformanceChart array={chartListingData} yesterdayViews={yesterdayViews} />
            </div>
          </Card>
        </div>
        <div className={styles.rightSide}>
          <Card titleText={adDetailsTitleMap.title} tooltipText={adDetailsTitleMap.tooltipText}>
            <AdDetails {...listingInfo} />
          </Card>
          <SavingsCard {...listingInfo} />
        </div>
      </div>
    </div>
  );
};
