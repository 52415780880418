import React from 'react';

import { IControlOption, TControlOptionValue } from '../../../../Checkbox/types';
import { useValueRef } from '../../../../../../hooks/useValueRef';
import { useControlledRef } from '../../../../../../hooks/useControlledRef';

interface IUseCheckboxGroupOptionsProps {
  options: IControlOption[];
  value?: TControlOptionValue[];
  defaultValue?: TControlOptionValue[];
  onChange?(e: React.ChangeEvent<HTMLInputElement>, value: TControlOptionValue[]): void;
}

export const useCheckboxGroupOptions = (props: IUseCheckboxGroupOptionsProps) => {
  const { options } = props;
  const propsRefValue = useValueRef(props);
  const [valueRef, setValue] = useControlledRef({
    controlled: props.value,
    default: props.defaultValue || [],
  });

  return React.useMemo(
    () =>
      options.map((option) => ({
        ...option,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const optionValue = option.value;
          const { onChange } = propsRefValue.current;

          if (!onChange) {
            return;
          }

          const prevValue = valueRef.current;
          const isChecked = prevValue.includes(optionValue);

          if (isChecked) {
            setValue(prevValue.filter((v) => v !== optionValue));
          } else {
            setValue([...prevValue, optionValue]);
          }

          onChange(e, valueRef.current);
        },
      })),
    [options, propsRefValue, valueRef, setValue]
  );
};
