import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWalletState, IUpdatedLandlordAccount } from 'types';

const initialState: IWalletState = {
  walletInfo: {
    walletId: null,
    rentDueDate: null,
    totalTenancyRate: null,
    newBankDetailsRequested: false,
  },
  monthlyTenancy: {
    monthlyTenancyRate: null,
    monthlyTenancyRateLabel: null,
    nextMonthlyTenancyRate: null,
    lastMonthlyRateAdjustmentReason: null,
  },
  landlordAccount: {
    landlordAccountName: '',
    landlordAccountNumber: '',
    landlordAccountSortCode: '',
  },
  updatedLandlordAccount: {
    updatedLandlordAccountName: undefined,
    updatedLandlordAccountNumber: undefined,
    updatedLandlordAccountSortCode: undefined,
  },
  tenancyAccount: {
    tenancyLedgerId: null,
    tenancyAccountNumber: null,
    tenancyAccountSortCode: null,
  },
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletState(state, action: PayloadAction<IWalletState>) {
      state.walletInfo = action.payload.walletInfo;
      state.landlordAccount = action.payload.landlordAccount;
      state.tenancyAccount = action.payload.tenancyAccount;
      state.monthlyTenancy = action.payload.monthlyTenancy;
    },
    setUpdatedBankDetailsState(state, action: PayloadAction<IUpdatedLandlordAccount>) {
      state.updatedLandlordAccount = action.payload;
    },
    resetWalletState() {
      return initialState;
    },
  },
});

export default walletSlice.reducer;
