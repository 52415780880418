import * as yup from 'yup';

export const passwordSchema = yup
  .string()
  .required('This field is required')
  .min(8, 'Is not less than 8 characters')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
    'Password should match the rules'
  );
