import * as React from 'react';
import cx from 'clsx';

import { Radio } from '../Radio';
import { IRadioGroupProps } from './types';
import styles from './RadioGroup.module.scss';

function RadioGroupComponent(props: IRadioGroupProps) {
  const { options, name, disabled, horizontal, checkedValue, onChange } = props;

  return (
    <div className={cx(styles.checkboxGroup, horizontal ? styles.horizontalGroup : styles.verticalGroup)}>
      {options.map(({ value, label }) => {
        const key = String(value);

        return (
          <Radio
            key={key}
            id={`${name}-${value}`}
            name={name}
            value={value}
            checked={value === checkedValue}
            disabled={disabled}
            label={label}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
}

export const RadioGroup = React.memo(RadioGroupComponent);
