import qs from 'qs';

import { axiosPublic, axiosPrivate, handleErrorResponse } from 'utils/axios';
import {
  ICreateUserPayload,
  IGetUserListPayload,
  IUserListResponse,
  IPatchUserStatusPayload,
  IPatchReInviteUserPayload,
  IUser,
  IUpdateUserAccountPayload,
} from 'types';

export const getUserInfo = async (jwtToken: string) =>
  axiosPublic
    .get<IUser>('profile/v1/user-info', {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    })
    .then((response) => response.data);

export const updateUserAccountCall = async ({ userId, payload }: IUpdateUserAccountPayload) =>
  axiosPrivate
    .patch<IUser>(`profile/v1/users/${userId}`, payload)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const getUserListCall = async (params: IGetUserListPayload) =>
  axiosPrivate
    .get<IUserListResponse>('profile/v1/users', {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((response) => response.data);

export const createUser = async (params: ICreateUserPayload) =>
  axiosPrivate.put<IUser>('profile/v1/users', { ...params }).then((response) => response.data);

export const sendVerificationCodeCall = async () =>
  axiosPrivate.post<IUser>('profile/v1/users/send-verification-code').then((response) => response.data);

export const activateUserCall = async (params: IPatchUserStatusPayload) =>
  axiosPrivate.post<IUser>('profile/v1/users/enable', params).then((response) => response.data);

export const deactivateUserCall = async (params: IPatchUserStatusPayload) =>
  axiosPrivate.post<IUser>('profile/v1/users/disable', params).then((response) => response.data);

export const reInviteUserCall = async (params: IPatchReInviteUserPayload) =>
  axiosPrivate.post<IUser>(`profile/v1/users/send-onboarding-invite`, params).then((response) => response.data);
