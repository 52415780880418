import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';

import { setSelectedListing } from 'store/actions/selectedListing';
import { CheckboxGroup, Pagination, SearchField, Select, Table } from 'components';
import { useAppDispatch, useAppSelector, usePrevious } from 'hooks';
import { getListingsList } from 'store/actions/listingsList';
import { handleFilters } from 'utils/helpers';
import {
  defaultFilterValues,
  defaultSortOption,
  propertiesColumnsTitle,
  propertiesFilterOptions,
  propertiesSortingOptions,
  propertiesSortingOptionsMap,
  statusToPathMap,
} from 'constants/properties';

import styles from './PropertiesPage.module.scss';

const columnsWidth = ['4fr', '1fr', '2fr', '2fr', '2fr'];

export const ListingsPage: React.FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPage, setSelectedPage] = useState<number>(Number(searchParams.get('selectedPage')) || 1);
  const [filterValues, setFilterValues] = useState<string[]>(
    searchParams.getAll('filterValues').length > 0 ? searchParams.getAll('filterValues') : defaultFilterValues
  );
  const [sortingValue, setSortingValue] = useState<string>(searchParams.get('sortingValue') || defaultSortOption.value);
  const [search, setSearch] = useState<string>(searchParams.get('search') || '');
  const prevSelectedPage = usePrevious(selectedPage);
  const { listingsTableItems, listingsItems } = useAppSelector((state) => state.listingsList);
  const { totalItems, pageSize } = useAppSelector((state) => state.listingsList.listingsPaginationConfig);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getListingsListAction = ({ page }: Record<string, number>): void => {
    dispatch(
      getListingsList({
        page,
        pageSize,
        search,
        filterByStatus: handleFilters(filterValues),
        ...propertiesSortingOptionsMap[sortingValue as keyof typeof propertiesSortingOptionsMap],
      })
    );
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, inputValue: string[]): void => {
    if (e.target.checked && e.target.value === 'all') {
      setFilterValues(defaultFilterValues);
    } else if (!e.target.checked && e.target.value === 'all') {
      setFilterValues([]);
    } else {
      setFilterValues(inputValue.filter((el) => el !== 'all'));
    }
  };

  const handleNavigation = (id: string): void => {
    const selectedListingItem = listingsItems.find((listing) => listing.listingId === id);

    if (selectedListingItem) {
      const { listingId, salesforceListingId, salesforcePropertyId, status } = selectedListingItem;

      dispatch(setSelectedListing({ listingId, salesforceListingId, salesforcePropertyId }));
      navigate(`/main/${statusToPathMap[status]}`);
    }
  };

  const handleSearchSubmit = () => {
    getListingsListAction({ page: selectedPage });
    setSearchParams(createSearchParams({ selectedPage: `${selectedPage}`, filterValues, sortingValue, search }));
  };

  useEffect(() => {
    if (!prevSelectedPage && !searchParams.get('selectedPage')) {
      getListingsListAction({ page: 1 });
    } else if (selectedPage === prevSelectedPage && selectedPage !== 1) {
      setSelectedPage(1);
    } else {
      getListingsListAction({ page: selectedPage !== prevSelectedPage ? selectedPage : 1 });
    }

    setSearchParams(createSearchParams({ selectedPage: `${selectedPage}`, filterValues, sortingValue, search }));
  }, [selectedPage, sortingValue, filterValues]);

  return (
    <div className={styles.propertiesPage}>
      <div className={styles.propertiesSearch}>
        <SearchField
          placeholder="Search for a property"
          value={search}
          onChange={(e, value) => setSearch(value)}
          onSubmit={handleSearchSubmit}
        />
      </div>
      <div className={styles.propertiesPageControls}>
        <div className={styles.propertiesPageFilters}>
          <CheckboxGroup
            horizontal
            options={propertiesFilterOptions}
            onChange={handleFilterChange}
            value={filterValues}
          />
        </div>
        <div className={styles.propertiesPageSorting}>
          <Select value={sortingValue} onChange={setSortingValue} options={propertiesSortingOptions} />
        </div>
        <div className={styles.propertiesPagePagination}>
          <Pagination
            selectedPage={selectedPage}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChanged={(page: number) => setSelectedPage(page)}
          />
        </div>
      </div>
      <Table
        hasHoverableRows
        THeadItems={propertiesColumnsTitle}
        TBodyItems={listingsTableItems}
        TGridTemplate={columnsWidth}
        onRowClick={(id) => handleNavigation(id)}
        className={styles.propertiesTable}
      />
    </div>
  );
};
