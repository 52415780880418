import React from 'react';
import { Navigate, useRoutes, createBrowserRouter } from 'react-router-dom';

import { mainRoutesMap, IRouteItem } from 'constants/routes';
import { useAuthData, useUserInfo } from 'hooks';
import { UserRole } from 'types';
import {
  AuthPage,
  DashboardPage,
  DocumentsPage,
  ForgotPasswordPage,
  MainPage,
  ManageUsersPage,
  NewPasswordPage,
  ListingsPage,
  ListingHistoryPage,
  SignInPage,
  ConfirmPasswordPage,
  SignUpPage,
  TenantsPage,
  WalletPage,
  ManageFinancesPage,
  ExpiredPasswordPage,
  SystemStatusPage,
} from 'pages';

import App from '../App';

export const router = createBrowserRouter([{ path: '*', element: <App /> }]);

const defaultAuthRoute = <Navigate to="/auth/sign-in" />;
const defaultMainRoute = <Navigate to="/main/properties" />;

const isProperRole = (routeItem: IRouteItem, role: UserRole) =>
  !role || !routeItem.allowedForRoles || routeItem.allowedForRoles.includes(role);

const authRoutes = [
  {
    path: '/auth',
    element: <AuthPage />,
    children: [
      {
        path: 'sign-in',
        element: <SignInPage />,
      },
      {
        path: 'new-password',
        element: <NewPasswordPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'confirm-password',
        element: <ConfirmPasswordPage />,
      },
      {
        path: 'confirm-password/:email',
        element: <ConfirmPasswordPage />,
      },
      {
        path: 'expired-password',
        element: <ExpiredPasswordPage />,
      },
      // disabled due to non functional flow
      // {
      //   path: 'sign-up',
      //   element: <SignUpPage />,
      // },
      // disabled due to missed functionality HM-291
    ],
  },
];

const getDefaultRoute = (isAuthenticated: boolean) => ({
  path: '/',
  element: isAuthenticated ? defaultMainRoute : defaultAuthRoute,
});

const getMainRoutes = (isAuthenticated: boolean, role: UserRole) => [
  {
    path: '/main',
    element: isAuthenticated ? <MainPage /> : defaultAuthRoute,
    children: [
      {
        path: mainRoutesMap.properties.path,
        element: isProperRole(mainRoutesMap.properties, role) ? <ListingsPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.tenancies.path,
        element: isProperRole(mainRoutesMap.tenancies, role) ? <ListingHistoryPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.dashboard.path,
        element: isProperRole(mainRoutesMap.dashboard, role) ? <DashboardPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.tenants.path,
        element: isProperRole(mainRoutesMap.tenants, role) ? <TenantsPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.income.path,
        element: isProperRole(mainRoutesMap.income, role) ? <WalletPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.documents.path,
        element: isProperRole(mainRoutesMap.documents, role) ? <DocumentsPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.users.path,
        element: isProperRole(mainRoutesMap.users, role) ? <ManageUsersPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.finances.path,
        element: isProperRole(mainRoutesMap.finances, role) ? <ManageFinancesPage /> : defaultMainRoute,
      },
      {
        path: mainRoutesMap.status.path,
        element: isProperRole(mainRoutesMap.status, role) ? <SystemStatusPage /> : defaultMainRoute,
      },
    ],
  },
];

const getRoutes = (isLoggedIn: boolean, role: UserRole) => [
  getDefaultRoute(isLoggedIn),
  ...authRoutes,
  ...getMainRoutes(isLoggedIn, role),
];

export const Router = () => {
  const { isAuthenticated } = useAuthData();
  const { role } = useUserInfo();

  return useRoutes(getRoutes(isAuthenticated, role));
};
