import { v4 as uuidv4 } from 'uuid';

import { getShortDateWithTime } from 'utils/helpers/date';
import { auditNameTypeMap } from 'constants/analytics';
import {
  TAuditLogGroupListResponse,
  IAuditLogResponse,
  ILogEventResponse,
  IAuditLog,
  IAuditLogGroup,
  TAuditLogStatus,
  IAuditEvent,
} from 'types';

const getAuditLogGroupStatus = (auditLogGroupList: IAuditLogResponse[]): TAuditLogStatus => {
  const filteredOKAuditLogList = auditLogGroupList.filter(({ status }) => status === 'OK');

  if (filteredOKAuditLogList.length === auditLogGroupList.length) {
    return 'OK';
  }
  if (filteredOKAuditLogList.length > 0) {
    return 'WARNING';
  }
  return 'ERROR';
};

const getAuditLogDetailsLabelForMultiple = (auditLogGroupList: IAuditLogResponse[]) => {
  const filteredOKAuditLogList = auditLogGroupList.filter(({ status }) => status === 'OK');
  return `${filteredOKAuditLogList.length}/${auditLogGroupList.length} tasks completed successfully`;
};

const getAuditLogDetailsLabelForSingle = (auditLogGroupList: IAuditLogResponse[]) => {
  return `${auditLogGroupList[0].status}: ${auditLogGroupList[0].logName}`;
};

const getAuditLogExecutedLabel = (auditLogTime: string) => getShortDateWithTime(auditLogTime);

const handleLogEvents = ({ status, name, details }: ILogEventResponse): IAuditEvent => {
  return {
    status,
    name,
    details,
  };
};

const handledAuditLog = ({ id, logName, status, logTime, logEvents }: IAuditLogResponse): IAuditLog => {
  return {
    id,
    name: logName,
    status,
    logTime: getAuditLogExecutedLabel(logTime),
    logType: auditNameTypeMap[logName as keyof TAuditLogGroupListResponse],
    logEvents: logEvents.map((event) => handleLogEvents(event)),
  };
};

export const handleAuditLogGroupList = (auditLogGroupList: TAuditLogGroupListResponse): IAuditLogGroup[] | [] => {
  const handledAuditLogGroupList = [];

  for (const key in auditLogGroupList) {
    if (auditLogGroupList.hasOwnProperty(key)) {
      const auditLogGroupItem = auditLogGroupList[key as keyof TAuditLogGroupListResponse];

      const auditLogGroup = {
        id: `${uuidv4()}`,
        name: key,
        status: getAuditLogGroupStatus(auditLogGroupItem),
        logTime: getAuditLogExecutedLabel(auditLogGroupItem[0].logTime),
        logType: auditNameTypeMap[key as keyof TAuditLogGroupListResponse],
        details:
          auditLogGroupItem.length > 1
            ? getAuditLogDetailsLabelForMultiple(auditLogGroupItem)
            : getAuditLogDetailsLabelForSingle(auditLogGroupItem),
        children: auditLogGroupItem.length > 1 ? auditLogGroupItem.map(handledAuditLog) : [],
        logEvents:
          auditLogGroupItem.length === 1 ? auditLogGroupItem[0].logEvents.map((event) => handleLogEvents(event)) : [],
      };

      handledAuditLogGroupList.push(auditLogGroup);
    }
  }

  return handledAuditLogGroupList;
};
