import React, { useEffect } from 'react';
import { Outlet, useSearchParams, useLocation } from 'react-router-dom';

import { setSelectedListing } from 'store/actions/selectedListing';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Header, Sidebar } from 'components';
import { getUserData } from 'store/actions/userData';
import { AuthState } from 'types';

import styles from './MainPage.module.scss';

export const MainPage: React.FC = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);
  const { listingId, salesforceListingId, salesforcePropertyId } = useAppSelector((state) => state.selectedListing);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const LI = searchParams.get('LI');
    const SLI = searchParams.get('SLI');
    const SPI = searchParams.get('SPI');

    if (LI && SLI && SPI) {
      dispatch(
        setSelectedListing({
          listingId: LI,
          salesforceListingId: SLI,
          salesforcePropertyId: SPI,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (listingId && salesforceListingId && salesforcePropertyId) {
      setSearchParams({ LI: listingId, SLI: salesforceListingId, SPI: salesforcePropertyId });
    }
  }, [pathname]);

  useEffect(() => {
    if (user) {
      dispatch(getUserData((user as AuthState['user'])?.accessToken?.jwtToken || ''));
    }
  }, [user]);

  return (
    <div className={styles.mainPage}>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.main}>
        <Header />
        <Outlet />
      </div>
    </div>
  );
};
