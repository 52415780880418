import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector, useUserInfo } from 'hooks';
import { ListingSelect } from 'components';
import { getWalletInfoByListingId } from 'store/actions/wallet';
import { getListingTerminationInfo } from 'store/actions/selectedListing';
import { InfoPane } from './internal/InfoPane';
import { TenancyTerminationBar } from './internal/TenancyTerminationBar';
import { ApproveEditBankDetailsBar } from './internal/ApproveEditBankDetailsBar';
import { Transaction } from './internal/Transaction';
import { ApproveEditTenancyTerminationBar } from './internal/ApproveEditTenancyTerminationBar';

import styles from './WalletPage.module.scss';

export const WalletPage: React.FC = (): JSX.Element => {
  const { walletInfo, monthlyTenancy, landlordAccount, tenancyAccount } = useAppSelector((state) => state.wallet);
  const { listingId, listingInfo, listingTerminationInfo } = useAppSelector((state) => state.selectedListing);
  const { role } = useUserInfo();
  const { rentManagement, tenancyStartDate, tenancyEndDate } = listingInfo;
  const { newListingTerminationDateRequested } = listingTerminationInfo;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (listingId) {
      dispatch(getWalletInfoByListingId({ listingId }));
      dispatch(getListingTerminationInfo({ listingId }));
    }
  }, [listingId]);

  return (
    <div className={styles.walletPage}>
      <div className={styles.selectWrapper}>
        <ListingSelect />
      </div>
      {role === 'Landlord' && tenancyStartDate && tenancyEndDate && (
        <TenancyTerminationBar startDate={tenancyStartDate} endDate={tenancyEndDate} />
      )}
      {role !== 'Landlord' && newListingTerminationDateRequested && <ApproveEditTenancyTerminationBar />}
      {role !== 'Landlord' && role !== 'Agent' && walletInfo.newBankDetailsRequested && <ApproveEditBankDetailsBar />}
      <InfoPane
        {...walletInfo}
        monthlyTenancy={monthlyTenancy}
        landlordAccount={landlordAccount}
        tenancyAccount={tenancyAccount}
        rentManagement={rentManagement}
      />
      <Transaction />
    </div>
  );
};
