import * as yup from 'yup';

export const newPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'Is not less than 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
      'Password should match the rules'
    )
    .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match'),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .min(8, 'Is not less than 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/,
      'Password should match the rules'
    )
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  terms: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
});
