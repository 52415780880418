import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const defaultDisabledSidebarItems = ['tenancies', 'dashboard', 'tenants', 'income', 'documents'];

interface IViewConfigState {
  disabledSidebarItems: string[];
}

const initialState: IViewConfigState = {
  disabledSidebarItems: defaultDisabledSidebarItems,
};

export const viewConfigSlice = createSlice({
  name: 'viewConfig',
  initialState,
  reducers: {
    toggleDisabledSidebarItems(state, action: PayloadAction<boolean>) {
      state.disabledSidebarItems = action.payload ? [] : defaultDisabledSidebarItems;
    },
  },
});

export default viewConfigSlice.reducer;
