import React from 'react';

import { useAppDispatch } from 'hooks';
import { appModalSlice } from 'store/reducers/AppModalSlice';
import { ReactComponent as AlertIcon } from 'images/alert-icon.svg';

import styles from './ApproveEditTenancyTerminationBar.module.scss';

const ApproveEditTenancyTerminationBarComponent: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const openApproveEditModal = () =>
    dispatch(
      appModalSlice.actions.setAppModalState({
        type: 'approveEditTenancyTermination',
        options: null,
      })
    );

  return (
    <div className={styles.approveEditTenancyTerminationBar}>
      <p className={styles.description}>
        <span className={styles.iconWrapper}>
          <AlertIcon />
        </span>
        The tenancy termination date for this property has been changed and needs to be approved.
        <button className={styles.appoveButton} onClick={openApproveEditModal}>
          Approve now
        </button>
      </p>
    </div>
  );
};

export const ApproveEditTenancyTerminationBar = React.memo(ApproveEditTenancyTerminationBarComponent);
