import React from 'react';

import { Modal, Button } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateListingRentManagement } from 'store/actions/selectedListing';
import styles from './OptOutModal.module.scss';

export interface IDeleteInvoiceModalProps {
  isOpen: boolean;
  toggle(): void;
}

export const OptOutModalComponent: React.FC<IDeleteInvoiceModalProps> = ({ isOpen, toggle }) => {
  const dispatch = useAppDispatch();
  const { listingId } = useAppSelector((state) => state.selectedListing);

  const handleOptOutAction = () => {
    if (listingId) {
      dispatch(updateListingRentManagement({ listingId, rentManagement: false }));
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Opt-out of rental collection</h2>

      <div className={styles.description}>
        <p>Are you sure you want to opt-out of the rental collection service?</p>
      </div>

      <div className={styles.controlSection}>
        <Button fullWidth size="small" onClick={handleOptOutAction}>
          Yes, I want to opt-out
        </Button>

        <div className={styles.backContol}>
          No, I don’t want to opt-out.{' '}
          <button className={styles.backContolLabel} onClick={toggle}>
            Go back.
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const OptOutModal = React.memo(OptOutModalComponent);
