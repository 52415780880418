import { axiosPrivate, handleErrorResponse } from 'utils/axios';

import { TAuditLogGroupListResponse, TAuditType } from 'types';

export const getAuditLogGroupListCall = async () =>
  axiosPrivate
    .get<TAuditLogGroupListResponse>(`/analytics/v1/audit-logs`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncMarketingStatistics = async () =>
  axiosPrivate
    .post(`/sync/analytics/v1/views/per-day`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncRailsbank = async () =>
  axiosPrivate
    .post(`/finance/v1/wallet/transactions/all`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncListingViewRecords = async () =>
  axiosPrivate
    .post(`/sync/property/v1/bookings/from`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncLandlordContact = async () =>
  axiosPrivate
    .post(`/sync/profile/v1/from`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncProperties = async () =>
  axiosPrivate
    .post(`/sync/property/v1/properties/from`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncRegularPayments = async () =>
  axiosPrivate
    .post(`/finance/v1/payments-schedule/from`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const postSyncUpcomingPayments = async () =>
  axiosPrivate
    .post(`/finance/v1/upcoming-payments/generate-per-date`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

/**
 * Map with API calls for sync data in salesforce
 * We need to keep this order according to salesforce and backend requirements
 */

export const syncCallMap: Record<TAuditType, () => Promise<any> | void> = {
  landlordContact: postSyncLandlordContact,
  properties: postSyncProperties,
  listingViewRecords: postSyncListingViewRecords,
  regularPayments: postSyncRegularPayments,
  marketingStatistics: postSyncMarketingStatistics,
  upcomingPayments: postSyncUpcomingPayments,
  railsbank: postSyncRailsbank,
  salesforce: () => {},
};
