import React, { useState } from 'react';

import { useAppSelector } from 'hooks';

import { AdDetailsItem } from '../index';
import { IAdDetailsProps } from './types';
import { adDetailsMap } from '../constants';
import styles from './AdDetails.module.scss';

const AdDetailsComponent: React.FC<IAdDetailsProps> = (props): JSX.Element => {
  const { status, priceReductionDate } = useAppSelector((state) => state.selectedListing.listingInfo);
  const [activeItem, setActiveItem] = useState('');

  const renderAdDetailsItems = () => {
    return adDetailsMap.map(({ key, title, icon }) => (
      <AdDetailsItem
        key={key}
        propertyStatus={status}
        labelText={title}
        value={props[key as keyof IAdDetailsProps]}
        icon={icon}
        type={key}
        active={activeItem === key}
        priceReductionDate={priceReductionDate}
        actionCallback={setActiveItem}
      />
    ));
  };

  return <div className={styles.adDetails}>{renderAdDetailsItems()}</div>;
};
export const AdDetails = React.memo(AdDetailsComponent);
