import { Button, DayProps, useActiveModifiers, useDayRender } from 'react-day-picker';
import React from 'react';
import cx from 'clsx';
import customStyles from '../Datepicker.module.scss';

export const Day = ({ date, displayMonth }: DayProps): JSX.Element => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);
  const activeModifiers = useActiveModifiers(date, displayMonth);

  if (dayRender.isHidden) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  const { selected } = activeModifiers;

  return (
    <Button
      ref={buttonRef}
      {...dayRender.buttonProps}
      className={cx(dayRender.buttonProps.className, {
        [customStyles.selectedDayButton]: !!selected,
      })}
    />
  );
};
