import React from 'react';

import { ICONS } from './constants';

export const Icon = ({ icon, size, color, className }: any) => {
  const styles = {
    svg: {
      display: 'inline-block',
      color,
    },
  };

  const icons = {
    [ICONS.PLUS]: (
      <>
        <path
          fill="currentColor"
          d="m20.45604,20.45603c4.6586,-4.65859 4.6586,-12.24099 0,-16.89959s-12.241,-4.65859 -16.8996,0s-4.6586,12.24099 0,16.89959s12.241,4.65859 16.8996,0zm-16.05656,-16.05656c4.19566,-4.19566 11.01787,-4.19566 15.21353,0s4.19566,11.01787 0,15.21353s-11.01787,4.19566 -15.21353,0s-4.19079,-11.01787 0,-15.21353z"
        />
        <path
          fill="currentColor"
          d="m12.00625,16.47966c0.16568,0 0.31187,-0.06822 0.42395,-0.17543c0.10721,-0.10721 0.17543,-0.25827 0.17543,-0.42395l0,-3.27953l3.27954,0c0.16568,0 0.31187,-0.06822 0.42395,-0.17543c0.10721,-0.10721 0.17543,-0.25827 0.17543,-0.42395c0,-0.33136 -0.26802,-0.59938 -0.59451,-0.59451l-3.27954,0l0,-3.27953c0,-0.33136 -0.26802,-0.59938 -0.59451,-0.59451c-0.33136,0 -0.59938,0.26802 -0.59451,0.59451l0,3.27953l-3.27954,0c-0.33136,0 -0.59938,0.26802 -0.59451,0.59451c0,0.33136 0.26802,0.59938 0.59451,0.59451l3.27954,0l0,3.27953c-0.01462,0.33624 0.2534,0.60425 0.58476,0.60425z"
        />
      </>
    ),
    [ICONS.PLUS_MINUS]: (
      <path
        fill="currentColor"
        d="m19.74309,5.90664l-14.36228,13.98306a0.79789,0.77682 0 0 1 -1.12838,-1.09859l14.36228,-13.98306a0.79789,0.77682 0 0 1 1.12838,1.09859zm-14.12857,4.88856a0.7979,0.77684 0 0 0 1.59581,0l0,-3.10735l3.19162,0a0.7979,0.77684 0 0 0 0,-1.55367l-3.19162,0l0,-3.10735a0.7979,0.77684 0 0 0 -1.59581,0l0,3.10735l-3.19162,0a0.7979,0.77684 0 0 0 0,1.55367l3.19162,0l0,3.10735zm15.95809,6.21469l-7.97905,0a0.7979,0.77684 0 0 0 0,1.55367l7.97905,0a0.7979,0.77684 0 0 0 0,-1.55367z"
      />
    ),
    [ICONS.LOUP]: (
      <path
        fill="currentColor"
        d="m15.0938,16.5938c-1.38987,0.99036 -3.09047,1.57288 -4.92712,1.57288c-4.69442,0 -8.5,-3.80558 -8.5,-8.5c0,-4.69442 3.80558,-8.5 8.5,-8.5c4.69442,0 8.5,3.80558 8.5,8.5c0,2.34721 -0.95139,4.47221 -2.48959,6.01041l5.99736,5.99736c0.27506,0.27506 0.26837,0.71609 -0.00777,0.99223c-0.27807,0.27807 -0.72038,0.27962 -0.99223,0.00777l-6.08065,-6.08065l0,0zm-4.92712,0.57288c4.14214,0 7.5,-3.35786 7.5,-7.5c0,-4.14214 -3.35786,-7.5 -7.5,-7.5c-4.14214,0 -7.5,3.35786 -7.5,7.5c0,4.14214 3.35786,7.5 7.5,7.5l0,0z"
      />
    ),
  };

  return (
    <svg
      style={styles.svg}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      {icons[icon]}
    </svg>
  );
};

Icon.defaultProps = {
  size: 24,
  color: 'currentColor',
  className: '',
};
