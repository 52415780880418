import { THeadItem } from 'components/elements/Table/types';

export const tenanciesHistoryColumnsTitle: THeadItem[] = [
  {
    value: 'Tenancy started',
    fieldName: 'startDate',
  },
  {
    value: 'Tenancy ends',
    fieldName: 'endDate',
  },
  {
    value: 'Rent/pcm',
    fieldName: 'propertyPrice',
  },
  {
    value: 'AST',
    fieldName: 'Tenancy Agreement',
    tooltipText: 'Tenancy Agreement',
  },
  {
    value: 'EICR',
    fieldName: 'EICR',
    tooltipText: 'Electrical Installation Condition Report',
  },
  {
    value: 'EPC',
    fieldName: 'EPC',
    tooltipText: 'Energy Performance Certificate',
  },
  {
    value: 'GSC',
    fieldName: 'GSC',
    tooltipText: 'Gas Safety Certificate',
  },
  {
    value: 'Action',
    fieldName: 'action',
  },
];
