import React from 'react';
import cx from 'clsx';

import { IFormFieldProps } from './types';
import styles from './FormField.module.scss';

const FormFieldComponent: React.FC<IFormFieldProps> = (props) => {
  const { children, labelId, labelText, fieldError, className } = props;

  return (
    <div className={cx(styles.formField, className)}>
      <label className={styles.label} htmlFor={labelId}>
        {labelText}
      </label>

      {children}

      {fieldError && <span className={styles.error}>{fieldError.message}</span>}
    </div>
  );
};

export const FormField = React.memo(FormFieldComponent);
