import React from 'react';
import { useModal } from 'hooks';
import { ReactComponent as AlertIcon } from 'images/alert-icon.svg';
import { ApproveEditBankDetailsModal } from '../ApproveEditBankDetailsModal';

import styles from './ApproveEditBankDetailsBar.module.scss';

const ApproveEditBankDetailsBarComponent: React.FC = (): JSX.Element => {
  const [isApproveEditBankDetailsOpen, toggleApproveEditBankDetailsModalOpen] = useModal();

  return (
    <div className={styles.approveEditBankDetailsBar}>
      <p className={styles.description}>
        <span className={styles.iconWrapper}>
          <AlertIcon />
        </span>
        The bank details for this property have changed and need to be approved.
        <button className={styles.appoveButton} onClick={toggleApproveEditBankDetailsModalOpen}>
          Approve now
        </button>
      </p>
      {isApproveEditBankDetailsOpen && (
        <ApproveEditBankDetailsModal
          isOpen={isApproveEditBankDetailsOpen}
          toggle={toggleApproveEditBankDetailsModalOpen}
        />
      )}
    </div>
  );
};

export const ApproveEditBankDetailsBar = React.memo(ApproveEditBankDetailsBarComponent);
