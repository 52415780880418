import React from 'react';

import { Tooltip } from 'components';
import { THeadItem } from '../types';

import styles from './index.module.scss';

const TableHeadCellComponent: React.FC<THeadItem> = ({ value, tooltipText }): JSX.Element => {
  return (
    <th className={styles.th} key={value}>
      {tooltipText ? (
        <>
          {value}
          <Tooltip text={tooltipText} type="info" placement="right-start" />
        </>
      ) : (
        value
      )}
    </th>
  );
};

export const TableHeadCell = React.memo(TableHeadCellComponent);
