import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PropertySelect, Table } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { selectedListingSlice } from 'store/reducers/SelectedListingSlice';
import { getPropertyDetailsBySalesforcePropertyId } from 'store/actions/selectedListing';
import { IOnCellClickWithRowIdParams } from 'components/elements/Table/types';
import { downloadDocumentByDocumentId } from 'store/api/documents';
import { statusToPathMap } from 'constants/properties';

import { tenanciesHistoryColumnsTitle } from './internal/constants';

import styles from './ListingHistoryPage.module.scss';

const columnsWidth = ['3fr', '3fr', '2fr', '2fr', '2fr', '2fr', '2fr', '2fr'];

export const ListingHistoryPage: React.FC = (): JSX.Element => {
  const { salesforcePropertyId } = useAppSelector((state) => state.selectedListing);
  const { listingHistoryItems, listingHistoryTableItems } = useAppSelector(
    (state) => state.selectedListing.listingHistoryInfo
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const downloadListingDocument = (documentId: string, title: string, fileExtension: string) => {
    if (documentId) {
      dispatch(loaderSlice.actions.increaseLoader());
      downloadDocumentByDocumentId({ documentId })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${title}.${fileExtension}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => console.error(new Error(e)))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    }
  };

  const handleNavigate = (id: string) => {
    const selectedListing = listingHistoryItems.find(({ listingId }) => listingId === id);
    if (selectedListing) {
      dispatch(
        selectedListingSlice.actions.setSelectedListing({
          listingId: selectedListing?.listingId,
          salesforceListingId: selectedListing?.salesforceListingId,
          salesforcePropertyId: selectedListing?.salesforcePropertyId,
        })
      );
      navigate(`/main/${statusToPathMap[selectedListing?.status as keyof typeof statusToPathMap]}`);
    }
  };

  const handleDownload = ({ value, rowId }: IOnCellClickWithRowIdParams) => {
    const selectedTenancyItem = listingHistoryItems.find(({ listingId }) => listingId === rowId);
    const selectedTenancyDocument = selectedTenancyItem?.documents.find((document) => document.id === value);

    if (selectedTenancyDocument) {
      const { id, title, fileExtension } = selectedTenancyDocument;
      downloadListingDocument(id, title, fileExtension);
    }
  };

  const onCellClick = ({ value, rowId, fieldName }: IOnCellClickWithRowIdParams) => {
    if (fieldName === 'action') {
      handleNavigate(rowId);
    } else {
      handleDownload({ value, rowId, fieldName });
    }
  };

  const fetchAsyncListAndDocuments = async () => {
    if (salesforcePropertyId) {
      await dispatch(
        getPropertyDetailsBySalesforcePropertyId({
          salesforcePropertyId,
          skipDocumentSync: false,
        })
      );
    }
  };

  useEffect(() => {
    if (salesforcePropertyId) {
      fetchAsyncListAndDocuments();
    }
  }, []);

  useEffect(() => {
    if (salesforcePropertyId) {
      fetchAsyncListAndDocuments();
    }
  }, [salesforcePropertyId]);

  return (
    <div className={styles.tenanciesHistoryPage}>
      <PropertySelect className={styles.propertySelect} />
      <Table
        className={styles.tenanciesHistoryTable}
        THeadItems={tenanciesHistoryColumnsTitle}
        TBodyItems={listingHistoryTableItems}
        TGridTemplate={columnsWidth}
        onCellClick={onCellClick}
      />
    </div>
  );
};
