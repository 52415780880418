import { axiosPrivate, handleErrorResponse } from 'utils/axios';
import {
  IGetWalletResponse,
  IInitAccountPayload,
  IPatchBankDetailsPayload,
  IGetWalletInfoByListingIdPayload,
  IBankDetailsPayload,
  IAdjustNextMonthsRentPayload,
} from 'types';

export const getWalletInfoByListingIdCall = async ({ listingId }: IGetWalletInfoByListingIdPayload) =>
  axiosPrivate
    .get<IGetWalletResponse>(`finance/v1/wallet/by-tenancy-id/${listingId}`)
    .then((response) => response.data);

export const postAdjustNextMonthsRentCall = async ({ walletId, amount, reason }: IAdjustNextMonthsRentPayload) =>
  axiosPrivate
    .post<IGetWalletResponse>(`finance/v1/wallet/adjust-month-rent`, { amount, reason, id: walletId })
    .then((response) => response.data);

export const initTenancyAccountCall = async ({ walletId, userEmail }: IInitAccountPayload) =>
  axiosPrivate
    .post<IGetWalletResponse>(`finance/v1/wallet/${walletId}/init-accounts`, { userEmail })
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const patchBankDetailsRequestCall = async ({ walletId, payload }: IPatchBankDetailsPayload) =>
  axiosPrivate
    .post<IGetWalletResponse>(`finance/v1/wallet/${walletId}/bank-details/request`, payload)
    .then((response) => response.data);

export const patchBankDetailsCall = async ({ walletId, payload }: IPatchBankDetailsPayload) =>
  axiosPrivate
    .post<IGetWalletResponse>(`finance/v1/wallet/${walletId}/bank-details/edit`, payload)
    .then((response) => response.data);

export const approveEditBankDetailsRequestCall = async ({ walletId }: IBankDetailsPayload) =>
  axiosPrivate
    .post<IGetWalletResponse>(`finance/v1/wallet/${walletId}/bank-details/approve`)
    .then((response) => response.data);

export const rejectEditBankDetailsRequestCall = async ({ walletId }: IBankDetailsPayload) =>
  axiosPrivate
    .post<IGetWalletResponse>(`finance/v1/wallet/${walletId}/bank-details/reject`)
    .then((response) => response.data);

export const getUpdatedBankDetailsCall = async ({ walletId }: IBankDetailsPayload) =>
  axiosPrivate.get<IGetWalletResponse>(`finance/v1/wallet/${walletId}/bank-details`).then((response) => response.data);
