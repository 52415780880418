import React from 'react';

import { passwordRules } from 'constants/passwordRules';

import styles from './PasswordRules.module.scss';

export const PasswordRules: React.FC = (): JSX.Element => {
  return (
    <div className={styles.passwordRules}>
      <h4 className={styles.passwordRulesTitle}>Make sure your password meets the following:</h4>
      <ul className={styles.passwordRulesList}>
        {passwordRules.map((rule, index) => (
          <li className={styles.rule} key={index}>
            {rule}
          </li>
        ))}
      </ul>
    </div>
  );
};
