// @ts-nocheck
import React, { useEffect } from 'react';

import { Button } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getAuditLogGroupList, syncAllAuditLog } from 'store/actions/analytics';
import { AuditLogRow } from './internal';

import styles from './SystemStatusPage.module.scss';

export const SystemStatusPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { auditLogGroupList } = useAppSelector((state) => state.analytics);
  const { loaderState } = useAppSelector((state) => state.loader);

  useEffect(() => {
    dispatch(getAuditLogGroupList());
  }, []);

  const isLastAuditLogInGroupRow = (auditLogIndex, auditLogGroup) => auditLogIndex + 1 === auditLogGroup.length;

  const handleAllSync = () => dispatch(syncAllAuditLog());

  const renderAuditLogRow = ({ id, ...rest }, isBordered) => <AuditLogRow key={id} {...rest} isBordered={isBordered} />;

  const renderAuditLogGroupRow = () => {
    return auditLogGroupList.map(({ id, children, ...rest }) => {
      return (
        <AuditLogRow key={id} {...rest} isAuditGroup>
          {children.length > 0 &&
            children.map((auditLog, auditLogIndex) =>
              renderAuditLogRow(auditLog, isLastAuditLogInGroupRow(auditLogIndex, children))
            )}
        </AuditLogRow>
      );
    });
  };

  return (
    <div className={styles.systemStatusPage}>
      <div className={styles.auditLogList}>
        <div className={styles.auditLogListHeader}>
          <h2 className={styles.listHeaderItem}>Background job</h2>
          <Button
            className={styles.controlButton}
            theme="primary"
            size="small"
            disabled={loaderState > 0}
            onClick={handleAllSync}
          >
            Sync All
          </Button>
        </div>
        <div className={styles.auditLogListBody}>{renderAuditLogGroupRow()}</div>
      </div>
    </div>
  );
};
