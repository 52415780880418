import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks';
import { addNotificationItem } from 'store/actions/notifications';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { FormField, Input, Button, ContactUs, PasswordRules } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { cognitoAuth } from 'utils/cognito';
import { confirmPasswordShema } from 'utils/schemas';

import styles from './ConfirmPasswordPage.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
  verificationCode: string;
};

const resetPasswordSuccessMessage = 'Your reset password was sent to your email with further instructions';

export const ConfirmPasswordPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('userEmail');
  const code = searchParams.get('code');

  const defaultValues = {
    verificationCode: code || undefined,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(confirmPasswordShema),
  });

  const confirmPasswordHandler = ({ password, verificationCode }: FormValues): void => {
    if (email) {
      dispatch(loaderSlice.actions.increaseLoader());
      cognitoAuth
        .confirmPassword(email, verificationCode, password)
        .then(() => navigate('/auth/sign-in'))
        .catch((error) => dispatch(addNotificationItem({ type: 'error', label: error?.message })))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    }
  };

  const resendPasswordHandler = (): void => {
    if (email) {
      dispatch(loaderSlice.actions.increaseLoader());
      cognitoAuth
        .forgotPassword(email)
        .then(() => dispatch(addNotificationItem({ type: 'success', label: resetPasswordSuccessMessage })))
        .catch((error) => dispatch(addNotificationItem({ type: 'error', label: error?.message })))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    }
  };

  useEffect(() => {
    if (!email) navigate('/auth/forgot-password');
  }, []);

  return (
    <div className="sign-up">
      <h2 className={styles.taglineHeader}>Welcome to Home Made!</h2>
      <p className={styles.taglineBody}>Create your new password.</p>

      <PasswordRules />

      <form autoComplete="off" onSubmit={handleSubmit(confirmPasswordHandler)}>
        <FormField labelId="password" labelText="Create new password" fieldError={errors.password as TFieldError}>
          <Input placeholder="Type your password" id="password" type="password" {...register('password')} />
        </FormField>
        <FormField labelId="confirmPassword" labelText="Confirm password" fieldError={errors.password as TFieldError}>
          <Input
            placeholder="Confirm your new password"
            id="confirmPassword"
            type="password"
            {...register('confirmPassword')}
          />
        </FormField>
        <FormField
          labelId="verificationCode"
          labelText="Verification code"
          fieldError={errors.verificationCode as TFieldError}
        >
          <Input
            placeholder="Type your verification code"
            id="verificationCode"
            type="password"
            {...register('verificationCode')}
          />
        </FormField>
        <div className={styles.forgotPasswordSection}>
          <button type="button" className={styles.resendPasswordBtn} onClick={resendPasswordHandler}>
            Resend code
          </button>
        </div>
        <div className={styles.controlSection}>
          <Button type="submit" fullWidth>
            Create new password
          </Button>
        </div>
      </form>
      <ContactUs promoLabel="Need help signing in?" />
    </div>
  );
};
