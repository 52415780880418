import React from 'react';
import cx from 'clsx';

import styles from './HiddenInput.module.scss';

export interface IHiddenInputProps extends React.ComponentPropsWithoutRef<'input'> {}

export const HiddenInput = React.forwardRef(function HiddenInput(
  props: IHiddenInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return <input {...props} ref={ref} className={cx(styles.input, props.className)} aria-hidden />;
});
