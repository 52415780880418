import React, { FC } from 'react';
import { BarLoader } from 'react-spinners';

import { useAppSelector } from 'hooks';

import styles from './LoaderBar.module.scss';

export const LoaderBar: FC = (): JSX.Element => {
  const loaderState = useAppSelector((state) => state.loader.loaderState);
  return (
    <div className={styles.loader}>
      {loaderState > 0 ? <BarLoader height={5} width="100%" color="#38b7a1" /> : null}
    </div>
  );
};
