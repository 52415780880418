import React from 'react';

import { Button } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import generalStyles from 'components/features/AppModal/AppModal.module.scss';
import { updateListingByListingId } from 'store/actions/selectedListing';
import styles from './ApproveChangePrice.module.scss';
import { IApproveChangePriceProps } from './types';

const ApproveChangePriceComponent: React.FC<IApproveChangePriceProps> = ({ toggle, price }) => {
  const { listingId } = useAppSelector((state) => state.selectedListing);

  const dispatch = useAppDispatch();

  const handleChangePrice = () => {
    if (listingId) {
      dispatch(updateListingByListingId({ listingId, payload: { price } }));
      toggle();
    }
  };

  return (
    <div>
      <h2 className={generalStyles.header}>Are you sure you want to increase the price?</h2>
      <p className={styles.description}>
        This property&#39;s asking rent has already been changed recently. Frequent changes to the asking rent
        aren&#39;t recommended, as this can affect the performance on property portals
      </p>
      <div className={styles.controlSection}>
        <Button fullWidth size="small" onClick={handleChangePrice}>
          Keep current price
        </Button>

        <Button fullWidth theme="secondary" size="small" onClick={toggle}>
          No, I still want to change the price
        </Button>
      </div>
    </div>
  );
};

export const ApproveChangePrice = React.memo(ApproveChangePriceComponent);
