import React from 'react';
import cx from 'clsx';

import { ReactComponent as NoTransactionIcon } from 'images/no-transactions-icon.svg';
import { TransactionTableItemControls } from '../TransactionTableItemControls';
import { ITransactionTableProps } from './types';
import styles from './TransactionTable.module.scss';

const TransactionTableComponent: React.FC<ITransactionTableProps> = ({ transactions }): JSX.Element => {
  const renderGroupHeader = (label: string, type: string): JSX.Element => {
    return (
      <div className={styles.transactionBodyTitleContainer}>
        <div className={cx(styles.transactionBodyTitle, type === 'e1UpcomingPayment' && styles.upcoming)}>{label}</div>
        <div />
      </div>
    );
  };

  const renderTransactionAmount = ({ type, amountType, amountLabel }: any) => {
    if (amountType === 'transaction-type-send') {
      return (
        <>
          <span />
          <span
            className={cx(
              styles.amountCell,
              styles.amountMinusCell,
              type === 'e1UpcomingPayment' && styles.upcomingAmountCell
            )}
          >
            -{amountLabel}
          </span>
        </>
      );
    }

    return (
      <>
        <span
          className={cx(
            styles.amountCell,
            styles.amountPlusCell,
            type === 'e1UpcomingPayment' && styles.upcomingAmountCell
          )}
        >
          +{amountLabel}
        </span>
        <span />
      </>
    );
  };

  const renderGroupBody = (
    { id, date, amount, amountLabel, authorName, reference, invoiceNumber, serviceDescription, amountType }: any,
    type: any
  ): JSX.Element => {
    return (
      <div className={styles.transactionBodyContent} key={id}>
        <div />
        <div className={cx(styles.bodyContentCell, styles.detailsCell)}>
          <span className={styles.detailsCellAuthor}>{authorName}</span>
          <span className={styles.detailsRef}>Ref: {reference}</span>
        </div>
        <div className={styles.bodyContentCell}>
          <span className={cx(styles.dateCell, type === 'e1UpcomingPayment' && styles.upcomingDateCell)}>{date}</span>
        </div>
        <div className={cx(styles.bodyContentCell, styles.contentAmountCell)}>
          {renderTransactionAmount({ amountLabel, amountType, type })}
        </div>
        <div className={styles.bodyControlsCell}>
          {' '}
          {type === 'e1UpcomingPayment' && (
            <TransactionTableItemControls
              id={id}
              date={date}
              amount={amount}
              amountLabel={amountLabel}
              authorName={authorName}
              reference={reference}
              invoiceNumber={invoiceNumber}
              serviceDescription={serviceDescription}
            />
          )}
        </div>
      </div>
    );
  };

  const renderTransactionTableBodyItems = ({ label, type, transactionItemList, id }: any): JSX.Element => {
    return (
      <div key={id}>
        {renderGroupHeader(label, type)}
        {transactionItemList.map((transactionItem: any) => renderGroupBody(transactionItem, type))}
      </div>
    );
  };

  const renderEmptyTable = () => {
    return (
      <div className={styles.emptyTableContainer}>
        <div className={styles.emptyTableIconContainer}>
          <NoTransactionIcon />
        </div>
        <h2 className={styles.emptyTableHeader}>There are no transactions to display</h2>
        <p className={styles.emptyTableDescription}>
          Please select a property in the rental income stage to view transactions
        </p>
      </div>
    );
  };

  return (
    <div className={styles.transactionTable}>
      <div className={styles.transactionTableHeader}>
        <div />
        <div className={styles.tableHeaderItem}>Transaction details</div>
        <div className={styles.tableHeaderItem}>Date</div>
        <div className={cx(styles.tableHeaderItem, styles.tableHeaderLastItem)}>Amount</div>
        <div />
      </div>
      <div className={styles.transactionTableBody}>
        {transactions.length > 0
          ? transactions.map((transaction) => renderTransactionTableBodyItems(transaction))
          : renderEmptyTable()}
      </div>
    </div>
  );
};

export const TransactionTable = React.memo(TransactionTableComponent);
