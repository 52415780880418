import PriceIcon from 'images/priceIcon.svg';
import PetsIcon from 'images/petsIcon.svg';
import FurnishedIcon from 'images/furnishedIcon.svg';
import BedroomIcon from 'images/bedroomIcon.svg';
import BathroomIcon from 'images/bathroomIcon.svg';
import ParkingIcon from 'images/parkingIcon.svg';
import AvailableIcon from 'images/availableIcon.svg';
import ViewingBookedIcon from 'images/viewingBookedIcon.svg';
import MultipleViewingsIcon from 'images/multipleViewingsIcon.svg';
import LettingPriceIcon from 'images/lettingPriceIcon.svg';
import { IAdDetailsRadio, TAdDetailsType } from './AdDetailsItem/types';

export const marketingStatsTitleMap = {
  title: 'Marketing stats',
  tooltipText:
    'Here you can see marketing statistics for your properties, including number of clicks on your portal ads, enquiries made, viewings conducted and offers we have received.',
};

export const marketingStatsMap: { title: string; key: string }[] = [
  { title: 'Ad Views', key: 'adViewingsNumber' },
  { title: 'Enquiries', key: 'enquiriesNumber' },
  { title: 'Planned Viewings', key: 'plannedViewings' },
  { title: 'Past Viewings', key: 'pastViewings' },
  { title: 'Offers', key: 'offersNumber' },
];

export const adDetailsTitleMap = {
  title: 'Ad details',
  tooltipText:
    'Use the Edit button to make changes to the asking rental price, your furnishing preferences and pet policy.',
};

export const adDetailsMap: { title: string; key: TAdDetailsType; icon: string }[] = [
  { title: 'Rent pcm', key: 'price', icon: PriceIcon },
  { title: 'Pets', key: 'petsAllowed', icon: PetsIcon },
  { title: 'Furnished', key: 'furnished', icon: FurnishedIcon },
  { title: 'Bedrooms', key: 'bedroomsNumber', icon: BedroomIcon },
  { title: 'Bathrooms', key: 'bathroomsNumber', icon: BathroomIcon },
  { title: 'Parking', key: 'parkingAvailable', icon: ParkingIcon },
  { title: 'Available', key: 'availableDate', icon: AvailableIcon },
];

export const savingsDetailsTitleMap = {
  title: 'By choosing Home Made to market your property, you will save',
  disclaimer: '* this is compared to an alternative agency with an average fee of 10% + VAT',
};

export const chartLegendMap: { label: string; icon: string; key: string }[] = [
  { label: 'Viewing booked', icon: ViewingBookedIcon, key: 'isViewingBooked' },
  { label: 'Multiple viewings', icon: MultipleViewingsIcon, key: 'isMultipleViewings' },
  { label: 'Letting price change', icon: LettingPriceIcon, key: 'isPriceChange' },
];

export const chartConfig = {
  xField: 'viewDate',
  yField: 'totalDetailViews',
  yAxis: {
    range: [0, 0.9],
  },
  autoFit: true,
  tooltip: {
    enabled: false,
    follow: true,
    enterable: true,
    offset: 18,
    shared: true,
    customContent: (title: string, data: any) => {
      const tooltipItem = data[0];
      return `<div class="chart-tooltip"><span>${tooltipItem?.data.totalDetailViews} views</span></div>`;
    },
  },
  areaStyle: {
    fill: '#D8F1ED',
    lineOpacity: 0,
    fillOpacity: 0.6,
  },
  line: {
    color: '#D8F1ED',
  },
  point: {
    shape: 'point',
    color: '#38B7A1',
  },
  color: '#38B7A1',
  isStack: true,
  smooth: true,
};

const petsAllowed: IAdDetailsRadio[] = [
  { value: 'Yes', label: 'Yes', apiValue: true },
  { value: 'No', label: 'No', apiValue: false },
];

const furnished: IAdDetailsRadio[] = [
  { value: 'Yes', label: 'Yes', apiValue: true },
  { value: 'No', label: 'No', apiValue: false },
  { value: 'Flexible', label: 'Flexible', apiValue: null },
];

export const adDetailsItemOptionsMap = {
  petsAllowed,
  furnished,
};

export const baseOffsetY = 41;
