import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAnalyticsState } from 'types';

const initialState: IAnalyticsState = {
  auditLogGroupList: [],
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setAuditLogList(state, action: PayloadAction<any>) {
      state.auditLogGroupList = action.payload;
    },
  },
});

export default analyticsSlice.reducer;
