import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Checkbox, ContactUs, FormField, Input, NavigationLink, PasswordRules } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { cognitoAuth } from 'utils/cognito';
import { newPasswordSchema } from 'utils/schemas';
import { useAppDispatch } from 'hooks';

import styles from './NewPasswordPage.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
  terms: boolean;
};

export const NewPasswordPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(newPasswordSchema),
  });

  const newPasswordHandler = ({ password }: FormValues): void => {
    dispatch(loaderSlice.actions.increaseLoader());
    cognitoAuth
      .completeNewPasswordChallenge(password)
      .then(() => {
        cognitoAuth.mfaSetup();
        navigate('/auth/sign-in');
      })
      .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
  };

  return (
    <div className="new-password">
      <h2 className={styles.taglineHeader}>Welcome to Home Made!</h2>
      <p className={styles.taglineBody}>Create your account password to access your property dashboard.</p>

      <PasswordRules />

      <form autoComplete="off" onSubmit={handleSubmit(newPasswordHandler)}>
        <FormField labelId="password" labelText="Create password" fieldError={errors.password as TFieldError}>
          <Input placeholder="Type your password" id="password" type="password" {...register('password')} />
        </FormField>
        <FormField labelId="confirmPassword" labelText="Confirm password" fieldError={errors.password as TFieldError}>
          <Input
            placeholder="Confirm your password"
            id="confirmPassword"
            type="password"
            {...register('confirmPassword')}
          />
        </FormField>

        <FormField fieldError={errors.terms as TFieldError}>
          <div className={styles.terms}>
            <Checkbox {...register('terms')} />
            <span className={styles.termsText}>
              I agree to <NavigationLink>Terms and Conditions</NavigationLink>. See{' '}
              <NavigationLink>Privacy</NavigationLink> and <NavigationLink>Cookie Policy</NavigationLink> for
              information on how we use the information you provide to us.
            </span>
          </div>
        </FormField>

        <div className={styles.controlSection}>
          <Button type="submit" fullWidth>
            Continue
          </Button>
        </div>
      </form>
      <ContactUs promoLabel="Need help signing in?" />
    </div>
  );
};
