import React from 'react';

import styles from './NavigationLink.module.scss';

interface INavigationLinkProps {
  children: React.ReactNode;
}

const NavigationLinkComponent: React.FC<INavigationLinkProps> = ({ children }) => (
  <span className={styles.navigationLink}>{children}</span>
);

export const NavigationLink = React.memo(NavigationLinkComponent);
