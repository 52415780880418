import { DayContentProps, useDayPicker } from 'react-day-picker';
import cx from 'clsx';
import React from 'react';
import format from 'date-fns/format';
import styles from 'react-day-picker/dist/style.module.css';

import customStyles from '../Datepicker.module.scss';

const getWeekday = (date: Date) => format(date, 'EEE');

export const DayContent = ({ date, activeModifiers }: DayContentProps): JSX.Element => {
  const {
    locale,
    labels: { labelDay },
    formatters: { formatDay },
  } = useDayPicker();

  const { selected } = activeModifiers;

  return (
    <span className={customStyles.dayContentWrapper}>
      {selected ? <span className={cx({ [customStyles.dayContentWeekday]: selected })}>{getWeekday(date)}</span> : null}
      <span aria-hidden="true" className={cx({ [customStyles.dayContentDay]: selected })}>
        {formatDay(date, { locale })}
      </span>
      <span className={styles.vhidden}>{labelDay(date, activeModifiers, { locale })}</span>
    </span>
  );
};
