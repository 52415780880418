import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { login, loginMFA, logout } from 'store/actions/auth';
import { refreshToken } from 'utils/cognito/refreshToken';
import { AuthState } from 'types';

const initialState: AuthState = {
  user: JSON.parse(localStorage?.getItem('user') as string) || null,
  userInfo: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<any>) {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action: PayloadAction<AuthState['user']>) => {
        localStorage.setItem('user', JSON.stringify(action.payload));
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, rejectValue: PayloadAction<unknown>) => {
        localStorage.removeItem('user');
        state.user = null;
        state.userInfo = null;
      })
      .addCase(loginMFA.fulfilled, (state, action: PayloadAction<AuthState['user']>) => {
        localStorage.setItem('user', JSON.stringify(action.payload));
        state.user = action.payload;
      })
      .addCase(loginMFA.rejected, (state, rejectValue: PayloadAction<unknown>) => {
        localStorage.removeItem('user');
        state.user = null;
        state.userInfo = null;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem('user');
        state.user = null;
        state.userInfo = null;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        localStorage.setItem('user', JSON.stringify(action.payload));
        state.user = action.payload as AuthState['user'];
      })
      .addCase(refreshToken.rejected, (state) => {
        localStorage.removeItem('user');
        state.user = null;
        state.userInfo = null;
      });
  },
});

export default authSlice.reducer;
