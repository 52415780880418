import axios from 'axios';

import { store } from '../../store';
import { refreshToken } from '../cognito/refreshToken';

export const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
});
export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    const { user } = store.getState().auth;

    const currentDate = new Date();

    if (user?.accessToken) {
      const { exp } = user.accessToken.payload;

      if (exp * 1000 < currentDate.getTime()) {
        await store.dispatch(refreshToken());
      }

      if (config?.headers) {
        config.headers.authorization = `Bearer ${store.getState().auth?.user?.accessToken?.jwtToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const handleErrorResponse = (error: any) => {
  let errorResponse;
  if (error.response && error.response.data) {
    // Expect the API to handle error responses in valid format
    errorResponse = error.response.data;
    // JSON stringify if you need the json and use it later
  } else if (error.request) {
    // TO Handle the default error response for Network failure or 404 etc.,
    errorResponse = error.request.message || error.request.statusText;
  } else {
    errorResponse = error.message;
  }
  throw errorResponse;
};
