import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAppModalState } from 'types';

const initialState: IAppModalState = {
  type: null,
  options: null,
};

export const appModalSlice = createSlice({
  name: 'appModal',
  initialState,
  reducers: {
    setAppModalState(state, action: PayloadAction<IAppModalState>) {
      state.type = action.payload.type;
      state.options = action.payload.options;
    },
    resetAppModalState() {
      return initialState;
    },
  },
});

export default appModalSlice.reducer;
