import React from 'react';
import cx from 'clsx';

import { TextAreaWrapper } from './internal';
import { ITextAreaProps } from './types';

import styles from './TextArea.module.scss';

const TextAreaComponent = React.forwardRef<HTMLDivElement, ITextAreaProps>(function TextArea(props, ref) {
  const { disabled, invalid, inputRef, size, rows, onChange, onFocus, onBlur, wrapperClassName, ...rest } = props;

  const [focused, setFocused] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e, e.currentTarget.value);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <TextAreaWrapper
      className={wrapperClassName}
      ref={ref}
      focused={focused}
      disabled={disabled}
      invalid={invalid}
      size={size}
    >
      <textarea
        {...rest}
        rows={rows}
        className={cx(styles.textarea, { [styles.invalid]: invalid })}
        ref={inputRef}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </TextAreaWrapper>
  );
});

TextAreaComponent.defaultProps = {
  rows: 5,
};

export const TextArea = React.memo(TextAreaComponent);
