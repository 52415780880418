import React, { useState, useEffect } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

import { useAppSelector, useModal, usePrevious, useAppDispatch, useUserInfo } from 'hooks';
import { Button, Pagination, Icon } from 'components';
import { getTransactionInfoByWalletId } from 'store/actions/transactions';
import { transactionsSlice } from 'store/reducers/TransactionsSlice';
import { TransactionTable } from '../TransactionTable';
import { HandleInvoiceDetailsModal } from '../HandleInvoiceDetailsModal';
import { AdjustRentModal } from '../AdjustRentModal';
import { DateRangePickerModal } from '../DateRangePickerModal';
import { ITransactionProps } from './types';
import styles from './Transaction.module.scss';

const TransactionComponent: React.FC<ITransactionProps> = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPage, setSelectedPage] = useState<number>(Number(searchParams.get('selectedPage')) || 1);
  const { listingId, salesforceListingId, salesforcePropertyId } = useAppSelector((state) => state.selectedListing);
  const { walletId } = useAppSelector((state) => state.wallet.walletInfo);
  const { monthlyTenancy } = useAppSelector((state) => state.wallet);
  const { role } = useUserInfo();
  const { transactionGroupList, transactionPaginationConfig, transactionDateRangeConfig } = useAppSelector(
    (state) => state.transactions
  );

  const [isInvoiceDetailsModalOpen, toggleInvoiceDetailsModal] = useModal();
  const [isAdjustModalOpen, toggleAdjustModal] = useModal();
  const [isDateRangePickerModalOpen, toggleDateRangePickerModal] = useModal();
  const prevSelectedPage = usePrevious(selectedPage);
  const dispatch = useAppDispatch();

  const { totalTransactions, pageSize } = transactionPaginationConfig;
  const { dateFrom, dateTo } = transactionDateRangeConfig;

  const getTransactionByWalletIdAction = ({ page }: Record<string, number>): void => {
    if (walletId) {
      dispatch(
        getTransactionInfoByWalletId({
          walletId,
          page,
          pageSize,
          dateTo,
          dateFrom,
        })
      );
    }
  };

  useEffect(() => {
    const dateFromParams = searchParams.get('dateFrom');
    const dateToParams = searchParams.get('dateTo');

    if (dateFrom && dateTo) {
      dispatch(
        transactionsSlice.actions.setTransactionDateRangeConfig({
          dateFrom: `${dateFromParams}`,
          dateTo: `${dateToParams}`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!walletId) return;

    dispatch(transactionsSlice.actions.resetTransactionState());
    getTransactionByWalletIdAction({ page: 1 });
  }, [walletId]);

  useEffect(() => {
    if (!prevSelectedPage) {
      getTransactionByWalletIdAction({ page: 1 });
    } else if (selectedPage === prevSelectedPage && selectedPage !== 1) {
      setSelectedPage(1);
    } else {
      getTransactionByWalletIdAction({ page: selectedPage !== prevSelectedPage ? selectedPage : 1 });
    }

    if (listingId && salesforceListingId && salesforcePropertyId) {
      setSearchParams(
        createSearchParams({
          LI: listingId,
          SLI: salesforceListingId,
          SPI: salesforcePropertyId,
          selectedPage: `${selectedPage}`,
          dateFrom,
          dateTo,
        })
      );
    }
  }, [selectedPage, dateFrom, dateTo]);

  return (
    <div className={styles.transactionTable}>
      <div className={styles.transactionTableControl}>
        <div className={styles.leftSideControl}>
          {role === 'Admin' || role === 'Controller' ? (
            <Button
              className={styles.controlButton}
              beforeIcon={<Icon icon="PLUS" />}
              size="small"
              onClick={toggleInvoiceDetailsModal}
            >
              Add new invoice
            </Button>
          ) : null}
          {role !== 'Agent' ? (
            <Button
              className={styles.controlButton}
              beforeIcon={<Icon icon="PLUS_MINUS" />}
              theme="secondary"
              size="small"
              onClick={toggleAdjustModal}
            >
              Adjust next month’s rent
            </Button>
          ) : null}
        </div>
        <div className={styles.rightSideControl}>
          <Button
            className={styles.controlButton}
            beforeIcon={<Icon icon="LOUP" />}
            theme="secondary"
            size="small"
            onClick={toggleDateRangePickerModal}
          >
            Search by date range
          </Button>
          <Pagination
            totalItems={totalTransactions}
            pageSize={pageSize}
            selectedPage={selectedPage}
            onPageChanged={setSelectedPage}
          />
        </div>
      </div>
      <div className={styles.transactionTableContent}>
        <TransactionTable transactions={transactionGroupList} />
      </div>
      {isInvoiceDetailsModalOpen && (
        <HandleInvoiceDetailsModal isOpen={isInvoiceDetailsModalOpen} toggle={toggleInvoiceDetailsModal} type="add" />
      )}
      {isAdjustModalOpen && (
        <AdjustRentModal isOpen={isAdjustModalOpen} toggle={toggleAdjustModal} options={{ monthlyTenancy, walletId }} />
      )}
      <DateRangePickerModal isOpen={isDateRangePickerModalOpen} toggle={toggleDateRangePickerModal} />
    </div>
  );
};

export const Transaction = React.memo(TransactionComponent);
