// @ts-nocheck
import React from 'react';
import cx from 'clsx';

import { Modal } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { appModalSlice } from 'store/reducers/AppModalSlice';
import { ApproveChangePrice, ApproveEditTenancyTermination } from './internal';
import styles from './AppModal.module.scss';

const modalsMap = [
  {
    key: 'approveChangePrice',
    component: <ApproveChangePrice />,
  },
  {
    key: 'approveEditTenancyTermination',
    component: <ApproveEditTenancyTermination />,
  },
];

export const AppModalComponent = () => {
  const dispatch = useAppDispatch();
  const { type, options } = useAppSelector((state) => state.appModal);

  const close = () => {
    dispatch(appModalSlice.actions.resetAppModalState());
  };

  const renderModal = () => {
    return modalsMap.map(({ key, component }) => {
      if (key === type && React.isValidElement(component)) {
        return React.cloneElement(component, { key, toggle: close, ...options });
      }
      return null;
    });
  };

  return (
    <Modal isOpen={!!type} onRequestClose={close} className={cx(styles.modal, type && styles[type])}>
      {type && renderModal()}
    </Modal>
  );
};

export const AppModal = React.memo(AppModalComponent);
