import React, { useState, useEffect } from 'react';

import { getDocumentsByListingId } from 'store/api/documents';
import { IDocumentItemResponse } from 'types';
import { Card, ListingSelect } from 'components';
import { useAppSelector, useAppDispatch } from 'hooks';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { DocumentItem } from './internal/DocumentItem';

import styles from './DocumentsPage.module.scss';

const documentTypes = {
  GSC: 'Gas Safety Certificate (GSC)',
  EPC: 'Energy Performance Certificate (EPC)',
  EICR: 'Electrical Installation Condition Report (EICR)',
  'Tenancy Agreement': 'Tenancy Agreement',
};

export const DocumentsPage: React.FC = (): JSX.Element => {
  const [documentsData, setDocumentsData] = useState<IDocumentItemResponse[] | null>(null);
  const { listingId } = useAppSelector((state) => state.selectedListing);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (listingId) {
      dispatch(loaderSlice.actions.increaseLoader());
      setDocumentsData(null);

      getDocumentsByListingId({ listingId })
        .then((res) => setDocumentsData(res))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    }
  }, [listingId]);

  return (
    <div className={styles.page}>
      <ListingSelect className={styles.select} />
      <Card className={styles.card}>
        <h4 className={styles.cardTitle}>Property documentation</h4>
        {documentsData && (
          <ul className={styles.list}>
            {(Object.keys(documentTypes) as Array<keyof typeof documentTypes>).map((type, i) => (
              <DocumentItem
                key={i}
                title={documentTypes[type]}
                documentId={documentsData.find((doc) => doc.type === type)?.id}
                fileExtension={documentsData.find((doc) => doc.type === type)?.fileExtension}
              />
            ))}
          </ul>
        )}
      </Card>
    </div>
  );
};
