import React from 'react';
import cx from 'clsx';

import { InputWrapper } from './internal';
import { ReactComponent as EyeIconSvgClosed } from './internal/assets/feather-eye-closed.svg';

// TODO: replace temporary icon
import { ReactComponent as EyeIconSvgOpen } from './internal/assets/feather-eye-open.svg';
import { IInputProps } from './types';

import styles from './Input.module.scss';

const InputComponent = React.forwardRef<HTMLDivElement, IInputProps>(function Input(props, ref) {
  const {
    disabled,
    invalid,
    inputRef,
    size,
    onChange,
    onFocus,
    onBlur,
    type,
    rightEnhancer: propRightEnhancer,
    wrapperClassName,
    ...rest
  } = props;

  const [focused, setFocused] = React.useState(false);
  const [newType, setNewType] = React.useState(type);

  const toggleType = () => setNewType(newType === 'password' ? 'text' : 'password');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.currentTarget.value);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleEyeIconClick = () => {
    toggleType();
  };

  const handleEyeIconKeyPress = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      toggleType();
    }
  };

  const rightEnhancer =
    type === 'password' ? (
      <span
        className={styles.eyeIconWrapper}
        onClick={handleEyeIconClick}
        role="button"
        tabIndex={0}
        onKeyPress={handleEyeIconKeyPress}
      >
        {newType === 'password' && <EyeIconSvgClosed />}
        {newType === 'text' && <EyeIconSvgOpen />}
      </span>
    ) : (
      propRightEnhancer
    );

  return (
    <InputWrapper
      className={wrapperClassName}
      ref={ref}
      focused={focused}
      disabled={disabled}
      invalid={invalid}
      rightEnhancer={rightEnhancer}
      size={size}
    >
      <input
        {...rest}
        type={newType}
        className={cx(styles.input, { [styles.invalid]: invalid }, { [styles.password]: newType === 'password' })}
        ref={inputRef}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </InputWrapper>
  );
});

InputComponent.defaultProps = {
  type: 'text',
};

export const Input = React.memo(InputComponent);
