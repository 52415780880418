import React from 'react';

import { IListingResponse } from 'types';
import { ListingSelect, Timeline, TimelineBadge, TimelineEvent } from 'components';
import { getProgressiveListingCall } from 'store/api/progressiveListing';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { getFullDate, getTenancyCompletedDate } from 'utils/helpers';
import { useAppSelector, useAppDispatch } from 'hooks';
import phoneImg from 'images/phone.svg';
import mailImg from 'images/mail.svg';

import { data, ITenancyItem, TenancyItemDateKey } from './data';
import styles from './TenantsPage.module.scss';

const defaultExpandedKeysEntries = Object.entries<boolean>({
  poiReceivedDate: false,
  referencingDate: false,
  contractDate: false,
  invoicingTimestamp: false,
  tenancyStartDate: false,
}) as [TenancyItemDateKey, boolean][];

export const TenantsPage: React.FC = (): JSX.Element => {
  const [listingData, setListingData] = React.useState<IListingResponse | null>(null);
  const { listingId, listingInfo } = useAppSelector((state) => state.selectedListing);
  const dispatch = useAppDispatch();

  const [expandedKeys, setExpandedKeys] = React.useState(
    new Map<TenancyItemDateKey, boolean>(defaultExpandedKeysEntries)
  );

  React.useEffect(() => {
    if (listingId) {
      setListingData(null);
      dispatch(loaderSlice.actions.increaseLoader());
      getProgressiveListingCall({ listingId })
        .then((res) => {
          const expandedItemDateKey =
            (res &&
              data
                .reduce((prev: TenancyItemDateKey[], curr: ITenancyItem) => {
                  return (res[curr.dateKey] &&
                    curr.dateKey === 'tenancyStartDate' &&
                    listingInfo.status === 'Tenanted') ||
                    (res[curr.dateKey] && curr.dateKey !== 'tenancyStartDate')
                    ? [...prev, curr.dateKey]
                    : [...prev];
                }, [])
                .pop()) ||
            data[0].dateKey;

          const keysMap = new Map(defaultExpandedKeysEntries);
          keysMap.set(expandedItemDateKey, true);
          setExpandedKeys(keysMap);
          setListingData(res);
        })
        .catch((e) => e)
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    }
  }, [listingId, listingInfo.status]);

  const getEventDescription = (item: ITenancyItem) => {
    if (listingData && item.dateKey === 'tenancyStartDate' && !!listingData[item.dateKey]) {
      return (
        <p className={styles.tenantsBookedDescription}>
          Move in scheduled for {getFullDate(listingData[item.dateKey as keyof IListingResponse] as string)}
        </p>
      );
    }
    return item.description;
  };

  const handleToggle = (dateKey: TenancyItemDateKey) => {
    const keysMap = new Map(expandedKeys);
    keysMap.set(dateKey, !keysMap.get(dateKey));
    setExpandedKeys(keysMap);
  };

  return (
    <div className={styles.tenantsPage}>
      <ListingSelect className={styles.tenantsTenancySelect} />
      <div className={styles.tenantsTimelineCard}>
        <h4 className={styles.tenantsTimelineHeader}>Timeline</h4>
        {listingData && (
          <Timeline>
            {data.map((item) => (
              <TimelineEvent
                isExpanded={expandedKeys.get(item.dateKey)}
                key={item.dateKey}
                dateKey={item.dateKey}
                title={item.title}
                badge={
                  <TimelineBadge
                    completed={
                      item.dateKey !== 'tenancyStartDate' ||
                      (item.dateKey === 'tenancyStartDate' && listingInfo.status === 'Tenanted')
                        ? getTenancyCompletedDate(listingData[item.dateKey as keyof IListingResponse] as string)
                        : null
                    }
                  />
                }
                description={getEventDescription(item)}
                onToggle={handleToggle}
              />
            ))}
          </Timeline>
        )}
      </div>

      <div className={styles.tenantsContactsCard}>
        <span className={styles.tenantsContactsText}>
          For any questions regarding your tenant’s progression, contact your Home Made team
        </span>

        <a href="tel:0207 846 0122" className={styles.tenantsContactsTel}>
          <img src={phoneImg} alt="Phone" className={styles.tenantsContactsPhoneImg} />
          020 3372 5202
        </a>
        <a href="mailto:Tenancy_progression@home-made.com" className={styles.tenantsContactsMail}>
          <img src={mailImg} alt="E-mail" className={styles.tenantsContactsMailImg} />
          Tenancy_progression@home-made.com
        </a>
      </div>
    </div>
  );
};
