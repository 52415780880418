import React from 'react';

import { getShortDate } from 'utils/helpers';
import { PaymentItemControl } from '../PaymentItemControl';
import { PaymentItemLabel } from '../PaymentItemLabel';
import { IPaymentItemProps } from './types';

import styles from './PaymentItem.module.scss';

export const PaymentItemComponent: React.FC<IPaymentItemProps> = (props) => {
  const { type, isEdit, handleEdit, approvedByDate, approvedByName } = props;

  return (
    <div className={styles.financesAdditionalDescription}>
      <span className={styles.financesDescriptionTitle}>
        {type === 'landlordPaymentsTotal' ? 'Landlord payment' : 'Home Made payment'}
      </span>
      {isEdit ? (
        <PaymentItemControl {...props} type={type} handleEdit={handleEdit} />
      ) : (
        <PaymentItemLabel {...props} type={type} handleEdit={handleEdit} isEdit={isEdit} />
      )}
      {approvedByDate ? (
        <span className={styles.financesApprovedBySection}>
          Approved by {approvedByName} {getShortDate(approvedByDate)}
        </span>
      ) : (
        <span className={styles.financesApprovedBySection}>Not approved yet</span>
      )}
    </div>
  );
};

export const PaymentItem = React.memo(PaymentItemComponent);
