import * as yup from 'yup';

export const handleBankDetailsSchema = yup.object().shape({
  accountName: yup.string().required('This field is required'),
  sortCode: yup
    .string()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Sort code must contain exactly 6 digits')
    .max(6, 'Sort code must contain exactly 6 digits'),
  accountNumber: yup
    .string()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(8, 'Account number must contain exactly 8 digits')
    .max(8, 'Account number must contain exactly 8 digits')
    .required('This field is required'),
});
