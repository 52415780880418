import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TrashIcon from 'components/elements/icons/TrashIcon';
import { TableRow } from 'components/elements/Table/internal';
import { Button, FormField, Input, Select } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { useAppDispatch } from 'hooks';
import { updateStatusUser, updateItemUser, reInviteUser } from 'store/actions/userList';
import { roleMap } from 'constants/userList';
import { editUserSchema } from 'utils/schemas';

import { IEditUserCellProps } from 'pages/ManageUsersPage/types';
import lockImg from '../../../../images/lock.svg';
import styles from './EditUserCell.module.scss';
import { InviteBadge } from '../InviteBadge';

type FormValues = {
  email: string;
  role: string;
  fullName: string;
  phoneNumber: string;
  id: string;
};

const roleKeys = Object.keys(roleMap).filter((key) => key !== 'Landlord') as Array<keyof typeof roleMap>;

const options = roleKeys.map((roleKey) => ({
  value: roleKey,
  label: roleMap[roleKey],
}));

export const EditUserCell: React.FC<IEditUserCellProps> = ({ id, onClose, values, isLandlordItem }) => {
  const dispatch = useAppDispatch();

  const initialValues = values.reduce<Record<string, string>>(
    (prev, { fieldName, value }) => (fieldName === 'action' ? { ...prev } : { ...prev, [fieldName]: value }),
    {}
  );

  const statusFieldValue = values.find((el) => el.fieldName === 'status')?.value;

  const defaultValues = {
    id: initialValues.id,
    role: Object.keys(roleMap).find((roleKey) => roleMap[roleKey as keyof typeof roleMap] === initialValues.role),
    phoneNumber: initialValues.phoneNumber,
    fullName: initialValues.fullName,
    email: initialValues.email,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(editUserSchema),
    defaultValues,
  });

  const editHandler = ({ fullName, phoneNumber, email, role }: FormValues): void => {
    dispatch(
      updateItemUser({
        userId: id,
        payload: role === 'Landlord' ? { fullName, phoneNumber } : { fullName, phoneNumber, email, role },
      })
    );
  };

  const handleDeactivateClick = () => {
    dispatch(updateStatusUser({ payload: { id }, type: 'deactivate' }));
  };

  const handleActivateClick = () => {
    dispatch(updateStatusUser({ payload: { id }, type: 'activate' }));
  };

  const handleSelectChange = (_: string, e: React.ChangeEvent<HTMLSelectElement>) => {
    const { onChange } = register('role');
    onChange(e);
    trigger('role');
  };

  const handleInviteClick = () => {
    dispatch(reInviteUser({ id: initialValues.id }));
  };

  return (
    <>
      <TableRow id={`${id}-info`}>
        {values.map(
          ({ fieldName, value }) =>
            fieldName !== 'id' &&
            fieldName !== 'status' &&
            fieldName !== 'action' && <td className={styles.infoCell}>{value}</td>
        )}
        <td className={styles.infoCellUserStatus}>
          {initialValues.status !== 'Disabled' ? (
            <Button
              className={styles.toggleUserStatusBtn}
              size="small"
              theme="secondary"
              beforeIcon={<TrashIcon />}
              onClick={handleDeactivateClick}
            >
              Deactivate user
            </Button>
          ) : (
            <Button className={styles.toggleUserStatusBtn} size="small" onClick={handleActivateClick}>
              Reactivate user
            </Button>
          )}
        </td>
        <td className={styles.infoCellClose}>
          <Button className={styles.infoCellCloseBtn} theme="secondary" size="small" onClick={onClose}>
            Close
          </Button>
        </td>
      </TableRow>
      <TableRow id={`${id}-edit`}>
        <td className={styles.editCell}>
          <form autoComplete="off" onSubmit={handleSubmit(editHandler)} className={styles.grid}>
            <div className={styles.userId}>
              <FormField labelId="id" labelText="Home Made User ID" className={styles.formField}>
                <Input
                  id="id"
                  {...register('id')}
                  readOnly
                  size="small"
                  rightEnhancer={<img src={lockImg} alt="Locked" />}
                />
              </FormField>
            </div>
            <div className={styles.activation}>
              {statusFieldValue === 'Not Activated' && <InviteBadge onInviteClick={handleInviteClick} />}
            </div>
            <div className={styles.fullName}>
              <FormField
                labelId="fullName"
                labelText="Full name"
                fieldError={errors.fullName as TFieldError}
                className={styles.formField}
              >
                <Input
                  placeholder="Type full name"
                  id="fullName"
                  {...register('fullName')}
                  readOnly={isLandlordItem}
                  rightEnhancer={isLandlordItem && <img src={lockImg} alt="Locked" />}
                />
              </FormField>
            </div>
            <div className={styles.status}>
              <FormField labelId="status" labelText="Status" className={styles.formField}>
                <Input
                  id="status"
                  value={statusFieldValue}
                  readOnly
                  rightEnhancer={<img src={lockImg} alt="Locked" />}
                />
              </FormField>
            </div>
            <div className={styles.email}>
              <FormField
                labelId="email"
                labelText="Email address"
                fieldError={errors.email as TFieldError}
                className={styles.formField}
              >
                <Input
                  placeholder="Type your email address"
                  id="email"
                  {...register('email')}
                  readOnly={isLandlordItem}
                  rightEnhancer={isLandlordItem && <img src={lockImg} alt="Locked" />}
                />
              </FormField>
            </div>
            <div className={styles.phoneNumber}>
              <FormField
                labelId="phoneNumber"
                labelText="Phone number"
                fieldError={errors.phoneNumber as TFieldError}
                className={styles.formField}
              >
                <Input
                  placeholder="Type your phone number"
                  id="phoneNumber"
                  {...register('phoneNumber')}
                  readOnly={isLandlordItem}
                  rightEnhancer={isLandlordItem && <img src={lockImg} alt="Locked" />}
                />
              </FormField>
            </div>
            <div className={styles.userType}>
              <FormField
                labelId="role"
                labelText="User type"
                fieldError={errors.role as TFieldError}
                className={styles.formField}
              >
                {isLandlordItem ? (
                  <Input id="role" {...register('role')} readOnly rightEnhancer={<img src={lockImg} alt="Locked" />} />
                ) : (
                  <Select
                    className={styles.select}
                    placeholder="Select user type"
                    id="role"
                    options={options}
                    value={getValues().role}
                    {...register('role')}
                    onChange={handleSelectChange}
                  />
                )}
              </FormField>
            </div>
            <div className={styles.saveButton}>
              <Button fullWidth size="medium40" disabled={!isValid || initialValues.role === 'Landlord'} type="submit">
                Save all changes
              </Button>
            </div>
          </form>
        </td>
      </TableRow>
    </>
  );
};
