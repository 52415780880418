import React from 'react';

import { SearchFieldWrapper } from './internal/SearchFieldWrapper';

import styles from './SearchField.module.scss';

import { ISearchFieldProps } from './types';

const SearchFieldComponent = React.forwardRef<HTMLDivElement, ISearchFieldProps>(function SearchField(props, ref) {
  const { value, disabled, inputRef, onChange, onFocus, onBlur, onSubmit, ...rest } = props;

  const [focused, setFocused] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.currentTarget.value);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onSubmit && e.key === 'Enter') {
      onSubmit(e.currentTarget.value);
    }
  };

  return (
    <SearchFieldWrapper focused={focused} onClick={onSubmit} value={value}>
      <input
        {...rest}
        value={value}
        type="text"
        className={styles.searchField}
        ref={inputRef}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
    </SearchFieldWrapper>
  );
});

export const SearchField = React.memo(SearchFieldComponent);
