import { UserRole, UserLabels } from 'types';

export const roleMap: Record<UserRole, UserLabels> = {
  Landlord: 'Landlord',
  Agent: 'Account Manager',
  Controller: 'Controller',
  Admin: 'Administrator',
};

export const userStatusMap = {
  Active: 'Active',
  Disabled: 'Disabled',
  'Not Activated': 'Not Activated',
};

export const userTypeFilterOptions = [
  {
    value: 'all',
    label: 'All user types',
  },
  {
    value: 'Landlord',
    label: roleMap.Landlord,
  },
  {
    value: 'Agent',
    label: roleMap.Agent,
  },
  {
    value: 'Controller',
    label: roleMap.Controller,
  },
  {
    value: 'Admin',
    label: roleMap.Admin,
  },
];

export const defaultUserTypeFilterValues = ['all', 'Landlord', 'Agent', 'Controller', 'Admin'];

export const statusFilterOptions = [
  {
    value: 'all',
    label: 'All statuses',
  },
  {
    value: 'Active',
    label: userStatusMap.Active,
  },
  {
    value: 'Not Activated',
    label: userStatusMap['Not Activated'],
  },
  {
    value: 'Disabled',
    label: userStatusMap.Disabled,
  },
];

export const defaultStatusFilterValues = ['all', 'Active', 'Not Activated', 'Disabled'];

export const usersSortingOptions = [
  { value: 'orderByNameAsc', label: 'Order by name (from A to Z)' },
  { value: 'orderByNameDesc', label: 'Order by name (from Z to A)' },
];

export const usersSortingOptionsMap = {
  orderByNameAsc: {
    orderByName: 'asc',
  },
  orderByNameDesc: {
    orderByName: 'desc',
  },
};

export const defaultSortOption = { value: 'orderByNameAsc', label: 'Order by name (from A to Z)' };

// Table
export const usersColumnsTitle = [
  {
    value: 'Name',
    fieldName: 'fullName',
  },
  {
    value: 'Email',
    fieldName: 'email',
  },
  {
    value: 'Phone number',
    fieldName: 'phoneNumber',
  },
  {
    value: 'User type',
    fieldName: 'role',
  },
  {
    value: 'Status',
    fieldName: 'status',
  },
  {
    value: 'Action',
    fieldName: 'action',
  },
];
