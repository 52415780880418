import { CognitoRefreshToken, CognitoUser } from 'amazon-cognito-identity-js';
import { createAsyncThunk } from '@reduxjs/toolkit';

import UserPool from 'config/UserPool';
import { RootState } from 'store';
import { AuthState } from 'types';

export const refreshToken = createAsyncThunk(`user/refreshToken`, async (_, { getState }) => {
  const state = getState() as RootState;

  return new Promise<AuthState['user']>((resolve, reject) => {
    const user = new CognitoUser({ Username: state.auth.user?.idToken?.payload?.email, Pool: UserPool });
    const userData = {} as AuthState['user'];

    user.refreshSession(
      new CognitoRefreshToken({ RefreshToken: state.auth?.user?.refreshToken?.jwtToken as string }),
      (err, session) => {
        if (!err) {
          userData!.idToken = {
            jwtToken: session.getIdToken().getJwtToken(),
            payload: session.getIdToken().decodePayload(),
          };
          userData!.accessToken = {
            jwtToken: session.getAccessToken().getJwtToken(),
            payload: session.getAccessToken().decodePayload(),
          };
          userData!.refreshToken = {
            jwtToken: session.getRefreshToken().getToken(),
          };
          userData!.isValid = session.isValid();

          resolve(userData);
        } else {
          console.log(err);
          reject(new Error('RefreshTokenError: ', err));
        }
      }
    );
  });
});
