import React from 'react';

const tenancyStartUpFormSrc =
  'http://url7536.home-made.com/ls/click?upn=eE2fR6GG-2BXUjA1Sw6VIsZreUdhFEN-2B122S7J3HWAXPeUDfTYYDNR4LNm2HvdhU-2BJIBsE4nAD5CWasWp99KgW-2Bw-3D-3DCIqq_yT5js-2FMnW-2Bhw5qbUV67oZegPZtygm5hrWIpGeq5TLRoOOnxmE7O40XDwDbdVWSCGaeqxXOE-2F4ucgAIaaFvhumL1D8kJ-2FYNLhZILN4UnMhZnr50vzhsQLatShQy0IbeYEdB3ofJFD5Lfp4iyaOTuN2t8Wbp1xX-2FdN6o6gbAh5hIT9vBbqtqKBzL7egjzfNgEqqxfn1-2B3TTGslo71zDmxVALLTCdxiBGyC2VGedBeYPZOfFZM9dL774NLEtXHDEOGkakyEspkGwM0emlmZPQZkGDBD-2BoXzmc2RwGxJjqloxgkBmEozCcVZ0-2B58J4TvqmpEwriNhrAY7TEFX3lDKbAorbRil4BOoxNlk-2BoI-2FRlHoOwzWko3howai1wTAwJO1MRu3wdFEinNNvk6wRO3tqWLSw-3D-3D';

export type TenancyItemDateKey =
  | 'poiReceivedDate'
  | 'referencingDate'
  | 'contractDate'
  | 'invoicingTimestamp'
  | 'tenancyStartDate';

export interface ITenancyItem {
  dateKey: TenancyItemDateKey;
  title: string;
  description?: React.ReactNode;
}

export const data: ITenancyItem[] = [
  {
    dateKey: 'poiReceivedDate',
    title: 'Tenant has paid the holding deposit',
    description: (
      <>
        <p>
          Congratulations - we’re delighted that you have found fantastic tenants for your property. We have received
          their holding payment and have begun the process of confirming their Right to Rent status and completing
          referencing checks. We are also drafting the contract and will provide this to you for review shortly.
        </p>
        <p>
          To take care of the tenancy administration and keep things running smoothly, we need to confirm a few details
          regarding your property and service preferences. Please fill in the{' '}
          <a href={tenancyStartUpFormSrc}>tenancy startup form</a> as soon as possible so that we can confirm your
          property’s compliance status and organise any requested services.
        </p>
      </>
    ),
  },
  {
    dateKey: 'referencingDate',
    title: 'Right to rent and referencing checks started',
    description: (
      <>
        <p>
          We have started the process of referencing the prospective residents. Our third-party referencing partner will
          complete background checks confirming tenant income and employment status, previous address history, and
          credit history.
        </p>
        <p>Full reports will be sent to you for review and approval once complete.</p>
      </>
    ),
  },
  {
    dateKey: 'contractDate',
    title: 'Tenancy contract has been issued',
    description: (
      <>
        <p>
          The tenancy agreement has been issued to all parties for signature. Please review and sign the digital
          document.
        </p>
        <p>Please feel free to reach out to your Operations Associate if you have any queries about the contract.</p>
      </>
    ),
  },
  {
    dateKey: 'invoicingTimestamp',
    title: "Deposit and first month's rent has been paid",
    description: (
      <>
        <p>We’re good to go!</p>
        <p>
          The tenants have paid the outstanding balance of rent and deposit in full and the tenancy can commence as
          scheduled. We have provided them with copies of the following documents (as per the relevant tenancy
          regulations:
        </p>
        <ul>
          <li>EICR</li>
          <li>GSC</li>
          <li>EPC</li>
          <li>How to Rent Guide</li>
          <li>The prescribed information and TDS scheme leaflet.</li>
        </ul>
        <p>Move-in has been organised and contact details have been exchanged.</p>
        <p>Thanks once again for trusting Home Made with your property!</p>
      </>
    ),
  },
  {
    dateKey: 'tenancyStartDate',
    title: 'Tenant has moved into the premises',
  },
];
