import React, { FC, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { components, ControlProps, NoticeProps, OptionProps, SingleValueProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import { useAppDispatch, useAppSelector } from 'hooks';
import { IPropertySuggestionItem } from 'types';
import { getPropertySuggestionsCall } from 'store/api/listing';
import { setSelectedProperty } from 'store/actions/selectedListing';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { handlePropertySuggestionsItem } from 'store/models/selectedListing';
import { ReactComponent as SearchFieldIcon } from 'images/search-field-icon.svg';

import { OptionLabel, ISuggestionSelectProps } from '../types';
import { customStyles } from '../customStyles';
import styles from '../SuggestionSelect.module.scss';

const SingleValue = ({ children, ...props }: SingleValueProps<IPropertySuggestionItem, false>) => (
  <components.SingleValue {...props}>
    <div className={styles.singleValueWrapper}>{children}</div>
  </components.SingleValue>
);

const Control: FC<ControlProps<IPropertySuggestionItem, false>> = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className={styles.searchIconWrapper}>
      <SearchFieldIcon />
    </div>
    {children}
  </components.Control>
);

const Option: FC<OptionProps<IPropertySuggestionItem, false>> = ({ children, ...props }) => (
  <components.Option {...props}>
    <div className={styles.optionWrapper}>{children}</div>
  </components.Option>
);

const NoOptionsMessage: FC<NoticeProps<IPropertySuggestionItem, false>> = ({ children, ...props }) => (
  <components.NoOptionsMessage {...props}>No properties found</components.NoOptionsMessage>
);

export const PropertySelect: FC<ISuggestionSelectProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { propertySuggestions } = useAppSelector((state) => state.selectedListing);

  const promiseOptions = (search: string) =>
    new Promise<IPropertySuggestionItem[]>((resolve) => {
      dispatch(loaderSlice.actions.increaseLoader());
      getPropertySuggestionsCall({ search })
        .then((data) => resolve(data.map(handlePropertySuggestionsItem)))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    });

  const handleInputChange = useCallback((newValue: string) => {
    setInputValue(newValue);
  }, []);

  const handleChange = (option: IPropertySuggestionItem | null) => {
    if (option?.salesforcePropertyId) {
      setSearchParams({ LI: option?.listingId, SLI: option?.salesforceListingId, SPI: option?.salesforcePropertyId });
      dispatch(
        setSelectedProperty({
          listingId: option?.listingId,
          salesforceListingId: option?.salesforceListingId,
          salesforcePropertyId: option?.salesforcePropertyId,
          skipDocumentSync: true,
        })
      );
    }
  };

  return (
    <AsyncSelect
      value={propertySuggestions.defaultOption}
      className={className}
      styles={customStyles}
      cacheOptions
      components={{ Control, NoOptionsMessage, Option, SingleValue }}
      defaultOptions={propertySuggestions.suggestionsList}
      loadOptions={promiseOptions}
      onInputChange={handleInputChange}
      placeholder="Search for a property"
      onChange={handleChange}
      getOptionValue={(option) => option.salesforcePropertyId}
      getOptionLabel={(option) =>
        (
          <Highlighter
            highlightClassName={styles.textHighlight}
            searchWords={[inputValue]}
            autoEscape
            textToHighlight={option.address}
          />
        ) as OptionLabel
      }
    />
  );
};

PropertySelect.defaultProps = {
  className: undefined,
};
