export const statusToPathMap = {
  'In advertising': 'dashboard',
  'Progressing tenants': 'tenants',
  Tenanted: 'income',
};

export const propertiesColumnsTitle = [
  {
    value: 'Property address',
    fieldName: 'address',
  },
  {
    value: 'Rent/pcm',
    fieldName: 'propertyPrice',
  },
  {
    value: 'Tenancy started',
    fieldName: 'startDate',
  },
  {
    value: 'Tenancy ends',
    fieldName: 'endDate',
  },
  {
    value: 'Property status',
    fieldName: 'status',
  },
];

export const propertiesFilterOptions = [
  {
    value: 'all',
    label: 'Show all',
  },
  {
    value: 'advertising',
    label: 'In advertising',
  },
  {
    value: 'progressing',
    label: 'Progressing tenants',
  },
  {
    value: 'tenanted',
    label: 'Tenanted',
  },
];

export const defaultFilterValues = ['all', 'advertising', 'progressing', 'tenanted'];

export const statusMap = {
  Let: 'Tenanted',
  Contract: 'Progressing tenants',
  'Under Offer': 'Progressing tenants',
  RentDeposit: 'Progressing tenants',
  Referencing: 'Progressing tenants',
  Marketing: 'In advertising',
};

export const statusToMap = {
  tenanted: ['let'],
  progressing: ['contract', 'underOffer', 'rentDeposit', 'referencing'],
  advertising: ['marketing'],
};

export const propertiesSortingOptions = [
  { value: 'rent_price_desc', label: 'Highest price' },
  { value: 'rent_price_asc', label: 'Lowest price' },
  { value: 'tenancy_started_desc', label: 'Recently started' },
  { value: 'tenancy_started_asc', label: 'Older properties' },
  { value: 'tenancy_ends_asc', label: 'Ending soon' },
];

export const propertiesSortingOptionsMap = {
  rent_price_desc: {
    orderByPrice: 'desc',
  },
  rent_price_asc: {
    orderByPrice: 'asc',
  },
  tenancy_started_desc: {
    orderByTenancyStartDate: 'desc',
  },
  tenancy_started_asc: {
    orderByTenancyStartDate: 'asc',
  },
  tenancy_ends_asc: {
    orderByTenancyEndDate: 'asc',
  },
};

export const defaultSortOption = { value: 'tenancy_started_desc', label: 'Recently started' };

export const defaultPageSize = 20;

export const staticCurrentDayDiff = 1;
