/* eslint-disable import/no-duplicates */
import format from 'date-fns/format';
import cx from 'clsx';
import React from 'react';
import { ClassNames, DayPicker, DayPickerProps } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';

import { Caption } from './internal/Caption';
import { Day } from './internal/Day';
import { DayContent } from './internal/DayContent';
import customStyles from './Datepicker.module.scss';

// Converts weekday to a single letter format (e.g. Monday => M, Sunday => S)
const formatWeekdayName = (date: Date) => format(date, 'EEEEE');

export const Datepicker: React.FC<DayPickerProps> = (props) => {
  const { classNames: propClassNames, formatters: propFormatters, className, ...restProps } = props;

  const customClassNames: ClassNames = {
    ...styles,
    root: cx(customStyles.rdp, className),
    head: customStyles.head,
    caption_label: cx(styles.caption_label, customStyles.monthName),
    button: cx(styles.button, customStyles.arrowButton),
    day_range_start: customStyles.dayRangeStart,
  };

  const classNames = { ...customClassNames, ...propClassNames };
  const formatters = { formatWeekdayName, ...propFormatters };

  return (
    <DayPicker
      {...restProps}
      classNames={classNames}
      formatters={formatters}
      components={{ Caption, DayContent, Day }}
    />
  );
};
