import { AppDispatch } from 'store';
import { getUserInfo, sendVerificationCodeCall, updateUserAccountCall } from 'store/api/user';
import { IUpdateUserAccountPayload } from 'types';
import { addNotificationItem } from 'store/actions/notifications';
import { authSlice } from 'store/reducers/AuthSlice';
import { loaderSlice } from 'store/reducers/LoaderSlice';

export const getUserData = (accessTokenJwt: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    const userInfo = await getUserInfo(accessTokenJwt);
    dispatch(authSlice.actions.setUserInfo(userInfo));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const sendVerificationCode = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    await sendVerificationCodeCall();
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateUserAccount = (params: IUpdateUserAccountPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const userInfo = await updateUserAccountCall(params);
    dispatch(authSlice.actions.setUserInfo(userInfo));
  } catch (error) {
    // @ts-ignore: Unreachable code error
    const { details, message } = error;
    dispatch(addNotificationItem({ type: 'error', label: message }));
    throw error;
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};
