import React from 'react';
import cx from 'clsx';

import { TBaseComponentProps } from '../../types';
import { TInputSize } from '../types';
import styles from './TextAreaWrapper.module.scss';

type TCursor = 'pointer';

interface ITextAreaWrapperProps extends TBaseComponentProps<React.ComponentPropsWithoutRef<'div'>> {
  className?: string;
  disabled?: boolean;
  focused?: boolean;
  invalid?: boolean;
  children: React.ReactNode;
  cursor?: TCursor;
  size?: TInputSize;
}

export const TextAreaWrapper = React.forwardRef<HTMLDivElement, ITextAreaWrapperProps>(function TextAreaWrapper(
  props,
  ref
) {
  const { focused, disabled, invalid, children, className, cursor, size, ...rest } = props;

  return (
    <div
      {...rest}
      ref={ref}
      className={cx(
        styles.textareaWrapper,
        styles[size!],
        focused && styles.focused,
        invalid && styles.invalid,
        disabled && styles.disabled,
        className
      )}
      style={{ cursor }}
      tabIndex={disabled ? -1 : props.tabIndex}
    >
      <div className={styles.childrenWrapper}>{children}</div>
    </div>
  );
});

TextAreaWrapper.defaultProps = {
  disabled: false,
  focused: false,
  invalid: false,
  cursor: undefined,
  size: 'medium',
  className: undefined,
};
