import { ITransactionGroup, ITransactionResponseItem } from 'types';
import { getPPDate, getTransactionPrice } from 'utils/helpers';

const converTransactionModel = ({
  date,
  amount,
  serviceDescription,
  invoiceNumber,
  authorName,
  entity,
  id,
  reference,
  type,
}: any) => {
  return {
    date: getPPDate(date),
    amount,
    amountLabel: getTransactionPrice(amount),
    serviceDescription,
    invoiceNumber,
    authorName,
    entity,
    id,
    reference,
    amountType: type,
  };
};

const groupCompletedTransactionsByDate = (transactions: ITransactionResponseItem[]): ITransactionGroup[] => {
  const transactionsConvertedDate = transactions.map((transaction: any) =>
    Object.assign(transaction, converTransactionModel(transaction))
  );

  const transactionDates = transactionsConvertedDate.map((transaction: any) => transaction.date);
  const transactionUniqueDates = [...new Set(transactionDates)];

  return transactionUniqueDates.map((date) => {
    return {
      id: date,
      type: 'e2Transaction',
      label: date,
      transactionItemList: transactionsConvertedDate.filter((transaction: any) => transaction.date === date),
    };
  });
};

const groupUpcomingPayments = (transactions: any) => {
  return [
    {
      id: 'upcoming',
      type: 'e1UpcomingPayment',
      label: 'Upcoming',
      transactionItemList: transactions
        .filter((transaction: any) => transaction.entity === 'e1UpcomingPayment')
        .map((transaction: any) => Object.assign(transaction, converTransactionModel(transaction))),
    },
  ];
};

export const handleTransactionGroupList = (transactions: ITransactionResponseItem[]): ITransactionGroup[] | [] => {
  const upcomingPayments = transactions.filter((item) => item.entity === 'e1UpcomingPayment');
  const completedTransaction = transactions.filter((item) => item.entity === 'e2Transaction');

  const groupedByDateUpcomingPayments = upcomingPayments.length > 0 ? groupUpcomingPayments(upcomingPayments) : [];
  const groupedByDateCompletedTransaction =
    completedTransaction.length > 0 ? groupCompletedTransactionsByDate(completedTransaction) : [];

  return [...groupedByDateUpcomingPayments, ...groupedByDateCompletedTransaction];
};
