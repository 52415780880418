import React from 'react';
import cx from 'clsx';

import styles from './StatusTag.module.scss';

interface IStatusTag {
  label: string;
  isOnHover?: boolean;
}

const StatusTagComponent: React.FC<IStatusTag> = ({ label, isOnHover }) => {
  return (
    <div className={cx(styles.statusTag, { [styles.isOnHover]: isOnHover })}>
      <div className={styles.statusTagContent}>
        <span className={styles.statusTagLabel}>{label}</span>
      </div>
    </div>
  );
};

StatusTagComponent.defaultProps = {
  isOnHover: false,
};

export const StatusTag = React.memo(StatusTagComponent);
