import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import cx from 'clsx';
import { useLayer } from 'react-laag';

import { ITooltipProps } from './types';
import styles from './Tooltip.module.scss';

const triggerTypeMap = {
  info: {
    content: '?',
    triggerClassName: styles.infoTrigger,
    containerClassName: styles.infoContainer,
  },
  error: {
    content: '!',
    triggerClassName: styles.errorTrigger,
    containerClassName: styles.errorContainer,
  },
};

export const TooltipComponent: React.FC<ITooltipProps> = ({ text, type, children, placement, layerOptions }) => {
  const [isClicked, setIsClicked] = useState(false);
  const clickTimerId: MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  const open = () => setIsClicked(true);
  const close = () => setIsClicked(false);

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isClicked,
    placement,
    onOutsideClick: close,
    onDisappear: close,
    triggerOffset: 10,
    ...layerOptions,
  });

  useEffect(() => {
    if (isClicked) {
      clickTimerId.current = setTimeout(close, 3000);
    } else if (clickTimerId.current) {
      clearTimeout(clickTimerId.current);
    }
  }, [isClicked]);

  const renderTooltipTrigger = () => {
    if (type && !children) {
      return (
        <div className={cx(styles.tooltipTrigger, triggerTypeMap[type].triggerClassName)}>
          <span className={styles.tooltipTriggerLabel}>{triggerTypeMap[type].content}</span>
        </div>
      );
    }
    return children;
  };

  return (
    <>
      <div onClick={open} onKeyPress={open} role="button" tabIndex={0} {...triggerProps}>
        {renderTooltipTrigger()}
      </div>
      {renderLayer(
        isClicked && (
          <div className={cx(styles.tooltipContainer, type && triggerTypeMap[type].containerClassName)} {...layerProps}>
            {text}
          </div>
        )
      )}
    </>
  );
};

TooltipComponent.defaultProps = {
  type: 'info',
};

export const Tooltip = React.memo(TooltipComponent);
