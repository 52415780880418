import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { INotifications, INotificationItem } from 'types';

const initialState: INotifications = {
  notificationList: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationList(state, action: PayloadAction<INotificationItem[]>) {
      state.notificationList = action.payload;
    },
  },
});

export default notificationsSlice.reducer;
