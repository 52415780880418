import React, { useState } from 'react';
import cx from 'clsx';

import styles from './index.module.scss';

interface ITableRowProps {
  children: React.ReactNode;
  id?: string;
  onClick?(id: string): void;
  className?: string;
  isHoverable?: boolean;
}

const TableRowComponent: React.FC<ITableRowProps> = ({
  children,
  id,
  isHoverable,
  className,
  onClick,
}): JSX.Element => {
  const [isOnHover, setIsOnHover] = useState(false);

  const handleClick = () => {
    if (onClick && id) {
      onClick(id);
    }
  };

  const childrenWithNewProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isOnHover });
    }
    return child;
  });

  return (
    <tr
      key={id}
      onClick={handleClick}
      className={cx({ [styles.tr]: true, className, [styles.trHover]: isHoverable })}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
    >
      {childrenWithNewProps}
    </tr>
  );
};

TableRowComponent.defaultProps = {
  id: '',
  className: '',
  onClick: undefined,
  isHoverable: false,
};

export const TableRow = React.memo(TableRowComponent);
