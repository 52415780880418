import React from 'react';
import cx from 'clsx';
import { NavLink } from 'react-router-dom';

import { Tooltip } from 'components';

import styles from './NavigationPaneItem.module.scss';

interface INavigationPaneItemProps {
  to: string;
  icon?: string;
  label: string;
  disabled: boolean;
}

const layerOptions = {
  triggerOffset: -10,
  containerOffset: 16,
  arrowOffset: 0,
};

const NavigationPaneItemComponent: React.FC<INavigationPaneItemProps> = (props): JSX.Element => {
  const { to, icon, label, disabled } = props;

  const renderPaneItem = (): JSX.Element => (
    <>
      <span className={styles.navigationPaneItemIcon}>
        <img className={styles.navigationPaneItemIconImg} src={icon} alt={label} />
      </span>
      <span className={cx(styles.navigationPaneItemLabel, disabled && styles.navigationPaneDisabledItemLabel)}>
        {label}
      </span>
    </>
  );

  const renderNavigationPaneItem = (): JSX.Element => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        [styles.navigationPaneItem, isActive ? styles.navigationPaneItemActive : null].filter(Boolean).join(' ')
      }
    >
      {renderPaneItem()}
    </NavLink>
  );

  const renderDisabledNavigationPaneItem = (): JSX.Element => (
    <Tooltip
      text="Please select a property to continue"
      type="error"
      placement="bottom-center"
      layerOptions={layerOptions}
    >
      <span className={styles.navigationPaneItem}>{renderPaneItem()}</span>
    </Tooltip>
  );

  return disabled ? renderDisabledNavigationPaneItem() : renderNavigationPaneItem();
};

export const NavigationPaneItem = React.memo(NavigationPaneItemComponent);
