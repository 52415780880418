import React from 'react';
import { Outlet } from 'react-router-dom';

import hmMainLogo from '../../images/hm-main-logo.svg';
import styles from './AuthPage.module.scss';

export const AuthPage: React.FC = (): JSX.Element => (
  <div className={styles.authPage}>
    <div className={styles.authPagePromo} />
    <div className={styles.authPageFormWrapper}>
      <div className={styles.authPageLogo}>
        <img src={hmMainLogo} alt="Home Made Logo" />
      </div>
      <div className={styles.authPageForm}>
        <Outlet />
      </div>
    </div>
  </div>
);
