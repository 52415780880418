import React from 'react';
import format from 'date-fns/format';

import { useModal, useAppSelector } from 'hooks';
import { TenancyDatesModal } from '../TenancyDatesModal';

import styles from './TenancyTerminationBar.module.scss';

const DATE_FORMAT = 'dd/MM/yyyy';

interface ITenancyTerminationBar {
  startDate: string;
  endDate: string;
}

const TenancyTerminationBarComponent: React.FC<ITenancyTerminationBar> = ({ startDate, endDate }): JSX.Element => {
  const [isTenancyDatesModalOpen, toggleTenancyDatesModal] = useModal();

  return (
    <div className={styles.tenancyTerminationBar}>
      <div className={styles.tenancyTerminationBarContainer}>
        <span className={styles.tenancyTerminationTitle}>This tenancy will terminate on</span>
        <span className={styles.tenancyTerminationEndDate}>{format(new Date(endDate), DATE_FORMAT)}</span>
        <button onClick={toggleTenancyDatesModal} className={styles.tenancyTerminationModalButton}>
          Request an update to this termination date
        </button>
      </div>
      {isTenancyDatesModalOpen && (
        <TenancyDatesModal
          isOpen={isTenancyDatesModalOpen}
          toggle={toggleTenancyDatesModal}
          options={{ startDate, endDate }}
        />
      )}
    </div>
  );
};

export const TenancyTerminationBar = React.memo(TenancyTerminationBarComponent);
