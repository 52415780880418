import React, { FC, useEffect, useRef } from 'react';

import { useAppDispatch } from 'hooks';
import closeIcon from 'images/closeNotificationIcon.svg';
import successIcon from 'images/successNotificationIcon.svg';
import errorIcon from 'images/errorNotificationIcon.svg';
import warningIcon from 'images/warningNotificationIcon.svg';
import { INotificationItem } from 'types';
import { removeNotificationItem } from 'store/actions/notifications';

import styles from './Notification.module.scss';

const notificationTypeClassNames = {
  error: styles.notificationBodyError,
  warning: styles.notificationBodyWarning,
  success: styles.notificationBodySuccess,
  closed: styles.notificationBodyClosed,
};

const actions = {
  close: removeNotificationItem,
};

const icons = {
  warning: warningIcon,
  success: successIcon,
  error: errorIcon,
  close: closeIcon,
};

const notificationPrefixMap = {
  error: 'Error',
  warning: 'Warning',
  success: 'Success',
};

const closeTimeout = process.env.REACT_APP_BUBBLE_NOTIFICATIONS_TTL
  ? +process.env.REACT_APP_BUBBLE_NOTIFICATIONS_TTL
  : 5000;

interface NotificationProps extends INotificationItem {}

export const Notification: FC<NotificationProps> = ({ type, label, notificationId }): JSX.Element => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const handleCloseNotification = () => {
    ref.current?.classList.add(notificationTypeClassNames.closed);
    setTimeout(() => dispatch(actions.close({ notificationId })), 500);
  };

  useEffect(() => {
    setTimeout(handleCloseNotification, closeTimeout);
  }, []);

  useEffect(() => {
    if (ref?.current) {
      ref.current.style.height = `${ref.current.offsetHeight}px`;
    }
  }, [ref]);

  return (
    <div ref={ref} className={`${styles.notificationBody} ${notificationTypeClassNames[type]}`}>
      <div className={styles.notificationIconContainer}>
        <img src={icons[type]} alt="Close notification" />
      </div>
      <div className={styles.notificationLabel}>
        {notificationPrefixMap[type]}: {label}
      </div>
      <div className={styles.notificationClose}>
        <button className={styles.button} onClick={handleCloseNotification}>
          <img src={icons.close} alt="Close notification" />
        </button>
      </div>
    </div>
  );
};
