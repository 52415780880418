import React, { FC } from 'react';

import { Notification } from 'components/elements';
import { useAppSelector } from 'hooks';
import styles from './NotificationList.module.scss';

export const NotificationList: FC = (): JSX.Element => {
  const { notificationList } = useAppSelector((state) => state.notifications);

  return (
    <section className={styles.notifications}>
      {notificationList.map((item) => (
        <Notification {...item} key={item.notificationId} />
      ))}
    </section>
  );
};
