import { store } from 'store';
import { financesSlice } from 'store/reducers/FinancesSlice';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { getFinancesListCall, approveFinancesCall, executeFinanceCall } from 'store/api/finances';
import { AppDispatch } from 'store';
import { addNotificationItem } from 'store/actions/notifications';
import { handleFinancesListItems, handleFinancesTotalPayments } from 'store/models/finances';
import { IGetFinancesListPayload, IExecuteApprovePayload, IPatchFinancesTotalsPayload } from 'types';

export const getFinancesList = (params: IGetFinancesListPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const { items, pageInfo } = await getFinancesListCall(params);
    const { page, total } = pageInfo;

    const financesList = items.map((item) => handleFinancesListItems(item));

    dispatch(financesSlice.actions.setFinancesFieldsList(financesList));
    dispatch(
      financesSlice.actions.setFinancesPaginationConfig({
        selectedPage: page,
        totalProperties: total,
        pageSize: 20,
      })
    );
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const executeApproveFinances = (params: IExecuteApprovePayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const { role, callback } = params;

    if (role === 'Controller') {
      await approveFinancesCall(params);
    }

    if (role === 'Admin') {
      await executeFinanceCall(params);
    }

    callback();
  } catch (e) {
    dispatch(addNotificationItem({ key: 'mfaCodeValidationFailed' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateFinancesTotalPayments = (params: IPatchFinancesTotalsPayload) => async (dispatch: AppDispatch) => {
  try {
    const { financesListItems } = store.getState().finances;

    const updatedFinancesList = financesListItems.map((item) =>
      item.id === params.id ? handleFinancesTotalPayments({ item, payload: params }) : item
    );

    dispatch(financesSlice.actions.setFinancesFieldsList(updatedFinancesList));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  }
};
