import * as React from 'react';
import cx from 'clsx';

import { Checkbox } from '../Checkbox';
import { ICheckboxGroupProps as ICheckboxGroupBaseProps } from '../Checkbox/types';
import { useCheckboxGroupOptions } from './internal/hooks';
import styles from './CheckboxGroup.module.scss';

interface ICheckboxGroupProps extends ICheckboxGroupBaseProps {
  // eslint-disable-next-line react/require-default-props
  horizontal?: boolean;
}

function CheckboxGroupComponent(props: ICheckboxGroupProps) {
  const { value, name, defaultValue, disabled, invalid, horizontal, role } = props;
  const options = useCheckboxGroupOptions(props);

  return (
    <div className={cx(styles.checkboxGroup, horizontal ? styles.horizontalGroup : styles.verticalGroup)} role={role}>
      {options.map((option) => {
        const optionValue = option.value;
        const key = String(optionValue);

        return (
          <Checkbox
            key={key}
            name={name}
            value={key}
            checked={value && value.includes(optionValue)}
            defaultChecked={(defaultValue || []).includes(optionValue) || undefined}
            disabled={disabled}
            invalid={invalid}
            label={option.label}
            onChange={option.onChange}
            role={role === 'listbox' ? 'option' : undefined}
          />
        );
      })}
    </div>
  );
}

export const CheckboxGroup = React.memo(CheckboxGroupComponent);
