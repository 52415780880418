import { useAppSelector } from './useAppSelector';

export const useAccountManagerInfo = () => {
  const { userInfo } = useAppSelector((state) => state.auth);

  return {
    accountManagerName: userInfo?.accountManagerName as string,
    accountManagerEmail: userInfo?.accountManagerEmail as string,
    accountManagerPhoneNumber: userInfo?.accountManagerPhoneNumber as string,
  };
};
