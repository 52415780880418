import React, { useState, useEffect } from 'react';
import cx from 'clsx';

import { IPaginationProps } from './types';

import styles from './Pagination.module.scss';

const PaginationComponent: React.FC<IPaginationProps> = ({
  totalItems = 0,
  pageSize = 10,
  selectedPage = 1,
  onPageChanged,
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const isPrevBtnDisabled = selectedPage === 1;
  const isNextBtnDisabled = selectedPage === totalPages || totalItems === 0;

  useEffect(() => {
    if (totalItems > 0 && pageSize > 0) {
      setTotalPages(Math.ceil(totalItems / pageSize));
    }
  }, [totalItems, pageSize]);

  return (
    <div className={styles.paginationWrapper}>
      <span className={styles.paginationCaption}>
        {totalItems > 0
          ? `${1 + pageSize * (selectedPage - 1)} - ${
              selectedPage * pageSize > totalItems ? totalItems : selectedPage * pageSize
            }`
          : '0 - 0'}{' '}
        of {totalItems}
      </span>
      <span className={styles.paginationBtnWrapper}>
        <button
          className={cx(
            styles.paginationBtn,
            styles.paginationBtnLeft,
            isPrevBtnDisabled ? styles.paginationBtnDisabled : ''
          )}
          aria-label="Previouse Page"
          onClick={() => onPageChanged(selectedPage - 1)}
          disabled={isPrevBtnDisabled}
        />
      </span>
      <span className={styles.paginationBtnWrapper}>
        <button
          className={cx(
            styles.paginationBtn,
            styles.paginationBtnRight,
            isNextBtnDisabled ? styles.paginationBtnDisabled : ''
          )}
          aria-label="Next Page"
          onClick={() => onPageChanged(selectedPage + 1)}
          disabled={isNextBtnDisabled}
        />
      </span>
    </div>
  );
};

export const Pagination = React.memo(PaginationComponent);
