import { createSlice } from '@reduxjs/toolkit';

interface ILoader {
  loaderState: number;
}

const initialState: ILoader = {
  loaderState: 0,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    increaseLoader(state) {
      state.loaderState++;
    },
    decreaseLoader(state) {
      state.loaderState--;
    },
  },
});

export default loaderSlice.reducer;
