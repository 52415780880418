import { IUser } from 'types';
import { ITableRowItemFields } from 'components/elements/Table/types';
import { roleMap, userStatusMap } from '../../constants/userList';

export const handleUserListItems = ({
  id,
  fullName,
  email,
  role,
  status,
  phoneNumber,
}: IUser): ITableRowItemFields => ({
  id: `${id}`,
  values: [
    {
      fieldName: 'id',
      value: `${id}`,
    },
    {
      fieldName: 'fullName',
      value: fullName,
    },

    {
      fieldName: 'email',
      value: email,
    },
    {
      fieldName: 'phoneNumber',
      value: phoneNumber,
    },
    {
      fieldName: 'role',
      value: roleMap[role],
    },
    {
      fieldName: 'status',
      value: userStatusMap[status],
    },
    {
      fieldName: 'action',
      fieldType: 'action',
      label: 'Edit',
      value: `${id}`,
    },
  ],
});
