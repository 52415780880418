import React from 'react';

import { IFinancesListProps } from './types';
import styles from './FinancesList.module.scss';

export const FinancesListComponent: React.FC<IFinancesListProps> = ({ children }) => {
  return (
    <div className={styles.financesList}>
      <div className={styles.financesListHeader}>
        <h2 className={styles.listHeaderItem}>Property address</h2>
      </div>
      <div className={styles.financesListBody}>{children}</div>
    </div>
  );
};

export const FinancesList = React.memo(FinancesListComponent);
