// @ts-nocheck
import { v4 as uuidv4 } from 'uuid';

import { INotificationItem, IAddNotificationItemPayload } from 'types';
import { notificationMessagesMap } from 'constants/notificationMessages';

const handleNotificationItem = ({ type, label, key }: IAddNotificationItemPayload) =>
  type && label ? { label, type } : key ? notificationMessagesMap[key] : notificationMessagesMap.defaultError;

export const handleNotificationList = (
  notificationItem: IAddNotificationItemPayload,
  notificationList: INotificationItem[]
): INotificationItem[] => {
  return [
    ...notificationList,
    {
      ...handleNotificationItem(notificationItem),
      notificationId: `${uuidv4()}`,
    },
  ];
};
