import React, { useState, useEffect } from 'react';
import { useUserInfo, useAppSelector, useAccountManagerInfo } from 'hooks';

import styles from './SidebarInfo.module.scss';

interface ISidebarInfoData {
  email: string | null;
  fullName: string | null;
  phoneNumber: string | null;
  address?: string | null;
}

const SidebarInfoComponent: React.FC = (): JSX.Element => {
  const { listingId, landlordInfo, listingInfo } = useAppSelector((state) => state.selectedListing);
  const [sideBarInfoData, setSidebarInfoData] = useState<ISidebarInfoData | null>(null);
  const { role } = useUserInfo();
  const { accountManagerName, accountManagerEmail, accountManagerPhoneNumber } = useAccountManagerInfo();

  useEffect(() => {
    const accountManagerData = {
      email: accountManagerEmail,
      phoneNumber: accountManagerPhoneNumber,
      fullName: accountManagerName,
    };

    const sidebarInfoData =
      role === 'Landlord' ? accountManagerData : { ...landlordInfo, address: listingInfo.address };

    setSidebarInfoData(sidebarInfoData);
  }, [role, accountManagerName, accountManagerEmail, accountManagerPhoneNumber, landlordInfo, listingInfo.address]);

  return (
    <div className={styles.sidebarInfo}>
      {role !== 'Landlord' && !listingId ? null : (
        <>
          {role === 'Landlord' ? (
            <span>For any questions regarding your properties, please contact your Account Manager below</span>
          ) : (
            <span>Landlord details:</span>
          )}
          {role !== 'Landlord' && <span>{sideBarInfoData?.address}</span>}
          <span className={styles.sidebarInfoContact}>{sideBarInfoData?.fullName}</span>
          <span>{sideBarInfoData?.phoneNumber}</span>
          <a href={`mailto:${sideBarInfoData?.email}`}>{sideBarInfoData?.email}</a>
        </>
      )}
    </div>
  );
};

export const SidebarInfo = React.memo(SidebarInfoComponent);
