import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITableRowItemFields } from 'components/elements/Table/types';
import {
  ILandlordInfo,
  IListingInfo,
  ISelectedListingState,
  ISelectedViewingsInfo,
  ISelectedViewsInfo,
  IListingTerminationInfo,
  IListingHistoryItem,
  ISetSelectedListingPayload,
  IListingSuggestionItem,
  IPropertySuggestionItem,
} from 'types';

const initialState: ISelectedListingState = {
  listingId: null,
  salesforceListingId: null,
  salesforcePropertyId: null,
  listingInfo: {
    status: null,
    address: null,
    enquiriesNumber: null,
    offersNumber: null,
    price: null,
    petsAllowed: null,
    furnished: null,
    bedroomsNumber: null,
    bathroomsNumber: null,
    parkingAvailable: null,
    availableDate: null,
    currency: null,
    savingsNumber: null,
    rentManagement: null,
    tenancyStartDate: null,
    tenancyEndDate: null,
    priceReductionDate: null,
    propertySalesforceId: null,
  },
  landlordInfo: {
    email: null,
    fullName: null,
    phoneNumber: null,
  },
  viewingsInfo: {
    adViewingsNumber: null,
    plannedViewings: null,
    pastViewings: null,
  },
  viewsInfo: {
    yesterdayViews: 0,
    chartListingData: [],
  },
  listingTerminationInfo: {
    id: null,
    newListingTerminationDateRequested: false,
    existingListingTerminationEndDate: null,
    updatedListingTerminationEndDate: null,
  },
  listingHistoryInfo: {
    listingHistoryItems: [],
    listingHistoryTableItems: [],
  },
  listingSuggestions: {
    defaultOption: {
      listingId: '',
      salesforceListingId: '',
      salesforcePropertyId: '',
      status: '',
      address: '',
    },
    suggestionsList: [],
  },
  propertySuggestions: {
    defaultOption: {
      propertyId: '',
      listingId: '',
      salesforceListingId: '',
      salesforcePropertyId: '',
      address: '',
    },
    suggestionsList: [],
  },
};

export const selectedListingSlice = createSlice({
  name: 'selectedListing',
  initialState,
  reducers: {
    setSelectedListing(state, action: PayloadAction<ISetSelectedListingPayload>) {
      state.listingId = action.payload.listingId;
      state.salesforceListingId = action.payload.salesforceListingId;
      state.salesforcePropertyId = action.payload.salesforcePropertyId;
    },
    setListingInfo(state, action: PayloadAction<IListingInfo>) {
      state.listingInfo = action.payload;
    },
    setLandlordInfo(state, action: PayloadAction<ILandlordInfo>) {
      state.landlordInfo = action.payload;
    },
    setListingViewingsNumber(state, action: PayloadAction<ISelectedViewingsInfo>) {
      state.viewingsInfo = action.payload;
    },
    setListingsViewsInfo(state, action: PayloadAction<ISelectedViewsInfo>) {
      state.viewsInfo = action.payload;
    },
    setListingTerminationInfo(state, action: PayloadAction<IListingTerminationInfo>) {
      state.listingTerminationInfo = action.payload;
    },
    setListingsHistoryItems(state, action: PayloadAction<IListingHistoryItem[]>) {
      state.listingHistoryInfo.listingHistoryItems = action.payload;
    },
    setListingsHistoryTableItems(state, action: PayloadAction<ITableRowItemFields[]>) {
      state.listingHistoryInfo.listingHistoryTableItems = action.payload;
    },
    setListingSuggestionsDefaultOption(state, action: PayloadAction<IListingSuggestionItem>) {
      state.listingSuggestions.defaultOption = action.payload;
    },
    setListingSuggestionsList(state, action: PayloadAction<IListingSuggestionItem[]>) {
      state.listingSuggestions.suggestionsList = action.payload;
    },
    setPropertySuggestionsDefaultOption(state, action: PayloadAction<IPropertySuggestionItem>) {
      state.propertySuggestions.defaultOption = action.payload;
    },
    setPropertySuggestionsList(state, action: PayloadAction<IPropertySuggestionItem[]>) {
      state.propertySuggestions.suggestionsList = action.payload;
    },
    resetListingState(state) {
      return { ...initialState, listingId: state.listingId };
    },
  },
});

export default selectedListingSlice.reducer;
