import { getWalletPrice, getPPDate, getTransactionPrice } from 'utils/helpers';
import { IGetWalletResponse, IWalletState, IUpdatedLandlordAccount } from 'types';

export const handleWalletModel = ({
  id,
  rentDueDate,
  totalTenancyRate,
  monthlyTenancyRate,
  nextMonthlyTenancyRate,
  landlordAccountName,
  landlordAccountNumber,
  landlordAccountSortCode,
  newBankDetailsRequested,
  tenancyLedgerId,
  tenancyAccountNumber,
  tenancyAccountSortCode,
  lastMonthlyRateAdjustmentReason,
}: IGetWalletResponse): IWalletState => {
  return {
    walletInfo: {
      walletId: `${id}`,
      rentDueDate: getPPDate(rentDueDate),
      totalTenancyRate: getTransactionPrice(totalTenancyRate),
      newBankDetailsRequested,
    },
    monthlyTenancy: {
      monthlyTenancyRate,
      monthlyTenancyRateLabel: getWalletPrice(monthlyTenancyRate),
      nextMonthlyTenancyRate,
      lastMonthlyRateAdjustmentReason,
    },
    landlordAccount: {
      landlordAccountName: landlordAccountName || '',
      landlordAccountNumber: landlordAccountNumber || '',
      landlordAccountSortCode: landlordAccountSortCode || '',
    },
    updatedLandlordAccount: {
      updatedLandlordAccountName: undefined,
      updatedLandlordAccountNumber: undefined,
      updatedLandlordAccountSortCode: undefined,
    },
    tenancyAccount: {
      tenancyLedgerId,
      tenancyAccountNumber,
      tenancyAccountSortCode,
    },
  };
};

export const handleUpdatedBankDetailsModel = ({ updated }: any): IUpdatedLandlordAccount => {
  const { landlordAccountName, landlordAccountNumber, landlordAccountSortCode } = updated;
  return {
    updatedLandlordAccountName: landlordAccountName,
    updatedLandlordAccountNumber: landlordAccountNumber,
    updatedLandlordAccountSortCode: landlordAccountSortCode,
  };
};
