import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import UserPool from 'config/UserPool';

const CognitoAuth = () => {
  const Pool = UserPool;
  let sessionUserAttributes: any;
  let User: CognitoUser;

  return {
    signIn: async (username: string, password: string) =>
      new Promise<CognitoUserSession>((resolve, reject) => {
        User = new CognitoUser({ Username: username, Pool });
        const authDetails = new AuthenticationDetails({ Username: username, Password: password });

        User.authenticateUser(authDetails, {
          onSuccess: (data) => resolve(data),
          onFailure: (err) => reject(err),
          newPasswordRequired: (userAttributes) => {
            const err: any = new Error('newPasswordRequired');
            err.userAttributes = userAttributes;

            delete userAttributes.email_verified;
            delete userAttributes.phone_number_verified;
            delete userAttributes.phone_number;
            delete userAttributes.email;

            sessionUserAttributes = userAttributes;
            reject(err);
          },
          mfaRequired: () => {
            reject(new Error('mfaRequired'));
          },
          totpRequired: () => {
            reject(new Error('totpRequired'));
          },
          customChallenge: () => {
            reject(new Error('customChallenge'));
          },
          mfaSetup: () => {
            reject(new Error('mfaSetup'));
          },
          selectMFAType: () => {
            reject(new Error('selectMFAType'));
          },
        });
      }),
    completeNewPasswordChallenge: async (newPassword: string) =>
      new Promise((resolve, reject) => {
        User.completeNewPasswordChallenge(newPassword, sessionUserAttributes, {
          onSuccess: resolve,
          onFailure: reject,
        });
      }),
    mfaSetup: async () =>
      new Promise((resolve) => {
        User.enableMFA(resolve);
      }),
    sendMFAcode: async (confirmationCode: string) =>
      new Promise<CognitoUserSession>((resolve, reject) => {
        User.sendMFACode(confirmationCode, {
          onSuccess: (data) => resolve(data),
          onFailure: reject,
        });
      }),
    forgotPassword: async (username: string) => {
      User = new CognitoUser({ Username: username, Pool });

      return new Promise((resolve, reject) => {
        User.forgotPassword({
          onSuccess: resolve,
          onFailure: reject,
        });
      });
    },
    confirmPassword(username: string, verificationCode: string, newPassword: string) {
      User = new CognitoUser({ Username: username, Pool });

      return new Promise((resolve, reject) => {
        User.confirmPassword(verificationCode, newPassword, {
          onFailure(e) {
            reject(e);
          },
          onSuccess(e) {
            resolve(e);
          },
        });
      });
    },
    changePassword: async (oldPassword: string, newPassword: string) =>
      new Promise((resolve, reject) => {
        User.changePassword(oldPassword, newPassword, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }),
    forceChangePassword(oldPassword: string, newPassword: string) {
      const sessionError = new Error('There is a connection error, please login again');
      const cognitoUser = Pool.getCurrentUser();

      if (!cognitoUser) throw sessionError;

      cognitoUser?.getSession((error: Error) => console.log(error));

      return new Promise((resolve, reject) => {
        cognitoUser?.changePassword(oldPassword, newPassword, (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      });
    },
  };
};

export const cognitoAuth = CognitoAuth();
