import React from 'react';

import styles from './ContactUs.module.scss';

interface IContactUsProps {
  promoLabel: string;
}

const ContactUsComponent: React.FC<IContactUsProps> = ({ promoLabel }) => (
  <div className={styles.contactUs}>
    <span>{promoLabel}</span>
    <span className={styles.contactUsLink}>Contact us on 020 3372 5202</span>
  </div>
);

export const ContactUs = React.memo(ContactUsComponent);
