import * as yup from 'yup';

export const editUserSchema = yup.object().shape({
  role: yup.string().required('This field is required'),
  fullName: yup.string().required('This field is required'),
  phoneNumber: yup
    .string()
    .required('This field is required')
    .matches(/^\+\d{6,14}$/, 'Example: +447975777666'),
  email: yup.string().required('This field is required').email('Email must be a valid email'),
  id: yup.string().required('This field is required'),
});
