import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'hooks';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { addNotificationItem } from 'store/actions/notifications';
import { FormField, Input, Button, ContactUs } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { forgotPasswordShema } from 'utils/schemas';
import { cognitoAuth } from 'utils/cognito';

import styles from './ForgotPasswordPage.module.scss';

type FormValues = {
  email: string;
};

export const ForgotPasswordPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(forgotPasswordShema),
  });

  const forgotPasswordHandler = ({ email }: FormValues): void => {
    dispatch(loaderSlice.actions.increaseLoader());
    cognitoAuth
      .forgotPassword(email)
      .then(() => navigate(`/auth/confirm-password?userEmail=${encodeURIComponent(email)}`))
      .then(() => dispatch(addNotificationItem({ key: 'resetPasswordCodeSent' })))
      .catch((error) => dispatch(addNotificationItem({ type: 'error', label: error?.message })))
      .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
  };

  return (
    <div className="forgot-password">
      <h2 className={styles.taglineHeader}>Forgot password?</h2>
      <p className={styles.taglineBody}>
        Enter the email address associated with your account and we’ll send you a link to reset your password.
      </p>
      <form autoComplete="off" onSubmit={handleSubmit(forgotPasswordHandler)}>
        <FormField labelId="email" labelText="Email address" fieldError={errors.email as TFieldError}>
          <Input placeholder="Type your email address" id="email" {...register('email')} />
        </FormField>
        <div className={styles.controlSection}>
          <Button type="submit" fullWidth>
            Continue
          </Button>
        </div>
      </form>
      <ContactUs promoLabel="Don’t have an account?" />
    </div>
  );
};
