import { UserRole } from 'types';
import { useAppSelector } from './useAppSelector';

export const useUserInfo = () => {
  const { userInfo } = useAppSelector((state) => state.auth);

  return {
    id: userInfo?.id as string,
    email: userInfo?.email as string,
    role: userInfo?.role as UserRole,
    fullName: userInfo?.fullName as string,
  };
};
