export const handleFilterOptions = (role: string) => {
  return [
    {
      value: 'all',
      label: 'Show all',
    },
    {
      value: 'Ready to execute',
      label: role === 'Controller' ? 'Ready to approve' : 'Ready to execute',
    },
    {
      value: 'Overdue',
      label: 'Overdue',
    },
    {
      value: 'Upcoming',
      label: 'Upcoming',
    },
  ];
};
