import React, { useState, useEffect } from 'react';
import cx from 'clsx';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Input, RadioGroup, Tooltip } from 'components';
import { updateListingByListingId } from 'store/actions/selectedListing';
import { appModalSlice } from 'store/reducers/AppModalSlice';
import { getCalculateNumberDays, getPrice } from 'utils/helpers';

import { adDetailsItemOptionsMap } from '../constants';
import { IAdDetailsProps } from './types';
import styles from './AdDetails.module.scss';

const AdDetailsItemComponent: React.FC<IAdDetailsProps> = (props) => {
  const { labelText, value, icon, type, active, propertyStatus, priceReductionDate, actionCallback } = props;
  const [newValue, setNewValue] = useState<string | number | null>('');
  const { listingId } = useAppSelector((state) => state.selectedListing);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewValue(value);
  }, [active]);

  const handledDetailValue = () => {
    if (type === 'price') return newValue;

    // @ts-ignore
    return adDetailsItemOptionsMap[type].find((el) => el.value === newValue).apiValue;
  };

  const isMoreThan5Percent = () => {
    return value && newValue && ((+newValue - +value) / +value) * 100 >= 5;
  };

  const handleSaveAction = () => {
    if ((type === 'price' && newValue === '') || value === null || newValue == null || value === +newValue) {
      actionCallback('');
      return;
    }

    if (
      type === 'price' &&
      isMoreThan5Percent() &&
      priceReductionDate &&
      getCalculateNumberDays(new Date(), new Date(priceReductionDate)) <= 1
    ) {
      dispatch(
        appModalSlice.actions.setAppModalState({
          type: 'approveChangePrice',
          options: { price: handledDetailValue() },
        })
      );
    } else if (listingId) {
      dispatch(updateListingByListingId({ listingId, payload: { [type]: handledDetailValue() } }));
    }

    actionCallback('');
  };

  const renderActionBody = (): JSX.Element | null => {
    if (type === 'price' && active) {
      return (
        <div className={styles.inputActionBody}>
          <Input
            size="extraSmall"
            placeholder="Type new price"
            type="number"
            onChange={(e, data: string) => setNewValue(data)}
          />
        </div>
      );
    }

    if ((type === 'petsAllowed' || type === 'furnished') && active) {
      return (
        <div className={styles.radioActionBody}>
          <RadioGroup
            options={adDetailsItemOptionsMap[type]}
            name={type}
            checkedValue={newValue}
            onChange={(e, data: string) => setNewValue(data)}
          />
        </div>
      );
    }

    return null;
  };

  const renderActionControl = (): JSX.Element | null => {
    const isEditable = type === 'price' || type === 'petsAllowed' || type === 'furnished';

    if (isEditable && !active && propertyStatus !== 'In advertising') {
      return (
        <Tooltip
          text="Marketing preferences can only be changed when property is being marketed"
          placement="left-center"
        >
          <button className={styles.actionDisableEditLabel}>Edit</button>
        </Tooltip>
      );
    }

    if (isEditable && !active) {
      return (
        <button className={styles.actionEditLabel} onClick={() => actionCallback(type)}>
          Edit
        </button>
      );
    }

    if (isEditable && active) {
      return (
        <button className={styles.actionSaveLabel} onClick={() => handleSaveAction()}>
          Save
        </button>
      );
    }

    return null;
  };

  const renderAdDetailsValueLabel = () => {
    if (type === 'price' && value && typeof value === 'number') return getPrice({ price: value, currency: 'GBP' });
    return value;
  };

  return (
    <div className={cx(styles.adDetailsItem, active && styles.active)}>
      <div className={styles.rowItem}>
        <div className={styles.labelWrapper}>
          <span className={styles.icon}>
            <img src={icon} alt={labelText} />
          </span>
          <span className={styles.label}>{labelText}</span>
        </div>
        <div className={styles.valueWrapper}>
          <div className={styles.value}>{renderAdDetailsValueLabel()}</div>
        </div>
        <div className={styles.actionControl}>{renderActionControl()}</div>
      </div>
      <div className={styles.actionBody}>{renderActionBody()}</div>
    </div>
  );
};

export const AdDetailsItem = React.memo(AdDetailsItemComponent);
