import { TAuditNameResponse, TAuditType } from 'types';

export const auditNameTypeMap: Record<TAuditNameResponse, TAuditType> = {
  'Marketing statistics data pull': 'marketingStatistics',
  'Railsbank synchronization': 'railsbank',
  'Salesforce data synchronization': 'salesforce',
  'Landlord contact data pull': 'landlordContact',
  'Regular payments schedule sync': 'regularPayments',
  'Properties data pull with listings': 'properties',
  'Listing view records update': 'listingViewRecords',
  'Upcoming payments for HM fee generation': 'upcomingPayments',
};
