import React from 'react';
import cx from 'clsx';

import { IRadioProps } from './types';

import styles from './Radio.module.scss';

const RadioComponent = React.forwardRef<HTMLDivElement, IRadioProps>(function Radio(props, ref) {
  const { disabled, inputRef, id, name, label, value, checked, onChange, onFocus, onBlur, ...rest } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.currentTarget.value);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div>
      <input
        type="radio"
        className={cx(styles.input, disabled && styles.disabled)}
        id={id}
        ref={inputRef}
        name={name}
        disabled={disabled}
        value={value}
        checked={checked}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

export const Radio = React.memo(RadioComponent);
