import React, { useState } from 'react';

import { CodeInput, Button } from '..';
import { IMFACodeProps } from './types';
import styles from './MFACode.module.scss';

const MFACodeComponent: React.FC<IMFACodeProps> = ({ sendMFAcode, resendMFACode }) => {
  const [verificationCode, setVerificationCode] = useState('');

  return (
    <div className={styles.verificationCodeContainer}>
      <CodeInput
        handleChange={setVerificationCode}
        onComplete={(values: string) => sendMFAcode(values)}
        fields={6}
        className={styles.codeInput}
      />
      <Button
        disabled={verificationCode.length < 6}
        onClick={() => sendMFAcode(verificationCode)}
        type="submit"
        fullWidth
      >
        Submit
      </Button>
      <p className={styles.resendDescription}>
        <span>It may take a minute to receive your code. Haven&apos;t received it?</span>
        <button onClick={resendMFACode} className={styles.verificationResend}>
          Resend a new code.
        </button>
      </p>
    </div>
  );
};

export const MFACode = React.memo(MFACodeComponent);
