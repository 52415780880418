import React, { useState } from 'react';
import { Arrow, useLayer } from 'react-laag';

import { ChangePasswordModal, EditUserAccountModal } from 'components';
import { logout } from 'store/actions/auth';
import { useAppDispatch, useModal, useUserInfo } from 'hooks';

import styles from './AccountPopover.module.scss';

export const AccountPopover: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isChangePasswordModalOpen, toggleChangePasswordModal] = useModal();
  const [isEditUserModalOpen, toggleEditUserModalOpen] = useModal();
  const { role } = useUserInfo();

  const close = () => setOpen(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: close,
    onDisappear: close,
    overflowContainer: false,
    auto: true,
    placement: 'bottom-end',
    triggerOffset: 5,
    containerOffset: 0,
    arrowOffset: 15,
  });

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleLogoutKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter') {
      handleLogout();
    }
  };

  return (
    <>
      <button {...triggerProps} onClick={() => setOpen(!isOpen)} className={styles.button}>
        {children}
      </button>
      {renderLayer(
        isOpen && (
          <ul {...layerProps} className={styles.list} role="menu">
            {/* disabled due to missed functionality HM-290
            <li className={styles.listItem} role="menuitem">
              Watch onboarding tutorial
            </li> */}
            {role !== 'Landlord' && (
              <li
                className={styles.listItem}
                onClick={toggleEditUserModalOpen}
                onKeyDown={toggleEditUserModalOpen}
                role="menuitem"
              >
                Edit account details
              </li>
            )}
            <li
              className={styles.listItem}
              onClick={toggleChangePasswordModal}
              onKeyDown={toggleChangePasswordModal}
              role="menuitem"
            >
              Change password
            </li>
            <li className={styles.listItem} onClick={handleLogout} onKeyDown={handleLogoutKeyDown} role="menuitem">
              Log out
            </li>
            <Arrow {...arrowProps} angle={35} size={14} />
          </ul>
        )
      )}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal isOpen={isChangePasswordModalOpen} toggle={toggleChangePasswordModal} />
      )}
      {isEditUserModalOpen && <EditUserAccountModal isOpen={isEditUserModalOpen} toggle={toggleEditUserModalOpen} />}
    </>
  );
};
