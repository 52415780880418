import React from 'react';

import { ISavingsCardProps } from './types';
import { savingsDetailsTitleMap } from '../constants';
import styles from './SavingsCard.module.scss';

const SavingsCardComponent: React.FC<ISavingsCardProps> = ({ savingsNumber }) => {
  return (
    <div className={styles.savingsCard}>
      <span className={styles.savingsCardTitle}>{savingsDetailsTitleMap.title}</span>
      <span>
        <span className={styles.savingsCardValue}>{savingsNumber}</span>
        <span className={styles.savingsCardValueAsterisk}>*</span>
      </span>
      <span className={styles.savingsCardDisclaimer}>{savingsDetailsTitleMap.disclaimer}</span>
    </div>
  );
};
export const SavingsCard = React.memo(SavingsCardComponent);
