import qs from 'qs';

import { IGetFinancesListPayload, IFinancesListResponse, IExecuteApprovePayload } from 'types';
import { axiosPrivate } from 'utils/axios';

export const getFinancesListCall = async (params: IGetFinancesListPayload) => {
  return axiosPrivate
    .get<IFinancesListResponse>('finance/v1/finances', {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((response) => response.data);
};

export const approveFinancesCall = async (params: IExecuteApprovePayload) => {
  return axiosPrivate
    .post<IFinancesListResponse>('finance/v1/finances/approve', params)
    .then((response) => response.data);
};

export const executeFinanceCall = async (params: IExecuteApprovePayload) => {
  return axiosPrivate
    .post<IFinancesListResponse>('finance/v1/finances/execute', params)
    .then((response) => response.data);
};
