import React from 'react';

import { Card, Checkbox } from 'components';
import { useModal, useAppSelector, useAppDispatch, useUserInfo } from 'hooks';
import { updateListingRentManagement } from 'store/actions/selectedListing';
import { OptOutModal } from '../OptOutModal';
import { InfoPaneItem } from '../InfoPaneItem';
import { IInfoPaneProps } from './types';
import { getInfoPaneMap } from '../constants';
import styles from './InfoPane.module.scss';

const InfoPaneComponent: React.FC<IInfoPaneProps> = (props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { listingId } = useAppSelector((state) => state.selectedListing);
  const [isOptOutOpen, toggleOptOut] = useModal();
  const { role } = useUserInfo();
  const infoPaneMap = getInfoPaneMap(role);

  const handleOptOutAction = () => {
    if (listingId) dispatch(updateListingRentManagement({ listingId, rentManagement: true }));
  };

  const handleOptOutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      toggleOptOut();
    } else {
      handleOptOutAction();
    }
  };

  return (
    <div className={styles.infoPane}>
      <Card>
        <div className={styles.infoPaneContent}>
          {infoPaneMap.map(
            ({ key, title, acceptableRoles }) =>
              acceptableRoles.includes(role) && (
                <InfoPaneItem data={props[key as keyof IInfoPaneProps]} type={key} labelText={title} key={key} />
              )
          )}
        </div>
        {role !== 'Agent' ? (
          <div className={styles.infoPaneFooter}>
            <Checkbox
              checked={!!props.rentManagement}
              size="small"
              label="Yes, I want Home Made to manage the rental collection on this property"
              onChange={(event) => handleOptOutChange(event)}
            />
            <OptOutModal isOpen={isOptOutOpen} toggle={toggleOptOut} />
          </div>
        ) : null}
      </Card>
    </div>
  );
};

export const InfoPane = React.memo(InfoPaneComponent);
