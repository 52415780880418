import { axiosPrivate } from 'utils/axios';
import { IDocumentItemResponse, IDownloadDocumentByDocumentIdPayload, IGetDocumentsByListingIdPayload } from 'types';

export const getDocumentsByListingId = async ({ listingId }: IGetDocumentsByListingIdPayload) =>
  axiosPrivate
    .get<IDocumentItemResponse[]>(`property/v1/documents/by-listing-id/${listingId}`)
    .then((response) => response.data);

export const downloadDocumentByDocumentId = async ({ documentId }: IDownloadDocumentByDocumentIdPayload) =>
  axiosPrivate.get(`property/v1/documents/${documentId}/download`, { responseType: 'blob' });
