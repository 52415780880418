import React from 'react';
import cx from 'clsx';

import { ControlInputContainer } from './internal/ControlInputContainer';
import { iconColorMap } from './internal/constants';
import { CheckIcon } from '../icons/CheckIcon';
import { ICheckboxProps } from './types';
import { TMemoForwardRefComponent } from '../types';
import styles from './Checkbox.module.scss';

const CheckboxComponent = React.forwardRef(function Checkbox(props: ICheckboxProps, ref: React.Ref<HTMLLabelElement>) {
  const { checked, disabled, invalid, size, label, className } = props;

  return (
    <ControlInputContainer
      {...props}
      checked={checked}
      type="checkbox"
      className={cx(
        styles.checkbox,
        className,
        disabled && styles.disabled,
        invalid && styles.invalid,
        checked && styles.labelChecked
      )}
      ref={ref}
    >
      <span className={cx(styles.box, styles[size!])}>
        <CheckIcon color={size ? iconColorMap[size] : 'currentColor'} />
      </span>
      {label && <span className={styles.label}>{label}</span>}
    </ControlInputContainer>
  );
});

export const Checkbox: TMemoForwardRefComponent<ICheckboxProps, HTMLLabelElement> = React.memo(CheckboxComponent);
