import React, { useEffect, useState } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

import { SearchField, Button, CheckboxGroup, Pagination } from 'components';
import { useAppSelector, usePrevious, useAppDispatch, useModal, useUserInfo } from 'hooks';
import { getFinancesList, executeApproveFinances } from 'store/actions/finances';
import { sendVerificationCode } from 'store/actions/userData';
import { IFinancesListItem, IExecuteApproveFinanceItem } from 'types';

import { VerificationModal } from './internal/VerificationModal';
import { FinancesItem } from './internal/FinancesItem';
import { FinancesList } from './internal/FinancesList';
import { defaultFilterValues } from './internal/constants';
import { handleFilterOptions } from './internal/utils';
import styles from './ManageFinancesPage.module.scss';

export const ManageFinancesPage: React.FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isVerificationModalOpen, toggleVerificationModal] = useModal();
  const { role } = useUserInfo();
  const [selectedPage, setSelectedPage] = useState<number>(Number(searchParams.get('selectedPage')) || 1);
  const [filterValues, setFilterValues] = useState<string[]>(
    searchParams.getAll('filterValues').length > 0 ? searchParams.getAll('filterValues') : defaultFilterValues
  );
  const [search, setSearch] = useState<string>(searchParams.get('search') || '');
  const [financesToApprove, setFinancesToApprove] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const prevSelectedPage = usePrevious(selectedPage);
  const { financesListItems } = useAppSelector((state) => state.finances);
  const { totalProperties, pageSize } = useAppSelector((state) => state.finances.financesPaginationConfig);

  const handleFilters = (filters: string[]) => {
    if (filters.includes('all')) {
      return ['none'];
    }
    return filters;
  };

  const getFinancesListAction = ({ page }: Record<string, number>): void => {
    dispatch(
      getFinancesList({
        page,
        pageSize,
        search,
        filterByStatus: handleFilters(filterValues),
      })
    );
  };

  const getActionButtonTitle = (): string => {
    return `${role === 'Controller' ? 'Approve' : 'Execute'} (${financesToApprove.length}) selected payment${
      financesToApprove.length > 1 ? 's' : ''
    }`;
  };

  const handleFinancesToAction = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setFinancesToApprove((finances) => [...finances, id]);
    }

    if (!isChecked) {
      setFinancesToApprove((finances) => finances.filter((el) => el !== id));
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, inputValue: string[]): void => {
    if (e.target.checked && e.target.value === 'all') {
      setFilterValues(defaultFilterValues);
    } else if (!e.target.checked && e.target.value === 'all') {
      setFilterValues([]);
    } else {
      setFilterValues(inputValue.filter((el) => el !== 'all'));
    }
  };

  const handleAction = () => {
    dispatch(sendVerificationCode());
    toggleVerificationModal();
  };

  const handleSearchSubmit = () => {
    getFinancesListAction({ page: selectedPage });
    setSearchParams(
      createSearchParams({
        selectedPage: `${selectedPage}`,
        filterValues,
        search,
      })
    );
  };

  const approveExecuteFinances = async ({ verificationCode }: Record<string, string>) => {
    const finances = financesListItems
      .filter((item) => financesToApprove.includes(item.id))
      .map(
        ({
          id,
          data: { homemadePaymentsTotal, landlordPaymentsTotal, editedOnFrontSide },
        }): IExecuteApproveFinanceItem =>
          role === 'Admin' && !editedOnFrontSide ? { id } : { id, homemadePaymentsTotal, landlordPaymentsTotal }
      );

    dispatch(
      executeApproveFinances({
        finances,
        role,
        verificationCode,
        callback: () => {
          getFinancesListAction({ page: selectedPage });
          toggleVerificationModal();
        },
      })
    );
  };

  useEffect(() => {
    if (!prevSelectedPage && !searchParams.get('selectedPage')) {
      getFinancesListAction({ page: 1 });
    } else if (selectedPage === prevSelectedPage && selectedPage !== 1) {
      setSelectedPage(1);
    } else {
      getFinancesListAction({ page: selectedPage !== prevSelectedPage ? selectedPage : 1 });
    }

    setFinancesToApprove([]);
    setSearchParams(
      createSearchParams({
        selectedPage: `${selectedPage}`,
        filterValues,
        search,
      })
    );
  }, [selectedPage, filterValues]);

  return (
    <div className={styles.manageFinancesPage}>
      <div className={styles.controlSection}>
        <SearchField
          placeholder="Search for a property"
          value={search}
          onSubmit={handleSearchSubmit}
          onChange={(e, value) => setSearch(value)}
        />
        <Button theme="secondary" disabled={financesToApprove.length === 0} fullWidth onClick={handleAction}>
          {getActionButtonTitle()}
        </Button>
      </div>
      <div className={styles.filterSection}>
        <div>
          <CheckboxGroup
            horizontal
            options={handleFilterOptions(role)}
            onChange={handleFilterChange}
            value={filterValues}
          />
        </div>
        <div>
          <Pagination
            selectedPage={selectedPage}
            pageSize={pageSize}
            totalItems={totalProperties}
            onPageChanged={(page: number) => setSelectedPage(page)}
          />
        </div>
      </div>
      <div>
        <FinancesList>
          {financesListItems.map(({ data }: IFinancesListItem): JSX.Element => {
            return <FinancesItem {...data} onApproveClick={handleFinancesToAction} userRole={role} />;
          })}
        </FinancesList>
      </div>
      {isVerificationModalOpen && (
        <VerificationModal
          isOpen={isVerificationModalOpen}
          toggle={toggleVerificationModal}
          onSubmit={approveExecuteFinances}
          resendVerificationCode={() => dispatch(sendVerificationCode())}
        />
      )}
    </div>
  );
};
