import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITableRowItemFields } from 'components/elements/Table/types';
import { IPaginationOptions } from 'components/elements/Pagination/types';
import { IListingListState, IListingsItem } from 'types';

const initialState: IListingListState = {
  listingsItems: [],
  listingsTableItems: [],
  listingsPaginationConfig: {
    selectedPage: 1,
    pageSize: 20,
    totalItems: 1,
  },
  listingsFiltersConfig: {
    search: '',
    filterByStatus: 'none',
    orderByPrice: 'desc',
    orderByTenancyStartDate: 'desc',
    orderByTenancyEndDate: 'desc',
  },
};

export const listingsListSlice = createSlice({
  name: 'propertyList',
  initialState,
  reducers: {
    setListingsItems(state, action: PayloadAction<IListingsItem[]>) {
      state.listingsItems = action.payload;
    },
    setListingsTableItems(state, action: PayloadAction<ITableRowItemFields[]>) {
      state.listingsTableItems = action.payload;
    },
    setListingsPaginationConfig(state, action: PayloadAction<IPaginationOptions>) {
      state.listingsPaginationConfig.selectedPage = action.payload.selectedPage;
      state.listingsPaginationConfig.totalItems = action.payload.totalItems;
    },
  },
});

export default listingsListSlice.reducer;
