import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFinancesPaginationConfig, IFinancesState, IFinancesListItem } from 'types';

const initialState: IFinancesState = {
  financesListItems: [],
  financesPaginationConfig: {
    selectedPage: 1,
    pageSize: 20,
    totalProperties: 1,
  },
};

export const financesSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {
    setFinancesFieldsList(state, action: PayloadAction<IFinancesListItem[]>) {
      state.financesListItems = action.payload;
    },
    setFinancesPaginationConfig(state, action: PayloadAction<IFinancesPaginationConfig>) {
      state.financesPaginationConfig.selectedPage = action.payload.selectedPage;
      state.financesPaginationConfig.totalProperties = action.payload.totalProperties;
    },
  },
});

export default financesSlice.reducer;
