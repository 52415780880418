import { UserLabels, UserRole } from 'types';
import { roleMap } from 'constants/userList';
import { statusToMap, staticCurrentDayDiff } from '../../constants/properties';

export const handleFilters = (filters: string[]) => {
  if (filters.includes('all')) {
    return ['none'];
  }
  return filters.map((el) => statusToMap[el as keyof typeof statusToMap]).flat();
};

export const handleUserFilters = (filters: string[]) => {
  if (filters.includes('all')) {
    return ['none'];
  }
  return filters.flat();
};

export const handleBoleanTitle = (value: boolean): string => {
  return value ? 'Yes' : 'No';
};

export const handleFurnishedTitle = (value: boolean): string => {
  return value === null ? 'Flexible' : value ? 'Yes' : 'No';
};

export const handleCardNumber = (cardNumber: string): string => {
  return cardNumber ? `*****${cardNumber.slice(-3)}` : '-';
};

export const getCalculateNumberDays = (currentDate: Date, calculatedDate: Date): number => {
  const differenceInTime = currentDate.getTime() - calculatedDate.getTime();

  return Math.ceil(differenceInTime / (1000 * 3600 * 24)) - staticCurrentDayDiff;
};

export const getDecimalStringNumber = (num: number): string => {
  if (num % 1 !== 0) {
    return num.toFixed(2);
  }
  return `${num}`;
};

export const getUserRoleKey = (value: UserLabels): UserRole | undefined => {
  const objectKeys = Object.keys(roleMap) as Array<keyof typeof roleMap>;
  return objectKeys.find((key: keyof typeof roleMap) => roleMap[key as keyof typeof roleMap] === value);
};
