import { ITableRowItemFields } from 'components/elements/Table/types';
import {
  IListingItemResponse,
  IListingsItem,
  TListingStatus,
  TListingStatusResponse,
  IListingByPropertySalesforceIdResponse,
} from 'types';
import { getFullDate, getPrice } from 'utils/helpers';

export const statusMap: Record<TListingStatusResponse, TListingStatus> = {
  Let: 'Tenanted',
  Contract: 'Progressing tenants',
  'Under Offer': 'Progressing tenants',
  RentDeposit: 'Progressing tenants',
  Referencing: 'Progressing tenants',
  Marketing: 'In advertising',
};

export const handleListingItems = ({
  id,
  propertySalesforceId,
  listingId,
  status,
}: IListingItemResponse | IListingByPropertySalesforceIdResponse): IListingsItem => {
  return {
    listingId: `${id}`,
    salesforceListingId: listingId,
    salesforcePropertyId: propertySalesforceId,
    status: statusMap[status],
  };
};

export const handleListingTableItems = ({
  id,
  address,
  status,
  price,
  currency,
  tenancyStartDate,
  tenancyEndDate,
}: IListingItemResponse): ITableRowItemFields => {
  return {
    id: `${id}`,
    values: [
      {
        fieldName: 'id',
        value: `${id}`,
      },
      {
        fieldName: 'address',
        value: address,
      },
      {
        fieldName: 'status',
        value: statusMap[status as keyof typeof statusMap],
        initialValue: status,
        fieldType: 'status',
      },
      {
        fieldName: 'propertyPrice',
        value: getPrice({ currency, price }),
      },
      {
        fieldName: 'startDate',
        value: getFullDate(tenancyStartDate),
      },
      {
        fieldName: 'endDate',
        value: getFullDate(tenancyEndDate),
      },
    ],
  };
};
