import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ITransactionsState,
  ITransactionGroup,
  ITransactionPaginationConfig,
  ITransactionDateRangeConfig,
} from 'types';

const initialState: ITransactionsState = {
  transactionGroupList: [],
  transactionPaginationConfig: {
    selectedPage: 1,
    pageSize: 20,
    totalTransactions: 0,
  },
  transactionDateRangeConfig: {
    dateTo: '',
    dateFrom: '',
  },
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionGroupList(state, action: PayloadAction<ITransactionGroup[]>) {
      state.transactionGroupList = action.payload;
    },
    setTransactionPaginationConfig(state, action: PayloadAction<ITransactionPaginationConfig>) {
      state.transactionPaginationConfig = action.payload;
    },
    setTransactionDateRangeConfig(state, action: PayloadAction<ITransactionDateRangeConfig>) {
      state.transactionDateRangeConfig = action.payload;
    },
    resetTransactionState() {
      return initialState;
    },
  },
});

export default transactionsSlice.reducer;
