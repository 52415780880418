import * as yup from 'yup';
import { passwordSchema } from './passwordSchema';

export const getExpiredPasswordSchema = (oldPassword: string) => {
  return yup.object().shape({
    password: passwordSchema
      .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match')
      .test('oldPassword', 'Passwords must be new', (value) => value !== oldPassword),
    confirmPassword: passwordSchema
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .test('oldPassword', 'Passwords must be new', (value) => value !== oldPassword),
  });
};
