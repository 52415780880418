import React from 'react';

import { IMarketingStatsItemProps } from './types';
import styles from './MarketingStatsItem.module.scss';

const MarketingStatsItemComponent: React.FC<IMarketingStatsItemProps> = (props) => {
  const { labelText, value } = props;

  return (
    <div className={styles.marketingStatsItem}>
      <span className={styles.label}>{labelText}</span>
      <div className={styles.delimiter} />
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export const MarketingStatsItem = React.memo(MarketingStatsItemComponent);
