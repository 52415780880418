import { AppDispatch } from 'store';
import { listingsListSlice } from 'store/reducers/ListingsListSlice';
import { addNotificationItem } from 'store/actions/notifications';
import { getListingsListCall } from 'store/api/listing';
import { IGetListingListPayload } from 'types';
import { handleListingItems, handleListingTableItems } from 'store/models/listingsList';
import { loaderSlice } from 'store/reducers/LoaderSlice';

export const getListingsList = (params: IGetListingListPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const { items, pageInfo } = await getListingsListCall(params);
    const { page, total } = pageInfo;

    const listingItems = items.map((item) => handleListingItems(item));
    const listingsTableItems = items.map((item) => handleListingTableItems(item));

    dispatch(listingsListSlice.actions.setListingsItems(listingItems));
    dispatch(listingsListSlice.actions.setListingsTableItems(listingsTableItems));
    dispatch(
      listingsListSlice.actions.setListingsPaginationConfig({
        selectedPage: page,
        totalItems: total,
        pageSize: 20,
      })
    );
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};
