import { chartLegendMap, baseOffsetY } from './constants';
import { IChartItem, IAnnotation } from '../types';

const getImgTempalate = (src: string, title: string) =>
  `<div class="chartTag"><img src=${src} title="${title}" alt="${title}"/><div class="tagArrow"/></div>`;

const createAnotation = (anotation: IAnnotation, el: IChartItem, index: number) => {
  const chartLegend = chartLegendMap.find((legend) => legend.key === anotation.key);

  return {
    type: 'html',
    position: [el.viewDate, el.totalDetailViews],
    // @ts-ignore */
    html: getImgTempalate(chartLegend.icon, chartLegend.label),
    offsetX: -15,
    offsetY: -(baseOffsetY * (index + 1)),
  };
};

export const createAnnotations = (data: []) => {
  return data
    .map((el: IChartItem) =>
      el.anotations
        .filter((anotation: IAnnotation) => anotation.value)
        .map((anotation: IAnnotation, index: number) => createAnotation(anotation, el, index))
    )
    .flat();
};

export const handleXAxis = (array: []) => {
  const xAxisValue = array.length > 15 ? 0.9 : array.length / 15;
  return {
    range: [0, xAxisValue],
  };
};
