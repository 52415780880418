import React from 'react';

import { IPaymentItemLabelProps } from './types';
import styles from './PaymentItemLabel.module.scss';

export const PaymentItemLabelComponent: React.FC<IPaymentItemLabelProps> = (props) => {
  const { landlordPaymentsTotalLabel, homemadePaymentsTotalLabel, type, handleEdit, balance } = props;

  return (
    <div className={styles.financesDescriptionSubTitleContainer}>
      <span className={styles.financesDescriptionSubTitle}>
        {type === 'landlordPaymentsTotal' ? landlordPaymentsTotalLabel : homemadePaymentsTotalLabel}
      </span>
      {balance > 0 ? (
        <button type="button" className={styles.editButton} onClick={() => handleEdit(type)}>
          Edit
        </button>
      ) : null}
    </div>
  );
};

export const PaymentItemLabel = React.memo(PaymentItemLabelComponent);
