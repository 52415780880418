import React, { useState } from 'react';

import { useUserInfo } from 'hooks';
import { ReactComponent as AlertIcon } from 'images/alert-icon.svg';
import { Checkbox, ExpandCollapseButton, Tooltip } from 'components';
import { PaymentItem } from '../PaymentItem';
import { IFinancesItemProps } from './types';
import styles from './FinancesItem.module.scss';

export const FinancesItemComponent: React.FC<IFinancesItemProps> = (props) => {
  const {
    id,
    rentDueDate,
    userRole,
    tenancyAddress,
    lastIncomingTransactionDate,
    lastIncomingTransactionAmount,
    balanceLabel,
    outgoingTotalLabel,
    outgoingTotal,
    onApproveClick,
    editedOnFrontSide,
  } = props;
  const [isCollapsed, handleCollapse] = useState(false);
  const [editedPaymantItem, setEditedPaymantItem] = useState('');
  const { role } = useUserInfo();

  const getDays = () => {
    return +rentDueDate > 1 ? 'days' : 'day';
  };

  const renderOverdueContainer = () => {
    return (
      <div className={styles.overdueContainer}>
        <AlertIcon />
        <span className={styles.overdueLabel}>
          Overdue {rentDueDate} {getDays()}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.financesItemRow}>
      <div className={styles.financesItem}>
        <div className={styles.financesItemMain}>
          <div>
            <div className={styles.financesItemTitleContainer}>
              <h3 className={styles.financesItemTitle}>{tenancyAddress}</h3>
              {rentDueDate !== '' && rentDueDate !== '0' && rentDueDate && renderOverdueContainer()}
            </div>
            <div className={styles.financesItemDescriptionContainer}>
              <div>
                <span className={styles.descriptionItemTitle}>Incoming {lastIncomingTransactionDate}</span>
                <span className={styles.descriptionItemContent}>{lastIncomingTransactionAmount}</span>
              </div>
              <div>
                <span className={styles.descriptionItemTitle}>Balance</span>
                <span className={styles.descriptionItemContent}>{balanceLabel}</span>
              </div>
              <div>
                <span className={styles.descriptionItemTitle}>Outgoing</span>
                <span className={styles.descriptionItemContent}>{outgoingTotalLabel}</span>
              </div>
            </div>
          </div>
          <div className={styles.approveItemContainer}>
            <Checkbox
              label={userRole === 'Controller' ? 'Ready to approve' : 'Ready to execute'}
              size="small"
              className={styles.actionCheckbox}
              disabled={outgoingTotal === 0}
              onChange={(e) => onApproveClick(e, id)}
            />
          </div>
          <div className={styles.expandCollapseContainer}>
            <ExpandCollapseButton isCollapsed={isCollapsed} onClick={() => handleCollapse(!isCollapsed)} />
          </div>
          {editedOnFrontSide && (
            <div className={styles.itemEditedTooltip}>
              <Tooltip
                text={
                  role !== 'Admin' ? 'This item was edited but not approved' : 'This item was edited but not executed'
                }
                type="error"
                placement="left-start"
              />
            </div>
          )}
        </div>
        {isCollapsed && (
          <div className={styles.financesItemAdditional}>
            <PaymentItem
              {...props}
              type="landlordPaymentsTotal"
              isEdit={editedPaymantItem === 'landlordPaymentsTotal'}
              handleEdit={setEditedPaymantItem}
            />
            <PaymentItem
              {...props}
              type="homemadePaymentsTotal"
              isEdit={editedPaymantItem === 'homemadePaymentsTotal'}
              handleEdit={setEditedPaymantItem}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const FinancesItem = React.memo(FinancesItemComponent);
