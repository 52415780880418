import React from 'react';

import { Button } from 'components';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { useAppDispatch } from 'hooks';
import { TDocumentId } from 'types';
import alertImg from 'images/alert.svg';
import tickImg from 'images/tick.svg';
import { downloadDocumentByDocumentId } from 'store/api/documents';

import styles from './DocumentItem.module.scss';

export interface IDocumentItemProps {
  title: string;
  documentId?: TDocumentId;
  fileExtension?: string;
}

export const DocumentItem: React.FC<IDocumentItemProps> = ({ title, documentId, fileExtension }) => {
  const dispatch = useAppDispatch();

  const handleDownload = () => {
    if (documentId) {
      dispatch(loaderSlice.actions.increaseLoader());
      downloadDocumentByDocumentId({ documentId })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${title}.${fileExtension}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => console.error(new Error(e)))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    }
  };

  const isUploaded = !!documentId;

  return (
    <li className={styles.listItem}>
      <div className={styles.titleWrapper}>
        {isUploaded ? (
          <img src={tickImg} alt="Not uploaded" className={styles.img} />
        ) : (
          <img src={alertImg} alt="Not uploaded" className={styles.img} />
        )}
        <span className={styles.title}>{title}</span>
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          size="small"
          disabled={!isUploaded}
          theme={isUploaded ? 'primary' : 'secondary'}
          fullWidth
          onClick={handleDownload}
        >
          {isUploaded ? 'Download a copy' : 'Pending upload'}
        </Button>
      </div>
    </li>
  );
};

DocumentItem.defaultProps = {
  documentId: undefined,
  fileExtension: 'pdf',
};
