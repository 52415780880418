import React from 'react';

import { NavigationPaneItem } from './internal';
import { INavigationPaneComponentProps } from './types';

const NavigationPaneComponent: React.FC<INavigationPaneComponentProps> = ({ items, disabledItems }): JSX.Element => {
  return (
    <div>
      {items.map(({ label, to, icon, disabledIcon }): JSX.Element => {
        const isPaneItemDisabled = disabledItems.find((el) => el === to);

        return (
          <NavigationPaneItem
            to={to}
            label={label}
            icon={isPaneItemDisabled ? disabledIcon : icon}
            disabled={!!isPaneItemDisabled}
            key={to}
          />
        );
      })}
    </div>
  );
};

export const NavigationPane = React.memo(NavigationPaneComponent);
