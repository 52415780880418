import { AppDispatch } from 'store';
import { userListSlice } from 'store/reducers/UserListSlice';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { updateUserAccountCall } from 'store/api/user';
import { getUserListCall, activateUserCall, deactivateUserCall, reInviteUserCall } from 'store/api/user';
import { addNotificationItem } from 'store/actions/notifications';
import { handleUserListItems } from 'store/models/userList';
import {
  IGetUserListPayload,
  IPatchUserStatus,
  IPatchReInviteUserPayload,
  IUser,
  IUpdateUserAccountPayload,
} from 'types';

export const getUserList = (params: IGetUserListPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const { items, pageInfo } = await getUserListCall(params);
    const { page, total } = pageInfo;

    const userList = items.map((item: IUser) => handleUserListItems(item));

    dispatch(userListSlice.actions.setUserFieldsList(userList));
    dispatch(
      userListSlice.actions.setUsersPaginationConfig({
        selectedPage: page,
        totalItems: total,
        pageSize: 20,
      })
    );
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateStatusUser = (params: IPatchUserStatus) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const { type, payload } = params;

    const response = type === 'activate' ? await activateUserCall(payload) : await deactivateUserCall(payload);
    const user = handleUserListItems(response);

    dispatch(userListSlice.actions.updateUserListItem(user));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const updateItemUser = (params: IUpdateUserAccountPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const response = await updateUserAccountCall(params);
    const user = handleUserListItems(response);

    dispatch(userListSlice.actions.updateUserListItem(user));
  } catch (error) {
    // @ts-ignore: Unreachable code error
    const { details, message } = error;
    dispatch(addNotificationItem({ type: 'error', label: message }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const reInviteUser = (params: IPatchReInviteUserPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    await reInviteUserCall(params);
    dispatch(addNotificationItem({ key: 'reInviteUserEmailSent' }));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};
