import { AppDispatch } from 'store';
import { transactionsSlice } from 'store/reducers/TransactionsSlice';
import { addNotificationItem } from 'store/actions/notifications';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { handleTransactionGroupList } from 'store/models/transactions';
import { IGetTransactionInfoPayload } from 'types';
import {
  getTransactionsByWalletIdCall,
  addUpcomingPaymentsCall,
  updateUpcomingPaymentsCall,
  deleteUpcomingPaymentsCall,
} from 'store/api/transactions';

export const getTransactionInfoByWalletId = (params: IGetTransactionInfoPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const transactionsData = await getTransactionsByWalletIdCall(params);
    const { items, pageInfo } = transactionsData;
    const handledTransactionGroupList = handleTransactionGroupList(items);

    dispatch(transactionsSlice.actions.setTransactionGroupList(handledTransactionGroupList));
    dispatch(
      transactionsSlice.actions.setTransactionPaginationConfig({
        selectedPage: pageInfo.page,
        totalTransactions: pageInfo.total,
        pageSize: 20,
      })
    );
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const handleUpcomingPayments = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const { payload, type } = params;

    if (type === 'edit') {
      await updateUpcomingPaymentsCall(payload);
    }

    if (type !== 'edit') {
      await addUpcomingPaymentsCall(payload);
    }
  } catch (error) {
    // @ts-ignore: Unreachable code error
    const { details, message } = error;
    dispatch(addNotificationItem({ type: 'error', label: message }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const deleteUpcomingPayments = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    await deleteUpcomingPaymentsCall(params);
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};
