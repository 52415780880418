import React from 'react';
import cx from 'clsx';

import { ISelectProps } from './types';
import styles from './Select.module.scss';

const SelectComponent = React.forwardRef(function Select(props: ISelectProps, ref: React.Ref<HTMLSelectElement>) {
  const { value, options, onChange, className, ...rest } = props;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value, e);
  };

  return (
    <select className={cx(styles.select, className)} onChange={handleChange} value={value} ref={ref} {...rest}>
      {options.length > 0 &&
        options.map((el) => (
          <option value={el.value} key={el.value}>
            {el.label}
          </option>
        ))}
    </select>
  );
});

export const Select = React.memo(SelectComponent);
