import React from 'react';

import { Modal } from 'components';

import { AuditEventRow } from '..';
import { IVerificationModalProps } from '../../types';
import styles from './LogInfoModal.module.scss';

export const LogInfoModalComponent: React.FC<IVerificationModalProps> = ({ isOpen, toggle, header, logEvents }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>{header}</h2>
      <div className={styles.body}>
        {logEvents.map((event) => (
          <AuditEventRow {...event} />
        ))}
      </div>
    </Modal>
  );
};

export const LogInfoModal = React.memo(LogInfoModalComponent);
