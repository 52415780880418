import React from 'react';
import cx from 'clsx';

import { ReactComponent as SearchFieldIcon } from 'images/search-field-icon.svg';
import { ISearchFieldWrapperProps } from './types';
import styles from './SearchFieldWrapper.module.scss';

export const SearchFieldWrapper = React.forwardRef<HTMLDivElement, ISearchFieldWrapperProps>(function SearchField(
  props,
  ref
) {
  const { focused, disabled, children, cursor, onClick, value, ...rest } = props;

  const test = () => {
    onClick(value);
  };

  return (
    <div
      {...rest}
      ref={ref}
      className={cx(styles.inputWrapper, focused && styles.focused, disabled && styles.disabled)}
      style={{ cursor }}
      tabIndex={disabled ? -1 : props.tabIndex}
    >
      <div className={styles.childrenWrapper}>
        <button className={styles.searchButton} aria-label="search" onClick={test}>
          <SearchFieldIcon />
        </button>
        {children}
      </div>
    </div>
  );
});

SearchFieldWrapper.defaultProps = {
  disabled: false,
  focused: false,
  cursor: undefined,
};
