import { IFinancesListItemResponse, IFinancesListItem } from 'types';
import { getPrice, getShortDate, getRentDueDate } from 'utils/helpers';

export const handleFinancesListItems = ({
  id,
  walletId,
  tenancyId,
  tenancyAddress,
  lastIncomingTransactionDate,
  lastIncomingTransactionAmount,
  balance,
  upcomingPaymentsTotal,
  landlordPaymentsTotal,
  homemadePaymentsTotal,
  outgoingTotal,
  status,
  approvedByName,
  fundsTransferFailed,
  approvedByDate,
  createdAt,
  updatedAt,
  rentDueDate,
}: IFinancesListItemResponse): IFinancesListItem => {
  return {
    id: `${id}`,
    data: {
      id: `${id}`,
      walletId: `${walletId}`,
      tenancyId: `${tenancyId}`,
      tenancyAddress,
      lastIncomingTransactionDate: getShortDate(lastIncomingTransactionDate),
      lastIncomingTransactionAmount: `${getPrice({ currency: 'GBP', price: lastIncomingTransactionAmount })}`,
      balance,
      balanceLabel: `${getPrice({ currency: 'GBP', price: balance })}`,
      upcomingPaymentsTotal: `${upcomingPaymentsTotal}`,
      landlordPaymentsTotal,
      homemadePaymentsTotal,
      landlordPaymentsTotalLabel: `${getPrice({ currency: 'GBP', price: landlordPaymentsTotal })}`,
      homemadePaymentsTotalLabel: `${getPrice({ currency: 'GBP', price: homemadePaymentsTotal })}`,
      outgoingTotal,
      outgoingTotalLabel: `${getPrice({ currency: 'GBP', price: outgoingTotal })}`,
      status,
      approvedByName: `${approvedByName}`,
      fundsTransferFailed: `${fundsTransferFailed}`,
      approvedByDate,
      createdAt,
      updatedAt,
      rentDueDate: rentDueDate ? getRentDueDate(rentDueDate) : '',
      editedOnFrontSide: false,
    },
  };
};

export const handleFinancesTotalPayments = ({ item, payload }: any): IFinancesListItem => {
  const { id, params } = payload;
  const { data } = item;
  const { landlordPaymentsTotal, homemadePaymentsTotal } = params;

  const updatedTotalPymentsDetails = {
    landlordPaymentsTotal,
    homemadePaymentsTotal,
    landlordPaymentsTotalLabel: `${getPrice({ currency: 'GBP', price: landlordPaymentsTotal })}`,
    homemadePaymentsTotalLabel: `${getPrice({ currency: 'GBP', price: homemadePaymentsTotal })}`,
    outgoingTotal: landlordPaymentsTotal + homemadePaymentsTotal,
    outgoingTotalLabel: `${getPrice({ currency: 'GBP', price: landlordPaymentsTotal + homemadePaymentsTotal })}`,
    editedOnFrontSide: true,
  };

  return {
    id: `${id}`,
    data: { ...data, ...updatedTotalPymentsDetails },
  };
};
