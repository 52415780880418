import React from 'react';
import format from 'date-fns/format';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Button, Input } from 'components';
import { handleListingTerminationDate } from 'store/actions/selectedListing';
import lockImg from 'images/lock.svg';
import styles from './ApproveEditTenancyTermination.module.scss';
import { IApproveEditTenancyTerminationProps } from './types';

const DATE_FORMAT = 'dd/MM/yyyy';

const ApproveEditTenancyTerminationComponent: React.FC<IApproveEditTenancyTerminationProps> = ({ toggle }) => {
  const dispatch = useAppDispatch();
  const { listingId, listingInfo, listingTerminationInfo } = useAppSelector((state) => state.selectedListing);
  const { tenancyStartDate, tenancyEndDate } = listingInfo;
  const { updatedListingTerminationEndDate } = listingTerminationInfo;

  const handleEditBankDetailsRequestCallback = (flag: boolean) => {
    if (listingId) {
      dispatch(
        handleListingTerminationDate({
          listingId,
          flag,
        })
      );
      toggle();
    }
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalSidePane}>
        <h2 className={styles.sideTitle}>Existing dates</h2>
        <p className={styles.sideItem}>
          <span className={styles.sideItemTitle}>Tenancy started</span>
          <span className={styles.sideItemContent}>
            {tenancyStartDate && format(new Date(tenancyStartDate), DATE_FORMAT)}
          </span>
        </p>
        <p className={styles.sideItem}>
          <span className={styles.sideItemTitle}>Tenancy end date</span>
          <span className={styles.sideItemContent}>
            {tenancyEndDate && format(new Date(tenancyEndDate), DATE_FORMAT)}
          </span>
        </p>
      </div>
      <div className={styles.modalContent}>
        <h2 className={styles.header}>Approval needed</h2>
        <p className={styles.description}>Please approve new tenancy end date</p>
        <div className={styles.container}>
          <span className={styles.startDateLabel}>Tenancy started</span>

          <div className={styles.startDateInput}>
            {tenancyStartDate && (
              <Input
                readOnly
                rightEnhancer={<img src={lockImg} alt="Locked" />}
                value={format(new Date(tenancyStartDate), DATE_FORMAT)}
                size="small"
              />
            )}
          </div>

          <span className={styles.endDateLabel}>New tenancy end date</span>

          <div className={styles.endDateInput}>
            {updatedListingTerminationEndDate && (
              <Input
                readOnly
                size="small"
                rightEnhancer={<img src={lockImg} alt="Locked" />}
                value={format(new Date(updatedListingTerminationEndDate), DATE_FORMAT)}
              />
            )}
          </div>
        </div>
        <div className={styles.controlSection}>
          <Button
            className={styles.controlButton}
            size="small"
            onClick={() => handleEditBankDetailsRequestCallback(true)}
          >
            Approve
          </Button>
          <Button
            className={styles.controlButton}
            size="small"
            onClick={() => handleEditBankDetailsRequestCallback(false)}
            theme="secondary"
          >
            Reject
          </Button>
        </div>
      </div>
    </div>
  );
};

export const ApproveEditTenancyTermination = React.memo(ApproveEditTenancyTerminationComponent);
