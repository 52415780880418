import React, { FC } from 'react';
import cx from 'clsx';

import { ReactComponent as ArrowIcon } from 'images/collapse-arrow-icon.svg';

import { IExpandCollapseButtonProps } from './types';

import styles from './ExpandCollapseButton.module.scss';

const ExpandCollapseButtonComponent: FC<IExpandCollapseButtonProps> = ({ isCollapsed, ...rest }): JSX.Element => {
  return (
    <button aria-label="colapse" className={cx(styles.expandCollapse, isCollapsed ? styles.isCollapsed : '')} {...rest}>
      <ArrowIcon />
    </button>
  );
};

export const ExpandCollapseButton = React.memo(ExpandCollapseButtonComponent);
