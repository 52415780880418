import React, { useEffect, useState } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

import { Button, CheckboxGroup, Pagination, SearchField, Select, Table } from 'components';
import { getUserList } from 'store/actions/userList';
import { ITableRowItemField, THeadItem } from 'components/elements/Table/types';
import PlusCircle from 'components/elements/icons/PlusCircle';
import { TableRow, TableRowCell } from 'components/elements/Table/internal';
import { useAppDispatch, useAppSelector, usePrevious, useUserInfo, useModal } from 'hooks';
import { handleUserFilters } from 'utils/helpers';
import { createUser } from 'store/api/user';
import { ICreateUserPayload } from 'types';
import { addNotificationItem } from 'store/actions/notifications';
import {
  defaultSortOption,
  defaultStatusFilterValues,
  defaultUserTypeFilterValues,
  statusFilterOptions,
  usersColumnsTitle,
  usersSortingOptions,
  usersSortingOptionsMap,
  userTypeFilterOptions,
} from 'constants/userList';

import { IMUTableRowItemFields } from './types';
import { EditUserCell } from './internal/EditUserCell';
import { AddUserModal } from './internal/AddUserModal';
import styles from './ManageUsersPage.module.scss';

const columnsWidth = ['2.5fr', '2.5fr', '2fr', '2fr', '1.5fr', '1fr'];

export const ManageUsersPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAddUserModalOpen, toggleUserModal] = useModal();
  const [selectedPage, setSelectedPage] = useState<number>(Number(searchParams.get('selectedPage')) || 1);
  const [search, setSearch] = useState<string>(searchParams.get('search') || '');
  const [sortingValue, setSortingValue] = useState<string>(searchParams.get('sortingValue') || defaultSortOption.value);
  const [editId, setEditId] = useState<string | null>(null);
  const { userListItems } = useAppSelector((state) => state.userList);
  const { totalItems, pageSize } = useAppSelector((state) => state.userList.usersPaginationConfig);
  const prevSelectedPage = usePrevious(selectedPage);
  const { role } = useUserInfo();

  const isAdmin = role === 'Admin';
  const initialRoleFilterValues = isAdmin ? defaultUserTypeFilterValues : ['Landlord'];

  const getInitialRoleFilter = () =>
    searchParams.getAll('roleFilterValues').length > 0 && isAdmin
      ? searchParams.getAll('roleFilterValues')
      : initialRoleFilterValues;

  const getInitialStatusFilter = () =>
    searchParams.getAll('statusFilterValues').length > 0
      ? searchParams.getAll('statusFilterValues')
      : defaultStatusFilterValues;

  const [roleFilterValues, setRoleFilterValues] = useState<string[]>(getInitialRoleFilter());
  const [statusFilterValues, setStatusFilterValues] = useState<string[]>(getInitialStatusFilter());

  const getUserListAction = ({ page }: Record<string, number>): void => {
    dispatch(
      getUserList({
        page,
        pageSize,
        search,
        filterByStatus: handleUserFilters(statusFilterValues),
        filterByRole: handleUserFilters(roleFilterValues),
        ...usersSortingOptionsMap[sortingValue as keyof typeof usersSortingOptionsMap],
      })
    );
  };

  const handleUserTypeFilterChange = (e: React.ChangeEvent<HTMLInputElement>, inputValue: string[]): void => {
    if (e.target.checked && e.target.value === 'all') {
      setRoleFilterValues(initialRoleFilterValues);
    } else if (!e.target.checked && e.target.value === 'all') {
      setRoleFilterValues([]);
    } else {
      setRoleFilterValues(inputValue.filter((el) => el !== 'all'));
    }
  };

  const handleStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>, inputValue: string[]): void => {
    if (e.target.checked && e.target.value === 'all') {
      setStatusFilterValues(defaultStatusFilterValues);
    } else if (!e.target.checked && e.target.value === 'all') {
      setStatusFilterValues([]);
    } else {
      setStatusFilterValues(inputValue.filter((el) => el !== 'all'));
    }
  };

  const handleAddUserSubmit = async (userData: ICreateUserPayload) => {
    createUser(userData)
      .then(() => {
        getUserListAction({ page: selectedPage });
        toggleUserModal();
      })
      .catch((e) => dispatch(addNotificationItem({ type: 'error', label: e.response.data.message })));
  };

  const handleSearchSubmit = () => {
    getUserListAction({ page: selectedPage });
    setSearchParams(
      createSearchParams({
        selectedPage: `${selectedPage}`,
        statusFilterValues,
        sortingValue,
        roleFilterValues,
        search,
      })
    );
  };

  useEffect(() => {
    setRoleFilterValues(getInitialRoleFilter());
  }, [role]);

  useEffect(() => {
    if (!prevSelectedPage && !searchParams.get('selectedPage')) {
      getUserListAction({ page: 1 });
    } else if (selectedPage === prevSelectedPage && selectedPage !== 1) {
      setSelectedPage(1);
    } else {
      getUserListAction({ page: selectedPage !== prevSelectedPage ? selectedPage : 1 });
    }

    if (role)
      setSearchParams(
        createSearchParams({
          selectedPage: `${selectedPage}`,
          statusFilterValues,
          sortingValue,
          roleFilterValues,
          search,
        })
      );
  }, [role, selectedPage, sortingValue, statusFilterValues, roleFilterValues]);

  return (
    <>
      <div className={styles.page}>
        {isAdmin && (
          <div className={styles.addUserButtonWrapper}>
            <Button fullWidth onClick={toggleUserModal} beforeIcon={<PlusCircle />} size="medium40">
              Add new user
            </Button>
          </div>
        )}
        <div className={styles.searchField}>
          <SearchField
            placeholder="Search for a user"
            value={search}
            onSubmit={handleSearchSubmit}
            onChange={(e, value) => setSearch(value)}
          />
        </div>
        <div className={styles.roleCheckboxGroup}>
          <CheckboxGroup
            disabled={!isAdmin}
            horizontal
            options={userTypeFilterOptions}
            onChange={handleUserTypeFilterChange}
            value={roleFilterValues}
          />
        </div>
        <div className={styles.controls}>
          <div className={styles.statusCheckboxWrapper}>
            <CheckboxGroup
              horizontal
              options={statusFilterOptions}
              onChange={handleStatusFilterChange}
              value={statusFilterValues}
            />
          </div>

          <Select
            className={styles.select}
            value={sortingValue}
            onChange={setSortingValue}
            options={usersSortingOptions}
          />

          <div className={styles.usersPagePagination}>
            <Pagination
              selectedPage={selectedPage}
              pageSize={pageSize}
              totalItems={totalItems}
              onPageChanged={(page: number) => setSelectedPage(page)}
            />
          </div>
        </div>

        <div className={styles.tableWrapper}>
          <Table
            THeadItems={usersColumnsTitle}
            TBodyItems={userListItems}
            TGridTemplate={columnsWidth}
            className={styles.userTable}
            renderBodyRow={(items: IMUTableRowItemFields) => {
              const isLandlordItem = items.values.find((item) => item.fieldName === 'role')?.value === 'Landlord';

              const editItem = (
                <EditUserCell
                  id={items.id}
                  key={items.id}
                  onClose={() => setEditId(null)}
                  values={items.values}
                  isLandlordItem={isLandlordItem}
                />
              );

              const rowItem = (
                <TableRow id={items.id} key={items.id}>
                  {usersColumnsTitle.map((headItem: THeadItem, index: number) => {
                    const cellItem = items.values.find(
                      (bodyItem: ITableRowItemField) => headItem.fieldName === bodyItem.fieldName
                    );

                    return (
                      cellItem && <TableRowCell {...cellItem} key={index} onClick={({ value }) => setEditId(value)} />
                    );
                  })}
                </TableRow>
              );

              return editId === items.id ? editItem : rowItem;
            }}
          />
        </div>
      </div>
      {isAddUserModalOpen && (
        <AddUserModal isOpen={isAddUserModalOpen} toggle={toggleUserModal} onSubmit={handleAddUserSubmit} />
      )}
    </>
  );
};
