import { store, AppDispatch } from 'store';
import { notificationsSlice } from 'store/reducers/NotificationsSlice';
import { IAddNotificationItemPayload, IRemoveNotificationPayload } from 'types';
import { handleNotificationList } from 'store/models/notifications';

export const addNotificationItem = (params: IAddNotificationItemPayload) => (dispatch: AppDispatch) => {
  try {
    const { notificationList } = store.getState().notifications;

    dispatch(notificationsSlice.actions.setNotificationList(handleNotificationList(params, notificationList)));
  } catch (e) {
    console.error(e);
  }
};

export const removeNotificationItem =
  ({ notificationId }: IRemoveNotificationPayload) =>
  (dispatch: AppDispatch) => {
    try {
      const { notificationList } = store.getState().notifications;
      const filteredNotificationList = notificationList.filter((item) => item.notificationId !== notificationId);

      dispatch(notificationsSlice.actions.setNotificationList(filteredNotificationList));
    } catch (e) {
      console.error(e);
    }
  };
