import React from 'react';

import { IControlProps } from './types';
import { HiddenInput } from '../HiddenInput';

export interface IControlInputContainerProps extends IControlProps {
  type: 'checkbox' | 'radio';
  className: string;
  /** Before these elements there will be a native input, from which we need to take styles for the active state (:checked) */
  children: React.ReactNode;
}

export const ControlInputContainer = React.forwardRef(function ControlInputContainer(
  props: IControlInputContainerProps,
  ref: React.Ref<HTMLLabelElement>
) {
  const {
    type,
    checked,
    defaultChecked,
    disabled,
    onChange,
    inputRef,
    tabIndex,
    name,
    id,
    autoFocus,
    children,
    value,
    role,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    label,
    ...rest
  } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, e.currentTarget.checked);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label {...rest} ref={ref}>
      <HiddenInput
        {...(value && { value })}
        type={type}
        ref={inputRef}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={handleChange}
        tabIndex={tabIndex}
        name={name}
        id={id}
        autoFocus={autoFocus}
        role={role}
      />
      {children}
    </label>
  );
});
