import { mainRoutesMap } from 'constants/routes';
import systemStatusIcon from 'images/systemStatusIcon.svg';
import systemStatusDisabledIcon from 'images/systemStatusIcon.svg';
import propertiesIcon from './assets/house-icon.svg';
import propertiesDisabledIcon from './assets/house-disabled-icon.svg';
import tenanciesHistoryIcon from './assets/tenancies-history-icon.svg';
import tenanciesHistoryDisabledIcon from './assets/tenancies-history-disabled-icon.svg';
import dashboardIcon from './assets/dashboard-icon.svg';
import dashboardDisabledIcon from './assets/dashboard-disabled-icon.svg';
import keysIcon from './assets/keys-icon.svg';
import keysDisabledIcon from './assets/keys-disabled-icon.svg';
import rentalIncomeIcon from './assets/rental-income-icon.svg';
import rentalIncomeDisabledIcon from './assets/rental-income-disabled-icon.svg';
import documentsIcon from './assets/documents-icon.svg';
import documentsDisabledIcon from './assets/documents-disabled-icon.svg';
import manageUsersIcon from './assets/manage-users-icon.svg';
import manageUsersDisabledIcon from './assets/manage-users-disabled-icon.svg';
import manageFinancesIcon from './assets/manage-finances-icon.svg';
import manageFinancesDisabledIcon from './assets/manage-finances-disabled-icon.svg';

export const sidebarItemsMap = [
  {
    label: 'My properties',
    to: mainRoutesMap.properties.path,
    icon: propertiesIcon,
    disabledIcon: propertiesDisabledIcon,
  },
  {
    label: 'Tenancies history',
    to: mainRoutesMap.tenancies.path,
    icon: tenanciesHistoryIcon,
    disabledIcon: tenanciesHistoryDisabledIcon,
  },
  {
    label: 'Advertising dashboard',
    to: mainRoutesMap.dashboard.path,
    icon: dashboardIcon,
    disabledIcon: dashboardDisabledIcon,
  },
  {
    label: 'Progressing tenants',
    to: mainRoutesMap.tenants.path,
    icon: keysIcon,
    disabledIcon: keysDisabledIcon,
  },
  {
    label: 'Property wallet',
    to: mainRoutesMap.income.path,
    icon: rentalIncomeIcon,
    disabledIcon: rentalIncomeDisabledIcon,
  },
  {
    label: 'Documents',
    to: mainRoutesMap.documents.path,
    icon: documentsIcon,
    disabledIcon: documentsDisabledIcon,
  },
  {
    label: 'Manage users',
    to: mainRoutesMap.users.path,
    icon: manageUsersIcon,
    disabledIcon: manageUsersDisabledIcon,
    allowedForRoles: mainRoutesMap.users.allowedForRoles,
  },
  {
    label: 'Manage finance',
    to: mainRoutesMap.finances.path,
    icon: manageFinancesIcon,
    disabledIcon: manageFinancesDisabledIcon,
    allowedForRoles: mainRoutesMap.finances.allowedForRoles,
  },
  {
    label: 'System status',
    to: mainRoutesMap.status.path,
    icon: systemStatusIcon,
    disabledIcon: systemStatusDisabledIcon,
    allowedForRoles: mainRoutesMap.status.allowedForRoles,
  },
];
