import React from 'react';

import successIcon from 'images/successStatusIcon.svg';
import errorIcon from 'images/errorStatusIcon.svg';

import { IAuditEventRowProps } from '../../types';

import styles from './AuditEventRow.module.scss';

const statusIcon = {
  OK: successIcon,
  ERROR: errorIcon,
};

const statusIconLabel = {
  OK: 'Ok',
  ERROR: 'Error',
};

const AuditEventRowComponent: React.FC<IAuditEventRowProps> = ({ name, status, details }): JSX.Element => {
  const renderEventDetail = (detail: string) => {
    return <span className={styles.detailsItem}>{detail}</span>;
  };

  return (
    <div className={styles.auditLogRowContainer}>
      <div className={styles.iconContainer}>
        <img src={statusIcon[status]} alt={statusIconLabel[status]} />
      </div>
      <div>
        <span className={styles.eventName}>{name}</span>
      </div>
      <div className={styles.details}>{details.map((detail) => renderEventDetail(detail))}</div>
    </div>
  );
};

export const AuditEventRow = React.memo(AuditEventRowComponent);
