import qs from 'qs';

import { axiosPrivate, handleErrorResponse } from 'utils/axios';
import {
  IGetListingListPayload,
  IListingsListResponse,
  IPropertyByListingIdResponse,
  IListingResponse,
  IUpdateListingByListingIdPayload,
  IViewsByListingIdResponse,
  IListingUpdateRentManagmentPayload,
  IRequestUpdateTerminationDatePayload,
  IListingTerminationInfoPayload,
  ITenancyTerminationInfoResponse,
  IPropertyResponse,
  IGetListingDetailsPayload,
  IGetListingDataBySalesforceListingIdPayload,
  IGetPropertyDetailsBySalesforcePropertyIdPayload,
  ISuggestionsPayload,
} from 'types';

export const getListingsListCall = async (params: IGetListingListPayload) => {
  return axiosPrivate
    .get<IListingsListResponse>('property/v1/listings', {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((response) => response.data);
};

export const getListingDetailsCall = async ({ listingId }: IGetListingDetailsPayload) => {
  return axiosPrivate
    .get<IListingResponse>(`property/v1/listings/${listingId}`)
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));
};

export const getListingDetailsBySalesforceListingIdCall = async ({
  salesforceListingId,
}: IGetListingDataBySalesforceListingIdPayload) => {
  return axiosPrivate
    .get<IPropertyByListingIdResponse>(`property/v1/listings/by-listing-id/${salesforceListingId}`)
    .then((response) => response.data);
};

export const getPropertyDetailsBySalesforcePropertyIdCall = async ({
  salesforcePropertyId,
  skipDocumentSync,
}: IGetPropertyDetailsBySalesforcePropertyIdPayload) => {
  return axiosPrivate
    .get<IPropertyResponse>(
      `property/v1/properties/by-salesforce-id/${salesforcePropertyId}?skipDocumentSync=${skipDocumentSync}`
    )
    .then((response) => response.data);
};

export const getListingViewsBySalesforceListingIdCall = async ({
  salesforceListingId,
}: IGetListingDataBySalesforceListingIdPayload) => {
  return axiosPrivate
    .get<IViewsByListingIdResponse[]>(`analytics/v1/views/${salesforceListingId}`)
    .then((response) => response.data);
};

export const updateListingByListingIdCall = async ({ listingId, payload }: IUpdateListingByListingIdPayload) => {
  return axiosPrivate
    .patch<IListingResponse>(`property/v1/listings/${listingId}`, payload)
    .then((response) => response.data);
};

export const updateListingRentManagmentCall = async ({
  listingId,
  rentManagement,
}: IListingUpdateRentManagmentPayload) => {
  return axiosPrivate
    .post<IListingResponse>(`property/v1/listings/${listingId}/change-rent-management`, { rentManagement })
    .then((response) => response.data);
};

export const requestUpdateListingTerminationDateCall = async ({
  listingId,
  date,
}: IRequestUpdateTerminationDatePayload) => {
  return axiosPrivate
    .post<IListingResponse>(`property/v1/listings/${listingId}/termination-date/request`, { date })
    .then((response) => response.data);
};

export const getListingTerminationInfoCall = async ({ listingId }: IListingTerminationInfoPayload) => {
  return axiosPrivate
    .get<ITenancyTerminationInfoResponse>(`property/v1/listings/${listingId}/termination-date`)
    .then((response) => response.data);
};

export const approveListingTerminationDateCall = async ({ listingId }: IListingTerminationInfoPayload) => {
  return axiosPrivate
    .post<ITenancyTerminationInfoResponse>(`property/v1/listings/${listingId}/termination-date/approve`)
    .then((response) => response.data);
};

export const rejectListingTerminationDateCall = async ({ listingId }: IListingTerminationInfoPayload) => {
  return axiosPrivate
    .post<ITenancyTerminationInfoResponse>(`property/v1/listings/${listingId}/termination-date/reject`)
    .then((response) => response.data);
};

export const getListingSuggestionsCall = async (params: ISuggestionsPayload) =>
  axiosPrivate
    .get<IListingResponse[]>('property/v1/listings/suggestions', { params })
    .then((response) => response.data);

export const getPropertySuggestionsCall = async (params: ISuggestionsPayload) =>
  axiosPrivate
    .get<IPropertyResponse[]>('property/v1/properties/suggestions', { params })
    .then((response) => response.data);
