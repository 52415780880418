import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, FormField, Input, Modal, Select } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { addUserSchema } from 'utils/schemas';
import { ICreateUserPayload } from 'types';
import { roleMap } from 'constants/userList';
import styles from './AddUserModal.module.scss';

export interface IAddUserModal {
  isOpen: boolean;
  toggle(): void;
  onSubmit(values: ICreateUserPayload): void;
}

const roleKeys = Object.keys(roleMap).filter((key) => key !== 'Landlord') as Array<keyof typeof roleMap>;

const options = roleKeys.map((roleKey) => ({
  value: roleKey,
  label: roleMap[roleKey],
}));

export const AddUserModal: React.FC<IAddUserModal> = ({ isOpen, toggle, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm<ICreateUserPayload>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(addUserSchema),
  });

  const addUserHandler = (values: ICreateUserPayload): void => onSubmit(values);

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal} shouldCloseOnOverlayClick={false}>
      <h2 className={styles.header}>Add new user</h2>

      <form autoComplete="off" onSubmit={handleSubmit(addUserHandler)}>
        <FormField labelId="role" labelText="User type" fieldError={errors.role as TFieldError}>
          <Select
            className={styles.select}
            placeholder="Select user type"
            id="role"
            options={options}
            value={getValues().role}
            {...register('role')}
            onChange={(_, e) => {
              register('role').onChange(e);
              trigger('role');
            }}
          />
        </FormField>

        <FormField labelId="fullName" labelText="Full name" fieldError={errors.fullName as TFieldError}>
          <Input placeholder="Full name" id="firstName" {...register('fullName')} />
        </FormField>

        <FormField labelId="phoneNumber" labelText="Phone number" fieldError={errors.phoneNumber as TFieldError}>
          <Input placeholder="Phone number" id="lastName" {...register('phoneNumber')} />
        </FormField>

        <FormField labelId="email" labelText="Email address" fieldError={errors.email as TFieldError}>
          <Input placeholder="Email address" id="email" {...register('email')} />
        </FormField>

        <div className={styles.controlSection}>
          <Button type="submit" fullWidth size="small" disabled={!isValid}>
            Add new user
          </Button>
        </div>
      </form>
    </Modal>
  );
};
