import * as yup from 'yup';

export const signUpSchema = yup.object().shape({
  email: yup.string().required('This field is required').email('Email must be a valid email'),
  password: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match'),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  terms: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
});
