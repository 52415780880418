export const notificationMessagesMap = {
  defaultError: {
    label: 'Something went wrong',
    type: 'error',
  },
  mfaCodeValidationFailed: {
    label: 'Sorry, code validation failed - please try again.',
    type: 'error',
  },
  mfaCodeSent: {
    label: 'A validation code was sent to your phone',
    type: 'success',
  },
  resetPasswordCodeSent: {
    label: 'Your reset password was sent to your email with further instructions',
    type: 'success',
  },
  reInviteUserEmailSent: {
    label: 'The email was sent successfully',
    type: 'success',
  },
};
