import React from 'react';
import { useInput } from 'react-day-picker';
import { useForm } from 'react-hook-form';
import format from 'date-fns/format';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal, Button, FormField, Input, TextArea, Datepicker } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { handleInvoiceDetailsSchema } from 'utils/schemas';
import { useAppDispatch, useAppSelector, useModal } from 'hooks';
import { handleUpcomingPayments, getTransactionInfoByWalletId } from 'store/actions/transactions';
import { IHandleInvoiceDetailsModalProps, FormValues } from './types';
import styles from './HandleInvoiceDetailsModal.module.scss';

const DATE_FORMAT = 'yyyy-MM-dd';

export const HandleInvoiceDetailsModalComponent: React.FC<IHandleInvoiceDetailsModalProps> = ({
  isOpen,
  toggle,
  type,
  options,
}) => {
  const dispatch = useAppDispatch();
  const { walletId } = useAppSelector((state) => state.wallet.walletInfo);
  const { transactionPaginationConfig, transactionDateRangeConfig } = useAppSelector((state) => state.transactions);
  const { pageSize, selectedPage } = transactionPaginationConfig;
  const { dateFrom, dateTo } = transactionDateRangeConfig;
  const [showDatePicker, setShowDatePicker] = useModal();
  const defaultDateValue = type === 'edit' ? options.date : format(new Date(), DATE_FORMAT);
  const defaultValues =
    type === 'edit'
      ? Object.assign(options, { date: format(new Date(options.date), DATE_FORMAT) })
      : { date: defaultDateValue };

  const { inputProps, dayPickerProps } = useInput({
    defaultSelected: new Date(defaultDateValue),
    required: true,
    format: DATE_FORMAT,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
    shouldFocusError: true,
    resolver: yupResolver(handleInvoiceDetailsSchema),
  });

  const getTransactionByWalletIdAction = (page: number): void => {
    if (walletId) {
      dispatch(
        getTransactionInfoByWalletId({
          walletId,
          page,
          pageSize,
          dateTo,
          dateFrom,
        })
      );
    }
  };

  const handleEditInvoiceDetails = async ({ amount, authorName, date, serviceDescription, invoiceNumber }: any) => {
    await dispatch(
      handleUpcomingPayments({
        payload: {
          paymentId: options.id,
          walletId,
          amount,
          authorName,
          date: format(dayPickerProps.selected as Date, DATE_FORMAT),
          serviceDescription,
          invoiceNumber,
        },
        type: 'edit',
      })
    );
    getTransactionByWalletIdAction(1);
    toggle();
  };

  const handleAddInvoiceDetails = async ({ amount, authorName, date, serviceDescription, invoiceNumber }: any) => {
    await dispatch(
      handleUpcomingPayments({
        payload: { walletId, amount, authorName, date, serviceDescription, invoiceNumber },
        type: 'add',
      })
    );
    getTransactionByWalletIdAction(selectedPage);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>{type === 'edit' ? 'Edit invoice details' : 'Add new invoice'}</h2>

      <form
        autoComplete="off"
        onSubmit={handleSubmit(type === 'edit' ? handleEditInvoiceDetails : handleAddInvoiceDetails)}
      >
        <div className={styles.formContainer}>
          <div className={styles.leftSide}>
            <FormField labelId="authorName" labelText="Contractor name" fieldError={errors.authorName as TFieldError}>
              <Input size="small" id="authorName" {...register('authorName')} />
            </FormField>
            <FormField
              labelId="serviceDescription"
              labelText="Description of service"
              fieldError={errors.serviceDescription as TFieldError}
            >
              <TextArea rows={4} id="serviceDescription" {...register('serviceDescription')} />
            </FormField>
          </div>
          <div className={styles.rightSide}>
            <FormField
              labelId="invoiceNumber"
              labelText="Invoice Number"
              fieldError={errors.invoiceNumber as TFieldError}
            >
              <Input size="small" id="invoiceNumber" type="text" {...register('invoiceNumber')} />
            </FormField>
            <FormField labelId="amount" labelText="Invoice total" fieldError={errors.amount as TFieldError}>
              <Input size="small" id="amount" {...register('amount')} />
            </FormField>
            <FormField labelId="date" labelText="Date of service" fieldError={errors.date as TFieldError}>
              <Input
                id="date"
                rightEnhancer={
                  <button type="button" className={styles.editButton} onClick={setShowDatePicker}>
                    Edit
                  </button>
                }
                readOnly
                size="small"
                {...inputProps}
                {...register('date')}
              />
            </FormField>

            <div className={styles.controlSection}>
              <Button fullWidth size="small" type="submit">
                {type === 'edit' ? 'Save new invoice details' : 'Add invoice'}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <Modal isOpen={showDatePicker} onRequestClose={setShowDatePicker}>
        <Datepicker
          mode="single"
          className={styles.datePicker}
          {...dayPickerProps}
          selected={dayPickerProps.selected as Date | undefined}
        />
      </Modal>
    </Modal>
  );
};

export const HandleInvoiceDetailsModal = React.memo(HandleInvoiceDetailsModalComponent);
