import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal, Button, FormField, Input } from 'components';
import { roleMap } from 'constants/userList';
import { TFieldError } from 'components/elements/FormField/types';
import { editUserAccountSchema } from 'utils/schemas';
import { updateUserAccount } from 'store/actions/userData';
import { useAppDispatch, useAppSelector, useUserInfo } from 'hooks';
import { getUserRoleKey } from 'utils/helpers';
import lockImg from 'images/lock.svg';

import { IEditUserModalProps, FormValues } from './types';
import styles from './EditUserAccountModal.module.scss';

export const EditUserAccountModalComponent: React.FC<IEditUserModalProps> = ({ isOpen, toggle }) => {
  const dispatch = useAppDispatch();
  const { id, role } = useUserInfo();
  const { userInfo } = useAppSelector((state) => state.auth);

  const defaultValues = {
    fullName: userInfo?.fullName,
    role: roleMap[userInfo?.role as keyof typeof roleMap],
    phoneNumber: userInfo?.phoneNumber,
    email: userInfo?.email,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(editUserAccountSchema),
    defaultValues,
  });

  const handleUpdateUserAccount = (data: FormValues) => {
    dispatch(updateUserAccount({ userId: id, payload: { ...data, role: getUserRoleKey(data.role) } })).then(toggle);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Edit account details</h2>

      <form autoComplete="off" onSubmit={handleSubmit(handleUpdateUserAccount)}>
        {/* <div className={cx(styles.errorMessageContainer, errorMessage !== '' && styles.errorMessageContainerVisible)}>
          <span>{errorMessage}</span>
        </div> */}
        <FormField labelId="fullName" labelText="Full name" fieldError={errors.fullName as TFieldError}>
          <Input placeholder="Type full name" id="fullName" {...register('fullName')} />
        </FormField>
        <FormField
          labelId="role"
          labelText="User type"
          fieldError={errors.role as TFieldError}
          className={styles.formField}
        >
          <Input
            id="role"
            {...register('role')}
            readOnly
            rightEnhancer={<img src={lockImg} alt="Locked" />}
            placeholder="Type your role"
          />
        </FormField>
        <FormField labelId="phoneNumber" labelText="Phone number" fieldError={errors.phoneNumber as TFieldError}>
          <Input placeholder="Type your phone number" id="phoneNumber" {...register('phoneNumber')} />
        </FormField>
        <FormField labelId="email" labelText="Email address" fieldError={errors.email as TFieldError}>
          <Input
            placeholder="Type your email address"
            id="email"
            {...register('email')}
            readOnly={!role || role === 'Landlord'}
            rightEnhancer={role === 'Landlord' && <img src={lockImg} alt="Locked" />}
          />
        </FormField>

        <div className={styles.controlSection}>
          <Button fullWidth size="medium40" type="submit">
            Save changes
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const EditUserAccountModal = React.memo(EditUserAccountModalComponent);
