import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal, Button, FormField, Input, TextArea } from 'components';
import { getWalletPrice } from 'utils/helpers';
import { TFieldError } from 'components/elements/FormField/types';
import { getAdjustRentSchema } from 'utils/schemas';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateAdjustNextMonthsRent, getWalletInfoByListingId } from 'store/actions/wallet';
import styles from './AdjustRentModal.module.scss';

export interface IAdjustRentModalProps {
  isOpen: boolean;
  toggle(): void;
  options: any;
}

type FormValues = {
  amount: number;
  description: string;
};

export const AdjustRentModalComponent: React.FC<IAdjustRentModalProps> = ({ isOpen, toggle, options }) => {
  const { listingId } = useAppSelector((state) => state.selectedListing);
  const { monthlyTenancy } = options;
  const [adjustValue, setAdjustValue] = useState(monthlyTenancy.nextMonthlyTenancyRate);
  const dispatch = useAppDispatch();

  const defaultValues = {
    amount: monthlyTenancy.nextMonthlyTenancyRate,
    description: monthlyTenancy.lastMonthlyRateAdjustmentReason,
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues,
    resolver: yupResolver(getAdjustRentSchema({ max: monthlyTenancy.monthlyTenancyRate })),
  });

  useEffect(() => {
    const subscription = watch(({ amount }, { name, type }) => name === 'amount' && amount && setAdjustValue(amount));
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleAdjustRent = async ({ description, amount }: any) => {
    if (listingId) {
      await dispatch(updateAdjustNextMonthsRent({ walletId: options.walletId, amount, reason: description }));
      dispatch(getWalletInfoByListingId({ listingId }));
      toggle();
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Adjust next month’s rent</h2>

      <p className={styles.currentAmount}>
        <span className={styles.currentAmountHeader}>Monthly rent</span>
        <span className={styles.currentAmountCount}>{monthlyTenancy.monthlyTenancyRateLabel}</span>
      </p>

      <form autoComplete="off" onSubmit={handleSubmit(handleAdjustRent)}>
        <FormField labelId="amount" labelText="Adjustment amount" fieldError={errors.amount as TFieldError}>
          <Input size="small" type="number" id="amount" {...register('amount')} />
        </FormField>

        <FormField
          labelId="description"
          labelText="Reason for adjustment"
          fieldError={errors.description as TFieldError}
        >
          <TextArea rows={4} id="description" {...register('description')} />
        </FormField>

        <p className={styles.updatedAmount}>
          <span className={styles.updatedAmountHeader}>Next month’s rent</span>
          <span className={styles.updatedAmountCount}>
            {getWalletPrice(monthlyTenancy.monthlyTenancyRate - adjustValue)}
          </span>
        </p>

        <div className={styles.controlSection}>
          <Button fullWidth size="small" type="submit">
            Save rent adjustment
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export const AdjustRentModal = React.memo(AdjustRentModalComponent);
