import React from 'react';

export interface ICheckIconProps {
  color: string;
  width?: number;
  height?: number;
}

export const CheckIcon = React.forwardRef((props: ICheckIconProps, ref: React.Ref<SVGSVGElement>) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 14.627 10.526"
  >
    <path
      id="Icon_feather-check"
      data-name="Icon feather-check"
      d="M17.8,9,9.687,17.112,6,13.425"
      transform="translate(-4.586 -7.586)"
      fill="none"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
));

CheckIcon.defaultProps = {
  width: 14.627,
  height: 10.526,
};
