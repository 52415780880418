import * as yup from 'yup';

export interface IAdjustRentSchemaPayload {
  max: number;
}

export const getAdjustRentSchema = ({ max }: IAdjustRentSchemaPayload) => {
  return yup.object().shape({
    amount: yup
      .number()
      .min(0, `Adjusted amount can't be less than 0`)
      .max(max, `Adjusted amount can't exceed the monthly rent`)
      .typeError('This field is required')
      .required('This field is required'),
    description: yup.string().typeError('This field is required').required('This field is required'),
  });
};
