import React from 'react';

import { NotificationList, LoaderBar, AppModal } from 'components';
import { Router } from './router';

const App = () => (
  <div className="app">
    <LoaderBar />
    <NotificationList />
    <AppModal />
    <Router />
  </div>
);

export default App;
