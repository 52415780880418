import React from 'react';

import { useModal, useUserInfo } from 'hooks';
import { ReactComponent as TrashIcon } from 'images/trash-icon.svg';
import { HandleInvoiceDetailsModal } from '../HandleInvoiceDetailsModal';
import { DeleteInvoiceModal } from '../DeleteInvoiceModal';
import { ITransactionTableItemControlsProps } from './types';
import styles from './TransactionTable.module.scss';

const TransactionTableItemControlsComponent: React.FC<ITransactionTableItemControlsProps> = ({
  id,
  date,
  amount,
  amountLabel,
  authorName,
  reference,
  invoiceNumber,
  serviceDescription,
}): JSX.Element => {
  const [isInvoiceDetailsModalOpen, toggleInvoiceDetailsModal] = useModal();
  const [isDeleteInvoiceModalOpen, toggleDeleteInvoiceModal] = useModal();
  const { role } = useUserInfo();

  return (
    <>
      {role === 'Admin' || role === 'Controller' ? (
        <>
          <button className={styles.editContentControl} onClick={toggleInvoiceDetailsModal}>
            Edit
          </button>{' '}
          <button className={styles.deleteContentControl} onClick={toggleDeleteInvoiceModal}>
            <TrashIcon />
          </button>
        </>
      ) : null}

      {isInvoiceDetailsModalOpen && (
        <HandleInvoiceDetailsModal
          isOpen={isInvoiceDetailsModalOpen}
          toggle={toggleInvoiceDetailsModal}
          type="edit"
          options={{ id, date, amount, amountLabel, authorName, reference, invoiceNumber, serviceDescription }}
        />
      )}
      <DeleteInvoiceModal isOpen={isDeleteInvoiceModalOpen} toggle={toggleDeleteInvoiceModal} options={{ id }} />
    </>
  );
};

export const TransactionTableItemControls = React.memo(TransactionTableItemControlsComponent);
