import { axiosPrivate, handleErrorResponse } from 'utils/axios';
import {
  IGetTransactionsByWalletIdResponse,
  IGetTransactionInfoPayload,
  IAddUpcomingPaymentsPayload,
  IUpdateUpcomingPaymentsPayload,
  IDeleteUpcomingPaymentsPayload,
} from 'types';

export const getTransactionsByWalletIdCall = async ({
  walletId,
  page,
  pageSize,
  dateFrom,
  dateTo,
}: IGetTransactionInfoPayload) =>
  axiosPrivate
    .get<IGetTransactionsByWalletIdResponse>(`finance/v1/wallet/${walletId}/payments`, {
      params: {
        page,
        pageSize,
        dateFrom,
        dateTo,
      },
    })
    .then((response) => response.data)
    .catch((e) => e);

export const addUpcomingPaymentsCall = async ({
  walletId,
  authorName,
  amount,
  serviceDescription,
  date,
  invoiceNumber,
}: IAddUpcomingPaymentsPayload) =>
  axiosPrivate
    .put(`finance/v1/upcoming-payments`, {
      walletId,
      serviceDate: date,
      invoiceTotal: amount,
      invoiceNumber,
      contractorName: authorName,
      serviceDescription,
    })
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const updateUpcomingPaymentsCall = async ({
  walletId,
  authorName,
  amount,
  serviceDescription,
  date,
  invoiceNumber,
  paymentId,
}: IUpdateUpcomingPaymentsPayload) =>
  axiosPrivate
    .patch<IGetTransactionsByWalletIdResponse>(`finance/v1/upcoming-payments/${paymentId}`, {
      walletId,
      serviceDate: date,
      invoiceTotal: amount,
      invoiceNumber,
      contractorName: authorName,
      serviceDescription,
    })
    .then((response) => response.data)
    .catch((e) => handleErrorResponse(e));

export const deleteUpcomingPaymentsCall = async ({ paymentId }: IDeleteUpcomingPaymentsPayload) =>
  axiosPrivate
    .delete(`finance/v1/upcoming-payments/${paymentId}`)
    .then((response) => response.data)
    .catch((e) => e);
