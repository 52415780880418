import { AppDispatch } from 'store';
import { addNotificationItem } from 'store/actions/notifications';
import { analyticsSlice } from 'store/reducers/AnalyticsSlice';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import {
  getAuditLogGroupListCall,
  syncCallMap,
  postSyncLandlordContact,
  postSyncProperties,
  postSyncListingViewRecords,
  postSyncRegularPayments,
  postSyncMarketingStatistics,
  postSyncUpcomingPayments,
  postSyncRailsbank,
} from 'store/api/analytics';
import { handleAuditLogGroupList } from 'store/models/analytics';
import { TAuditType } from 'types';

export const getAuditLogGroupList = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    const auditLogGroupList = await getAuditLogGroupListCall();
    const handledAuditLogGroupList = handleAuditLogGroupList(auditLogGroupList);

    dispatch(analyticsSlice.actions.setAuditLogList(handledAuditLogGroupList));
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const syncAuditLogItem = (type: TAuditType) => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());
    await syncCallMap[type]();
    dispatch(getAuditLogGroupList());
    dispatch(addNotificationItem({ type: 'success', label: 'Data has been successfully synced' }));
  } catch (error) {
    if (error !== '') {
      dispatch(addNotificationItem({ type: 'error', label: (error as Error)?.message }));
    } else {
      dispatch(addNotificationItem({ key: 'defaultError' }));
    }
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};

export const syncAllAuditLog = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(loaderSlice.actions.increaseLoader());

    //We need to keep this order according to salesforce and backend requirements
    await postSyncLandlordContact();
    await postSyncProperties();
    await postSyncListingViewRecords();
    await postSyncRegularPayments();
    await postSyncMarketingStatistics();
    await postSyncUpcomingPayments();
    await postSyncRailsbank();

    dispatch(getAuditLogGroupList());
  } catch (e) {
    dispatch(addNotificationItem({ key: 'defaultError' }));
  } finally {
    dispatch(loaderSlice.actions.decreaseLoader());
  }
};
