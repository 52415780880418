type ProvidedStyles = object;

interface IInternalComponentState {
  isSelected?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
}

const colors = {
  bgColorSelected: 'var(--color-38b7a1)',
  bgColorActive: 'var(--color-1ed9b8)',
  bgColor: 'var(--color-d8f1ed)',
  textColor: 'var(--color-00000099)',
  textColorSelected: 'hsl(0, 0%, 95%)',
};

export const customStyles = {
  option: (provided: ProvidedStyles, { isSelected, isFocused }: IInternalComponentState) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid var(--color-cccccc)',
    height: 60,
    backgroundColor: isSelected ? colors.bgColorSelected : isFocused ? colors.bgColor : 'transparent',
    color: isSelected ? colors.textColorSelected : colors.textColor,
    padding: '8px 24px 8px 16px',
    ':active': {
      backgroundColor: isSelected ? colors.bgColorSelected : colors.bgColorActive,
      color: isSelected ? colors.textColorSelected : isFocused ? colors.textColorSelected : colors.textColor,
    },
  }),
  control: (provided: ProvidedStyles) => ({
    ...provided,
    height: 50,
    boxShadow: 'none',
    flexWrap: 'nowrap' as const,
    borderLeft: `1px solid ${colors.bgColorSelected}`,
    borderRight: `1px solid ${colors.bgColorSelected}`,
    borderBottom: `1px solid ${colors.bgColorSelected}`,
    borderTop: `1px solid ${colors.bgColorSelected}`,
    '&:hover': {
      borderLeft: `1px solid ${colors.bgColorSelected}`,
      borderRight: `1px solid ${colors.bgColorSelected}`,
      borderBottom: `1px solid ${colors.bgColorSelected}`,
      borderTop: `1px solid ${colors.bgColorSelected}`,
    },
  }),
  valueContainer: (provided: ProvidedStyles) => ({ ...provided, padding: '8px 24px 8px 16px' }),
  singleValue: (provided: ProvidedStyles, { isSelected }: IInternalComponentState) => ({
    ...provided,
    margin: '0 0 0 2px',
    color: isSelected ? colors.textColorSelected : colors.textColor,
  }),
  input: (provided: ProvidedStyles) => ({
    ...provided,
    color: `${colors.textColor}`,
    fontFamily: 'var(--font-family-catamaran)',
    fontStyle: 'var(--font-style-normal)',
    fontWeight: 'var(--font-weight-300)',
    fontVariant: 'normal',
    fontSize: 'var(--font-size-16)',
    lineHeight: 'var(--line-spacing-22)',
  }),
  loadingMessage: (provided: ProvidedStyles) => ({ ...provided, color: colors.textColor }),
  menu: (provided: ProvidedStyles) => ({
    ...provided,
    margin: 0,
    borderRadius: '0px 0px 5px 5px',
    borderLeft: `1px solid ${colors.bgColorSelected}`,
    borderRight: `1px solid ${colors.bgColorSelected}`,
    borderBottom: `1px solid ${colors.bgColorSelected}`,
  }),
  menuList: (provided: ProvidedStyles) => ({ ...provided, paddingTop: 0, paddingBottom: 0 }),
  noOptionsMessage: (provided: ProvidedStyles) => ({ ...provided, color: colors.textColor }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: () => ({ display: 'none' }),
};
