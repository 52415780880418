import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useUserInfo } from 'hooks';
import { viewConfigSlice } from 'store/reducers/ViewConfigSlice';
import { SidebarInfo } from 'components/elements/SidebarInfo';
import hmMainLogo from 'images/hm-main-logo.svg';
import { NavigationPane } from '..';
import { INavigationPaneItem } from '../NavigationPane/types';

import { handleSideBarRoles } from './internal/utils';

import styles from './Sidebar.module.scss';

const SidebarComponent: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [sideBarItems, setSideBarItems] = useState<INavigationPaneItem[]>([]);
  const { disabledSidebarItems } = useAppSelector((state) => state.viewConfig);
  const { listingId } = useAppSelector((state) => state.selectedListing);
  const { role } = useUserInfo();

  useEffect(() => {
    if (listingId && disabledSidebarItems.length > 0) {
      dispatch(viewConfigSlice.actions.toggleDisabledSidebarItems(true));
    }
    if (!listingId && disabledSidebarItems.length === 0) {
      dispatch(viewConfigSlice.actions.toggleDisabledSidebarItems(false));
    }
  }, [listingId]);

  useEffect(() => {
    setSideBarItems(handleSideBarRoles(role));
  }, [role]);

  return (
    <aside className={styles.sidebar}>
      <div className={styles.sidebarLogoContainer}>
        <Link to="/main/properties">
          <img className={styles.sidebarLogo} src={hmMainLogo} alt="Home Made Logo" />
        </Link>
      </div>

      <NavigationPane items={sideBarItems} disabledItems={disabledSidebarItems} />
      <SidebarInfo />
    </aside>
  );
};

export const Sidebar = React.memo(SidebarComponent);
