export const getInfoPaneMap = (role: string): { title: string; key: string; acceptableRoles: string[] }[] => [
  { title: 'Rent due date', key: 'rentDueDate', acceptableRoles: ['Landlord', 'Agent', 'Controller', 'Admin'] },
  {
    title: 'Total tenancy rent',
    key: 'totalTenancyRate',
    acceptableRoles: ['Landlord', 'Agent', 'Controller', 'Admin'],
  },
  {
    title: 'Monthly rent',
    key: 'monthlyTenancy',
    acceptableRoles: ['Landlord', 'Agent', 'Controller', 'Admin'],
  },
  {
    title: role === 'Landlord' ? 'Bank account' : 'LL bank account',
    key: 'landlordAccount',
    acceptableRoles: ['Landlord', 'Agent', 'Controller', 'Admin'],
  },
  {
    title: 'Home Made account',
    key: 'tenancyAccount',
    acceptableRoles: ['Controller', 'Admin'],
  },
];
