import React, { useState } from 'react';
import cx from 'clsx';

import { ExpandCollapseButton, Button } from 'components';
import { useModal, useAppDispatch, useAppSelector } from 'hooks';
import { syncAuditLogItem } from 'store/actions/analytics';
import successIcon from 'images/successStatusIcon.svg';
import errorIcon from 'images/errorStatusIcon.svg';
import warningIcon from 'images/warningStatusIcon.svg';

import { LogInfoModal } from '../index';
import { IAuditLogRowProps } from '../../types';

import styles from './AuditLogRow.module.scss';

const statusIcon = {
  OK: successIcon,
  ERROR: errorIcon,
  WARNING: warningIcon,
};

const statusIconLabel = {
  OK: 'Ok',
  WARNING: 'Attention',
  ERROR: 'Error',
};

const AuditLogRowComponent: React.FC<IAuditLogRowProps> = ({
  name,
  status,
  logTime,
  logType,
  details,
  logEvents,
  isAuditGroup,
  isBordered,
  children,
}): JSX.Element => {
  const [isCollapsed, handleCollapse] = useState(false);
  const [isLogInfoModalOpen, toggleLogInfoModalModal] = useModal();
  const { loaderState } = useAppSelector((state) => state.loader);

  const dispatch = useAppDispatch();

  const handleSync = () => dispatch(syncAuditLogItem(logType));

  const renderAuditLogLastRun = () => {
    return !children && `Last run at ${logTime}`;
  };

  const renderAuditLogDetails = () => {
    return (
      details && (
        <span>
          <span className={styles.details}>Details: </span>
          {details}
        </span>
      )
    );
  };

  const renderAuditLogAction = () => {
    return (
      (children && children.length > 0 && (
        <ExpandCollapseButton isCollapsed={isCollapsed} onClick={() => handleCollapse(!isCollapsed)} />
      )) ||
      (logEvents.length > 0 && (
        <div className={styles.viewDetailsBtnContainer}>
          <button className={styles.viewDetailsBtn} onClick={toggleLogInfoModalModal}>
            See Details
          </button>
        </div>
      ))
    );
  };

  return (
    <>
      <div className={cx(isAuditGroup && styles[`auditLogRowContainer${status}`])}>
        <div className={cx(styles.auditLogRow, isAuditGroup ? styles.rowGrid : styles.childrenRowGrid)}>
          <div className={cx(isBordered ? styles.borderedCell : '')} />
          <div
            className={cx(
              styles.cell,
              styles.statusIconCell,
              isAuditGroup ? styles.rowIcon : '',
              isBordered ? styles.borderedCell : ''
            )}
          >
            <img src={statusIcon[status]} alt={statusIconLabel[status]} />
          </div>
          <div className={cx(styles.cell, styles.logNameCell)}>{name}</div>
          <div className={cx(styles.cell, styles.regularLabelCell)}>{renderAuditLogLastRun()}</div>
          <div className={styles.cell}>
            {logType !== 'salesforce' && (
              <Button
                className={styles.controlButton}
                theme="secondary"
                size="small"
                disabled={loaderState > 0}
                onClick={handleSync}
              >
                Sync Now
              </Button>
            )}
          </div>
          <div className={cx(styles.cell, styles.regularLabelCell)}>{renderAuditLogDetails()}</div>
          <div className={cx(styles.cell, styles.expandCollapseCell, styles.rowExpandCollapse)}>
            {renderAuditLogAction()}
          </div>
          <div className={cx(isBordered ? styles.borderedCell : '')} />
        </div>
        <div className={styles.auditLogRowChildren}>{isCollapsed && children}</div>
      </div>
      {logEvents.length > 0 && isLogInfoModalOpen && (
        <LogInfoModal
          isOpen={isLogInfoModalOpen}
          toggle={toggleLogInfoModalModal}
          header={name}
          logEvents={logEvents}
        />
      )}
    </>
  );
};

export const AuditLogRow = React.memo(AuditLogRowComponent);
