import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appModalReducer from 'store/reducers/AppModalSlice';
import authReducer from './reducers/AuthSlice';
import viewConfigReducer from './reducers/ViewConfigSlice';
import selectedListingReducer from './reducers/SelectedListingSlice';
import listingsListReducer from './reducers/ListingsListSlice';
import userListReducer from './reducers/UserListSlice';
import walletReducer from './reducers/WalletSlice';
import transactionsReducer from './reducers/TransactionsSlice';
import financesReducer from './reducers/FinancesSlice';
import analyticsReducer from './reducers/AnalyticsSlice';
import notificatiosReducer from './reducers/NotificationsSlice';
import loaderReducer from './reducers/LoaderSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  viewConfig: viewConfigReducer,
  listingsList: listingsListReducer,
  userList: userListReducer,
  selectedListing: selectedListingReducer,
  wallet: walletReducer,
  transactions: transactionsReducer,
  finances: financesReducer,
  analytics: analyticsReducer,
  notifications: notificatiosReducer,
  loader: loaderReducer,
  appModal: appModalReducer,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const store = setupStore();
