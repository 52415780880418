import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormField, Input, Button, ContactUs } from 'components';
import { TFieldError } from 'components/elements/FormField/types';
import { useAppDispatch } from 'hooks';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { addNotificationItem } from 'store/actions/notifications';
import { updateUserAccount } from 'store/actions/userData';
import { getExpiredPasswordSchema } from 'utils/schemas';
import { cognitoAuth } from 'utils/cognito';
import { passwordRules } from 'constants/passwordRules';

import styles from './ExpiredPasswordPage.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

export const ExpiredPasswordPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // @ts-ignore: Unreachable code error
  const { oldPassword, userId } = location.state;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(getExpiredPasswordSchema(oldPassword)),
  });

  useEffect(() => {
    if (!oldPassword && !userId) navigate('/auth/sign-in');
  }, []);

  const handleChangePassword = async ({ password }: FormValues) => {
    dispatch(loaderSlice.actions.increaseLoader());

    cognitoAuth
      .forceChangePassword(oldPassword, password)
      .then(() => dispatch(updateUserAccount({ userId, payload: { passwordModifiedAt: new Date().toISOString() } })))
      .then(() => navigate('/main/properties'))
      .catch((error) => {
        if (error && error.name === 'LimitExceededException') {
          dispatch(addNotificationItem({ type: 'error', label: error?.message }));
        }
        if (error) {
          dispatch(addNotificationItem({ key: 'defaultError' }));
        }
      })
      .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
  };

  return (
    <div className="expired-password">
      <h2 className={styles.taglineHeader}>Password expired</h2>
      <div className={styles.ruleDescription}>
        <p className={styles.ruleDescriptionHeader}>
          Your account password expired. Please create a new password to proceed.
        </p>
        <p className={styles.ruleDescriptionHeader}>
          In order to protect your account, make sure your password meets the following:
        </p>
        <ul className={styles.passwordRulesList}>
          {passwordRules.map((rule, index) => (
            <li className={styles.rule} key={index}>
              {rule}
            </li>
          ))}
        </ul>
      </div>

      <form autoComplete="off" onSubmit={handleSubmit(handleChangePassword)}>
        <FormField labelId="password" labelText="New password" fieldError={errors.password as TFieldError}>
          <Input placeholder="New password" id="password" type="password" {...register('password')} />
        </FormField>
        <FormField
          labelId="confirmPassword"
          labelText="Re-enter your new password"
          fieldError={errors.password as TFieldError}
        >
          <Input
            placeholder="Re-enter your new password"
            id="confirmPassword"
            type="password"
            {...register('confirmPassword')}
          />
        </FormField>

        <div className={styles.controlSection}>
          <Button fullWidth type="submit">
            Change password
          </Button>
        </div>
      </form>
      <ContactUs promoLabel="Need help signing in?" />
    </div>
  );
};
