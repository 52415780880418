import React, { FC, useCallback, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { components, ControlProps, NoticeProps, OptionProps, SingleValueProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import { useAppDispatch, useAppSelector } from 'hooks';
import { IListingSuggestionItem } from 'types';
import { getListingSuggestionsCall } from 'store/api/listing';
import { setSelectedListing } from 'store/actions/selectedListing';
import { loaderSlice } from 'store/reducers/LoaderSlice';
import { handleListingSuggestionsItem } from 'store/models/selectedListing';
import { ReactComponent as SearchFieldIcon } from 'images/search-field-icon.svg';

import { OptionLabel, ISuggestionSelectProps } from '../types';
import { customStyles } from '../customStyles';
import styles from '../SuggestionSelect.module.scss';

const SingleValue = ({ children, ...props }: SingleValueProps<IListingSuggestionItem, false>) => (
  <components.SingleValue {...props}>
    <div className={styles.singleValueWrapper}>
      {children}
      <span className={styles.status}>{props.data.status}</span>
    </div>
  </components.SingleValue>
);

const Control: FC<ControlProps<IListingSuggestionItem, false>> = ({ children, ...props }) => (
  <components.Control {...props}>
    <div className={styles.searchIconWrapper}>
      <SearchFieldIcon />
    </div>
    {children}
  </components.Control>
);

const Option: FC<OptionProps<IListingSuggestionItem, false>> = ({ children, ...props }) => (
  <components.Option {...props}>
    <div className={styles.optionWrapper}>
      {children}
      <span className={styles.status}>{props.data.status}</span>
    </div>
  </components.Option>
);

const NoOptionsMessage: FC<NoticeProps<IListingSuggestionItem, false>> = ({ children, ...props }) => (
  <components.NoOptionsMessage {...props}>No properties found</components.NoOptionsMessage>
);

export const ListingSelect: FC<ISuggestionSelectProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const { listingSuggestions } = useAppSelector((state) => state.selectedListing);

  const promiseOptions = (search: string) =>
    new Promise<IListingSuggestionItem[]>((resolve) => {
      dispatch(loaderSlice.actions.increaseLoader());
      getListingSuggestionsCall({ search })
        .then((data) => resolve(data.map(handleListingSuggestionsItem)))
        .finally(() => dispatch(loaderSlice.actions.decreaseLoader()));
    });

  const handleInputChange = useCallback((newValue: string) => {
    setInputValue(newValue);
  }, []);

  const handleChange = (option: IListingSuggestionItem | null) => {
    if (option?.listingId) {
      dispatch(
        setSelectedListing({
          listingId: option?.listingId,
          salesforceListingId: option?.salesforceListingId,
          salesforcePropertyId: option?.salesforcePropertyId,
        })
      );
    }
  };

  return (
    <AsyncSelect
      value={listingSuggestions.defaultOption}
      className={className}
      styles={customStyles}
      cacheOptions
      components={{ Control, NoOptionsMessage, Option, SingleValue }}
      defaultOptions={listingSuggestions.suggestionsList}
      loadOptions={promiseOptions}
      onInputChange={handleInputChange}
      placeholder="Search for a property"
      onChange={handleChange}
      getOptionValue={(option) => option.listingId}
      getOptionLabel={(option) =>
        (
          <Highlighter
            highlightClassName={styles.textHighlight}
            searchWords={[inputValue]}
            autoEscape
            textToHighlight={option.address}
          />
        ) as OptionLabel
      }
    />
  );
};

ListingSelect.defaultProps = {
  className: undefined,
};
