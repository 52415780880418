import React from 'react';
import { Area } from '@ant-design/plots';

import { createAnnotations, handleXAxis } from '../utils';
import { IViewsPerformanceChartProps, IChartLegend } from './types';
import { chartLegendMap, chartConfig } from '../constants';
import styles from './ViewsPerformanceChart.module.scss';
import './chart.css';

const ViewsPerformanceChartComponent: React.FC<IViewsPerformanceChartProps> = ({ array, yesterdayViews }) => {
  const renderChartLegend = ({ label, icon }: IChartLegend, i: number) => {
    return (
      <div className={styles.legendContainer} key={i}>
        <img src={icon} alt={label} />
        <span className={styles.legendLabel}>{label}</span>
      </div>
    );
  };

  return (
    <div className={styles.viewsPerformanceChartContainer}>
      <div className={styles.yesterdayViews}>
        <span className={styles.yesterdayViewsLabel}>Yesterdays views:</span>{' '}
        <span className={styles.yesterdayViewsValue}>{yesterdayViews}</span>
      </div>
      <div className={styles.viewsPerformanceChart}>
        {/*
          // @ts-ignore */}
        <Area {...chartConfig} data={array} annotations={createAnnotations(array)} xAxis={handleXAxis(array)} />
      </div>
      <div className={styles.viewsPerformanceChartLegend}>
        {chartLegendMap.map((item, i) => renderChartLegend(item, i))}
      </div>
    </div>
  );
};
export const ViewsPerformanceChart = React.memo(ViewsPerformanceChartComponent);
