import React from 'react';

import { Modal, Button, FormField, Input } from 'components';
import { IBankAccountDetailsModalProps } from './types';
import styles from './BankAccountDetailsModal.module.scss';

export const BankAccountDetailsModalComponent: React.FC<IBankAccountDetailsModalProps> = ({
  isOpen,
  toggle,
  options,
}) => {
  const { landlordAccountName, landlordAccountSortCode, landlordAccountNumber } = options;

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <h2 className={styles.header}>Landlord bank details</h2>

      <div className={styles.inputSection}>
        <FormField labelId="accountName" labelText="Account Name">
          <Input readOnly size="small" id="accountName" value={landlordAccountName} />
        </FormField>

        <FormField labelId="sortCode" labelText="Sort code">
          <Input readOnly size="small" id="sortCode" type="number" value={landlordAccountSortCode} />
        </FormField>

        <FormField labelId="accountNumber" labelText="Account number">
          <Input readOnly size="small" id="accountNumber" type="number" value={landlordAccountNumber} />
        </FormField>

        <div className={styles.controlSection}>
          <Button fullWidth size="small" type="submit" onClick={toggle}>
            Close window
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const BankAccountDetailsModal = React.memo(BankAccountDetailsModalComponent);
