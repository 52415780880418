import { ITableRowItemFields, ITableRowItemField } from 'components/elements/Table/types';
import { statusMap } from 'constants/properties';
import {
  IListingResponse,
  IListingInfo,
  ILandlordInfo,
  IViewsByListingIdResponse,
  IPropertyByListingIdResponse,
  ITenancyTerminationInfoResponse,
  IListingTerminationInfo,
  IListingByPropertySalesforceIdResponse,
  IListingsDocument,
  TDocumentType,
  documentTypeMap,
  IListingHistoryItem,
  IListingSuggestionItem,
  IPropertyResponse,
  IPropertySuggestionItem,
} from 'types';
import {
  getPrice,
  getFullDate,
  handleBoleanTitle,
  getShortDate,
  getDayMonthDate,
  handleFurnishedTitle,
} from 'utils/helpers';

export const handleSelectedListingInfo = ({
  status,
  address,
  enquiriesNumber,
  offersNumber,
  price,
  petsAllowed,
  furnished,
  bedroomsNumber,
  bathroomsNumber,
  parkingAvailable,
  availableDate,
  currency,
  rentManagement,
  savingsNumber,
  tenancyStartDate,
  tenancyEndDate,
  priceReductionDate,
  propertySalesforceId,
}: IListingResponse): IListingInfo => {
  return {
    status: statusMap[status],
    address,
    enquiriesNumber,
    offersNumber,
    bedroomsNumber,
    bathroomsNumber,
    currency,
    rentManagement,
    tenancyStartDate,
    tenancyEndDate,
    priceReductionDate,
    price,
    propertySalesforceId,
    petsAllowed: handleBoleanTitle(petsAllowed),
    furnished: handleFurnishedTitle(furnished),
    parkingAvailable: handleBoleanTitle(parkingAvailable),
    availableDate: getShortDate(availableDate),
    savingsNumber: getPrice({ currency, price: savingsNumber }),
  };
};

export const handleLandlordInfo = ({ contactInfo }: IListingResponse): ILandlordInfo => {
  const { landlord } = contactInfo;
  return {
    email: landlord.email,
    fullName: landlord.fullName,
    phoneNumber: landlord.phoneNumber,
  };
};

export const getTotalAdViewsNumber = (arr: IViewsByListingIdResponse[]) => {
  return `${arr.reduce((partialSum: number, a) => partialSum + a.totalDetailViews, 0)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
};

export const getViewingsNumber = ({ bookings }: IPropertyByListingIdResponse) => {
  let plannedViewings = 0;
  let pastViewings = 0;

  if (bookings.length > 0) {
    bookings.forEach(({ bookingDate }) => {
      if (new Date(bookingDate).getTime() + new Date().getTimezoneOffset() * 60000 > new Date().getTime()) {
        plannedViewings++;
      } else {
        pastViewings++;
      }
    });
  }

  return {
    plannedViewings,
    pastViewings,
  };
};

export const getTotalViewsNumber = (arr: IViewsByListingIdResponse[]) => {
  return arr.reduce((partialSum: number, a) => partialSum + a.totalDetailViews, 0);
};

export const handleChartListingData = ({ viewsData, listingData }: any) => {
  const handledViewsData = viewsData.map(({ totalDetailViews, viewDate, price }: any, index: number) => {
    const viewingBookedNumber = listingData.bookings.filter(
      (e: any) => getShortDate(e.bookingDate) === getShortDate(viewDate)
    );

    return {
      totalDetailViews,
      viewDate: getDayMonthDate(viewDate),
      anotations: [
        { key: 'isViewingBooked', value: viewingBookedNumber.length === 1 },
        { key: 'isMultipleViewings', value: viewingBookedNumber.length > 1 },
        { key: 'isPriceChange', value: index !== 0 && viewsData[index - 1].price !== price },
      ],
    };
  });

  return {
    chartListingData: handledViewsData.reverse(),
    yesterdayViews: 0,
  };
};

export const handleListingTerminationInfo = ({
  id,
  newTenancyTerminationDateRequested,
  existing,
  updated,
}: ITenancyTerminationInfoResponse): IListingTerminationInfo => {
  return {
    id,
    newListingTerminationDateRequested: newTenancyTerminationDateRequested,
    existingListingTerminationEndDate: existing.tenancyEndDate,
    updatedListingTerminationEndDate: updated.tenancyEndDate,
  };
};

const handleTenanciesHistoryDocuments = (
  mapItem: TDocumentType,
  documents: IListingsDocument[]
): ITableRowItemField => {
  const document = documents.find(({ type }) => type === mapItem);

  if (document) {
    return { fieldType: 'downloadDocument', fieldName: mapItem, value: document.id };
  }

  return { fieldName: mapItem, value: '-' };
};

export const handleListingsHistoryItem = ({
  id,
  listingId,
  propertySalesforceId,
  address,
  tenancyStartDate,
  tenancyEndDate,
  status,
  documents,
}: IListingByPropertySalesforceIdResponse): IListingHistoryItem => {
  return {
    listingId: `${id}`,
    salesforceListingId: listingId,
    salesforcePropertyId: propertySalesforceId,
    address,
    status: statusMap[status as keyof typeof statusMap],
    tenancyStartDate,
    tenancyEndDate,
    documents,
  };
};

export const handleListingsHistoryTableItem = ({
  id,
  price,
  currency,
  tenancyStartDate,
  tenancyEndDate,
  documents,
}: IListingByPropertySalesforceIdResponse): ITableRowItemFields => {
  return {
    id: `${id}`,
    values: [
      {
        fieldName: 'id',
        value: `${id}`,
      },
      {
        fieldName: 'startDate',
        value: getFullDate(tenancyStartDate),
      },
      {
        fieldName: 'endDate',
        value: getFullDate(tenancyEndDate),
      },
      {
        fieldName: 'propertyPrice',
        value: getPrice({ currency, price }),
      },
      {
        fieldName: 'action',
        fieldType: 'action',
        label: 'See details',
        value: `${id}`,
      },
      ...documentTypeMap.map((mapItem) => handleTenanciesHistoryDocuments(mapItem, documents)),
    ],
  };
};

export const handleListingSuggestionsItem = ({
  id,
  propertySalesforceId,
  listingId,
  status,
  address,
}: IListingResponse): IListingSuggestionItem => {
  return {
    listingId: `${id}`,
    salesforceListingId: listingId,
    salesforcePropertyId: propertySalesforceId,
    status: statusMap[status as keyof typeof statusMap],
    address,
  };
};

export const handlePropertySuggestionsItem = ({
  id,
  salesforceId,
  lastListingListingId,
  lastListingSalesforceListingId,
  name,
}: IPropertyResponse): IPropertySuggestionItem => {
  return {
    propertyId: `${id}`,
    listingId: lastListingListingId,
    salesforceListingId: lastListingSalesforceListingId,
    salesforcePropertyId: salesforceId,
    address: name,
  };
};
