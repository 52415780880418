import React, { useState } from 'react';

import { Modal, Button, CodeInput } from 'components';
import { useAppSelector } from 'hooks';
import styles from './VerificationModal.module.scss';

export interface IVerificationModalProps {
  isOpen: boolean;
  toggle(): void;
  onSubmit: any;
  resendVerificationCode: any;
}

export const VerificationModalComponent: React.FC<IVerificationModalProps> = ({
  isOpen,
  toggle,
  onSubmit,
  resendVerificationCode,
}) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const [verificationCode, setVerificationCode] = useState('');

  return (
    <Modal isOpen={isOpen} onRequestClose={toggle} className={styles.modal}>
      <div className={styles.verificationModal}>
        <h3 className={styles.verificationHeader}>Authenticate your account</h3>
        <p className={styles.verificationDescription}>
          <span className={styles.verificationDescriptionContent}>
            Please confirm your account by entering the authorisation code sent
          </span>
          <span className={styles.verificationDescriptionEmail}>to {userInfo?.phoneNumber}.</span>
        </p>
        <div>
          <CodeInput handleChange={setVerificationCode} fields={8} className={styles.codeInput} />
        </div>
        <div className={styles.verificationSubmit}>
          <Button
            disabled={verificationCode.length < 8}
            fullWidth
            size="small"
            onClick={() => onSubmit({ verificationCode })}
          >
            Submit
          </Button>
        </div>
        <div className={styles.verificationPos}>
          It may take a minute to receive your code. Haven’t received it?{' '}
          <button onClick={resendVerificationCode} className={styles.verificationResend}>
            Resend a new code.
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const VerificationModal = React.memo(VerificationModalComponent);
